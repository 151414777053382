import JlinkType from '@/common/global/JlinkType'
import { ASSIGNMENTS_TYPE } from '@/common/enum/JLINK_ENUMS'

export default class AssignmentsTransform {
  toStatus (assignments?:AssignmentsBaseVo) {
    switch (assignments?.assignmentsStatus) {
      case 0:
        return { type: 'prepare', desc: '准备中' }
      case 1:
        return { type: 'doing', desc: '进行中' }
      case 2:
        return { type: 'complete', desc: '已完成' }
      default:
        return { type: 'warning', desc: '状态错误' }
    }
  }

  toType (assignments?:AssignmentsBaseVo|ASSIGNMENTS_TYPE) {
    if (JlinkType.assignments.isTempType(assignments)) {
      return '临时任务'
    } else if (JlinkType.assignments.isReservationType(assignments)) {
      return '预约任务'
    } else if (JlinkType.assignments.isQuickType(assignments)) {
      return '快速任务'
    } else if (JlinkType.assignments.isDockType(assignments)) {
      return '机场任务'
    } else {
      return '未知任务'
    }
  }
}
