import UserTypeMaker from '@/common/global/type/UserTypeMaker'
import AssignmentsTypeMaker from '@/common/global/type/AssignmentsTypeMaker'
import DroneTypeMaker from '@/common/global/type/DroneTypeMaker'
import WaylineJobTypeMaker from '@/common/global/type/WaylineJobTypeMaker'
/*
* JLink类型判断
* */
export default class JlinkType {
  static drone = new DroneTypeMaker()

  static assignments = new AssignmentsTypeMaker()

  static waylineJob = new WaylineJobTypeMaker()

  static user = new UserTypeMaker()
}
