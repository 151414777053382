import { UserModule } from '@/store/User'
import { computed } from 'vue'

export default class UserTypeMaker {
  // 判断用户是不是自己

  isSelf (userOrUserId?: {userId:string} | string) {
    if (typeof userOrUserId === 'object') {
      return !!(UserModule.user?.userId) && userOrUserId.userId === UserModule.user.userId
    } else {
      return !!(UserModule.user?.userId) && userOrUserId === UserModule.user.userId
    }
  }

  // 判断用户是不是自己并且自己有没有管理员权限
  isAdminSelf (userOrUserId?: {userId:string} | string) {
    if (typeof userOrUserId === 'object') {
      return this.isSelf(userOrUserId) && this.isAdminUser(UserModule.user.userAuthorities)
    } else {
      return this.isSelf(userOrUserId) && this.isAdminUser(UserModule.user.userAuthorities)
    }
  }

  isSuperSelf (userOrUserId?: {userId:string} | string) {
    if (typeof userOrUserId === 'object') {
      return this.isSelf(userOrUserId) && this.isSuperUser(UserModule.user.userAuthorities)
    } else {
      return this.isSelf(userOrUserId) && this.isSuperUser(UserModule.user.userAuthorities)
    }
  }

  isSuperButNotSelf (userOrUserId?: {userId:string} | string) {
    if (typeof userOrUserId === 'object') {
      return !this.isSelf(userOrUserId) && this.isSuperUser(UserModule.user.userAuthorities)
    } else {
      return !this.isSelf(userOrUserId) && this.isSuperUser(UserModule.user.userAuthorities)
    }
  }

  authOverly (authorities: string[]) {
    if (this.isSuper.value && !this.isSuperUser(authorities)) {
      return 0
    }
    if (this.isAdmin.value && !this.isSuperUser(authorities) && !this.isAdminUser(authorities)) {
      return 1
    }
    return 2
  }

  isSuperUser (auth:string[]) {
    return (auth.includes('00'))
  }

  isAdminUser (auth:string[]) {
    return auth.includes('11')
  }

  isSuper = computed(() => this.isSuperUser(UserModule.authorities))

  isAdmin = computed(() => this.isAdminUser(UserModule.authorities) || this.isSuperUser(UserModule.authorities))
}
