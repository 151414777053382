import { ModuleBase } from '@/common/dji/cloud/ModuleBase'
import { getDjiApiInfo } from '@/api/api.system'
import DjiCloudException from '@/common/errors/DjiCloudException'

declare let window: any

export class ModuleApi extends ModuleBase {
  async load () {
    if (!this.isLoaded()) {
      this.unload()
      try {
        const api = await getDjiApiInfo(undefined)
        this.loadComponent('api', { host: api.host, token: api.token })
        this.setApiToken(api.token)
      } catch (e) {
        this.unload()
        throw new DjiCloudException('加载api模块失败', 100)
      }
    }
  }

  unload () {
    if (this.isLoaded()) {
      this.unloadComponent('api')
    }
  }

  isLoaded () {
    return this.isComponentLoaded('api') as boolean
  }

  getApiToken (): string {
    return this.bridgeSetBlock<string>(() => window.djiBridge.apiGetToken())
  }

  setApiToken (token: string) {
    window.djiBridge.apiSetToken(token)
  }

  getApiHost () {
    return this.bridgeSetBlock<string>(() => window.djiBridge.apiGetHost())
  }

  getApidiv () {
    return ''
  }
}
