// import * as Cookies from 'js-cookie'
import {COORDINATE} from "@/common/enum/JLINK_ENUMS";

type SAVECONTAINER = 'localStorage' | 'sessionStorage' | 'cookie'

declare class StorageValueFormat {
  // key:'loginUser'|'pilotInfo'|'pilotMediaConfig'|'emergency'
  // data:Object
  'loginUser':LoginUserVo
  'pilotInfo':ReqGetDjiCloudPlatformInfo['Response']
  'pilotMediaConfig':{autoUploadPhoto: boolean, autoUploadPhotoType: 0|1, autoUploadVideo:boolean}
  'Company':CompanyDetailVo
  'DeviceEnum':DeviceEnumVo[]
  'jlink_bigemap':{exteralSelect:boolean}
  'flight_param':FlightParam
  'zoneData2':{coordinate:COORDINATE, values:FlyZoneData[]}
}

/*
* JLink本地存储
* */
export default class JlinkStorage {
  static getItem (key: string, storage: SAVECONTAINER = 'localStorage'): string | undefined {
    switch (storage) {
      case 'cookie':
        // return Cookies.get(key) || undefined
        return undefined
      case 'localStorage':
        return localStorage.getItem(key) || undefined
      case 'sessionStorage':
        return sessionStorage.getItem(key) || undefined
    }
  }

  static setItem (key: string, value: string, storage: SAVECONTAINER = 'localStorage') {
    console.log(key, value)
    switch (storage) {
      case 'cookie':
        // Cookies.set(key, value)
        break
      case 'localStorage':
        localStorage.setItem(key, value)
        break
      case 'sessionStorage':
        sessionStorage.setItem(key, value)
        break
    }
  }

  static removeItem (key: string, storage: SAVECONTAINER = 'localStorage') {
    switch (storage) {
      case 'cookie':
        // Cookies.remove(key)
        break
      case 'localStorage':
        localStorage.removeItem(key)
        break
      case 'sessionStorage':
        sessionStorage.clear()

        break
    }
  }

  static clear (storage: SAVECONTAINER = 'localStorage') {
    switch (storage) {
      case 'cookie':
        // Cookies.remove()
        break
      case 'localStorage':
        localStorage.clear()
        break
      case 'sessionStorage':
        sessionStorage.clear()
        break
    }
  }

  static get<T extends keyof StorageValueFormat> (key: T): StorageValueFormat[T] | undefined {
    const value = this.getItem(key)
    return value && JSON.parse(value)
  }

  static set<T extends keyof StorageValueFormat> (key: T, data: StorageValueFormat[T]): void {
    this.setItem(key, JSON.stringify(data))
  }

  static remove<T extends keyof StorageValueFormat> (key: T):void {
    this.removeItem(key)
  }
}
