import { UserModule } from '@/store/User'
import { watchError } from '@/common/mitt/mitt'
import { remove } from 'lodash'
import { ExceptionEnum } from '@/common/errors/ExceptionEnum'
import { App } from 'vue'
import JlinkUtils from '@/common/global/JlinkUtils'
import JlinkUi from '@/common/global/JlinkUi'
let reloadConfirm: string | undefined

function reLogin (message: string) {
  if (message !== reloadConfirm) {
    reloadConfirm = message
    UserModule.logout(message)
      .finally(() => {
        reloadConfirm = undefined
      })
  }
}
const notifyStrs: string[] = []

function showNotify (title:string, message: string, type:'warning'|'error'|'info'|'success') {
  if (!notifyStrs.includes(message)) {
    notifyStrs.push(message)
    // @ts-ignore
    JlinkUi.baseNotify(title, message, {
      type,
      duration: 4000,
      onClose: function () {
        remove(notifyStrs, function (i) {
          return i === message
        })
      }
    })
  }
}

const messageStrs: string[] = []
function showMessage (message: string, type:'warning'|'error'|'info'|'success') {
  if (!messageStrs.includes(message)) {
    messageStrs.push(message)
    // @ts-ignore
    JlinkUi.baseMessage(message, {
      type,
      onClose: function () {
        remove(messageStrs, function (i) {
          return i === message
        })
      }
    })
  }
}

export default function errorInstall (app: App<Element>) {
  app.config.errorHandler = function (err) {
    dealError(err)
  }
};

watchError(async (error) => {
  await dealError(error)
},false)

function dealError (err: any) {
  console.error(err)
  if (typeof err === 'string') {
    console.warn(err)
  } else if (err.type === ExceptionEnum.REQUEST) {
    showMessage(err.message, 'error')
  } else if (err.type === ExceptionEnum.RESPONSE) {
    switch (err.code) {
      // 用户不存在
      case 100002:
        reLogin(err.message)
        break
      // 认证失败
      case 100010:
        reLogin(err.message)
        break
      // token不正确
      case 100006:
        reLogin(err.message)
        break
      // token无效
      case 100008:
        reLogin(err.message)
        break
      default:
        showNotify('', err.message, 'error')
        break
    }
  } else if (err.type === ExceptionEnum.NOTIFY) {
    showNotify('', err.message, err.errType)
  } else if (err.type === ExceptionEnum.COMPONENTS) {
    showMessage(err.message, 'error')
  } else if (err.type === ExceptionEnum.DJI_CLOUD) {
    showMessage(err.message, 'error')
  } else if (err.type === ExceptionEnum.MQTT) {
    // console.error(err.message)
  } else if (err.type === ExceptionEnum.DATA) {
    showMessage(err.message, 'error')
  } else {
    // showMessage(err.message, 'error')
  }
}
