import JlinkWorkerDownLoad from '@/common/workers/JlinkWorkerDownLoad'
import { ref } from 'vue'
import JlinkUi from '@/common/global/JlinkUi'
import JlinkUtils from '@/common/global/JlinkUtils'
import { GB, MB } from '@/common/global/JlinkValues'
import axios, { AxiosResponse } from 'axios'
import NotifyException from '@/common/errors/NotifyException'
import DateUtil from '@/common/global/util/DateUtil'

type OssDownloadFile = {
  fileName: string
  fileObjectKey: string
  fileExt: string
  fileSize: number
}

export default class JlinkDownload {
  // 多线程下载文件
  static async downloadOssFiles (files: OssDownloadFile[]) {
    const images: OssDownloadFile[] = []
    const videos: OssDownloadFile[] = []
    let imageNum = 0
    let imageSize = 0
    let videoNum = 0
    let videoSize = 0
    let unknownNum = 0
    let unknownSize = 0
    for (const file of files) {
      if (file) {
        if (JlinkUtils.regex.isImage(file.fileExt) || JlinkUtils.regex.isImage(file.fileName)) {
          imageNum++
          imageSize += file.fileSize
          images.push(file)
        } else if (JlinkUtils.regex.isVideo(file.fileExt) || JlinkUtils.regex.isVideo(file.fileName)) {
          videoNum++
          videoSize += file.fileSize
          videos.push(file)
        } else {
          unknownNum++
          unknownSize += file.fileSize
        }
      }
    }

    let desc = `当前选中文件${imageNum + videoNum + unknownNum}个,`
    if (imageNum > 0) {
      desc += `图片文件${imageNum}个${imageSize.asByteSizeFormat(1)}，`
    }
    if (videoNum > 0) {
      desc += `视频文件${videoNum}个${videoSize.asByteSizeFormat(1)}，`
    }
    if (unknownNum > 0) {
      desc += `未知文件${unknownNum}个${unknownNum.asByteSizeFormat(1)}，`
    }

    const downloadSize = imageSize + videoSize

    if (downloadSize > 0) {
      desc += `总计下载文件大小${downloadSize.asByteSizeFormat(1)}（不包含未知文件,其中图片文件按照最大每个包0.5G进行并行zip压缩下载，视频文件单独下载,因为机器配置原因超过2G的大文件有可能下载失败)`
      const res = await JlinkUi.baseConfirm(desc, '批量下载')
      if (res.action === 'confirm') {
        const downloadTag = JlinkUtils.date.parseTime(Date.now(), '{y}{m}{d}{h}{i}{s}')
        const checkLimit = 0.5 * GB
        let chunkCount = 0
        let chunk: OssDownloadFile[] = []
        let chunkSize = 0
        for (const image of images) {
          chunkSize += image.fileSize
          if (chunkSize < checkLimit) {
            chunk.push(image)
          } else {
            if (chunk.length > 0) {
              const downloadImages = chunk.map(f => {
                return { id: JlinkUtils.random.uuid(8), name: f.fileName, url: f.fileObjectKey, oss: true }
              })
              this.appendDownload(downloadImages, downloadTag + `(${++chunkCount})`)
            }
            chunk = []
            chunkSize = image.fileSize
            chunk.push(image)
          }
        }

        if (chunk.length) {
          const downloadImages = chunk.map(f => {
            return { id: JlinkUtils.random.uuid(8), name: f.fileName, url: f.fileObjectKey, oss: true }
          })
          this.appendDownload(downloadImages, downloadTag + `(${++chunkCount})`)
        }

        if (videos.length > 0) {
          const downloadVideos = videos.map(f => {
            return { id: JlinkUtils.random.uuid(8), name: downloadTag + f.fileName, url: f.fileObjectKey, oss: true }
          })
          this.appendDownload(downloadVideos)
        }
      }
    } else {
      desc += '不包含可下载视频或者图片，'
      const res = await JlinkUi.baseConfirm(desc, '无法下载', { showConfirmButton: false })
    }
  }

  static async downloadOssFile (file: OssDownloadFile) {
    const res = await JlinkUi.baseConfirm(`当前下载文件大小${file.fileSize.asByteSizeFormat(1)}，因为机器配置原因超过2G的大文件有可能下载失败`, '文件下载')
    if (res.action === 'confirm') {
      if (file.fileSize < 5 * MB) {
        await this.downloadSmallOssFile(file.fileObjectKey, file.fileName)
      } else {
        await this.downloadLargeOssFile(file.fileObjectKey, file.fileName)
      }
    }
  }

  static async downloadLargeOssFile (ossKey: string, name: string) {
    this.appendDownload([{ id: JlinkUtils.random.uuid(8), name, url: ossKey, oss: true }])
  }

  static async downloadSmallOssFile (ossKey: string, name: string) {
    const url = await window.$oss.getSignUrl(ossKey)
    return await this.downloadFile(url, name)
  }

  private static async downloadFile (url: string, name: string) {
    let res: AxiosResponse<any>
    try {
      res = await axios.get(url, { responseType: 'blob', })
    } catch (e) {
      throw new NotifyException('url错误或者文件不存在', 'error')
    }
    if (res.status !== 200) {
      throw new NotifyException('url错误或者文件不存在', 'error')
    }

    if (res.data) {
      if (res.data.size === 0) {
        throw new NotifyException('下载文件不是有效的文件', 'error')
      }
      const blob = new Blob([res.data], { type: res.data.type })
      const linkNode = document.createElement('a')
      // constant evt = document.createEvent('HTMLEvents')
      // evt.initEvent('click')
      linkNode.style.display = 'none'
      linkNode.download = name
      linkNode.href = URL.createObjectURL(blob) // 生成一个Blob URL
      document.body.appendChild(linkNode)
      // linkNode.dispatchEvent(evt)
      linkNode.click() // 模拟在按钮上的一次鼠标单击
      URL.revokeObjectURL(linkNode.href) // 释放URL 对象
      document.body.removeChild(linkNode)
    } else {
      throw new NotifyException('下载文件数据格式错误', 'error')
    }
  }

  static downloadWorkers = ref<JlinkWorkerDownLoad[]>([])

  static appendDownload (files: { id: string, name: string, url: string, oss: boolean }[], zipName?: string) {
    if (files.length > 0) {
      const download = new JlinkWorkerDownLoad(files, zipName)
      this.downloadWorkers.value.push(download)
    }
  }

  static removeDownLoad (uuid: string) {
    const target = this.downloadWorkers.value.find(item => item.uuid === uuid)
    if (target) {
      target.destroy()
      this.downloadWorkers.value.removeElement(target)
    }
  }
}
