import Clipboard from 'clipboard'
import { mittError } from '@/common/mitt/mitt'
import NotifyException from '@/common/errors/NotifyException'

export default class DomUtil {
  SPECIAL_CHARS_REGEXP = new RegExp('/([\:\-\_]+(.))/g')
  MOZ_HACK_REGEXP = new RegExp('/^moz([A-Z])/')
  R_SPACE = /\s+/g

  clipboard (event: Event, get:(elem: Element)=>string, action?:(elem: Element)=>'cut'|'copy') {
    const target = event.target as Element
    console.log(target)
    if (target) {
      const clipboard = new Clipboard(target, {
        text: get,
        action
      })
      clipboard.on('success', () => {
        mittError(new NotifyException('Copy successfully', 'success'))
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        mittError(new NotifyException('Copy failed', 'error'))
        clipboard.destroy()
      })
    }
  }

  // 转成驼峰命名格式
  camelCase (name: string): string {
    return name.replace(this.SPECIAL_CHARS_REGEXP, function (_, separator, letter, offset) {
      return offset ? letter.toUpperCase() : letter
    }).replace(this.MOZ_HACK_REGEXP, 'Moz$1')
  }

  // 节点是否包含class
  hasClass (el: HTMLElement, cls: string) {
    if (!el || !cls) return false
    if (cls.indexOf(' ') >= 0) throw new Error('className should not contain space.')
    if (el.classList) {
      return el.classList.contains(cls)
    } else {
      return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1
    }
  }

  // 动态添加class
  addClass (el: HTMLElement, cls: string) {
    if (!el) return
    let curClass = el.className
    const classes = (cls || '').split(' ')

    for (let i = 0, j = classes.length; i < j; i++) {
      const clsName = classes[i]
      if (!clsName) continue

      if (el.classList) {
        el.classList.add(clsName)
      } else if (!this.hasClass(el, clsName)) {
        curClass += ' ' + clsName
      }
    }
    if (!el.classList) {
      el.className = curClass
    }
  }

  // 动态移除class
  removeClass (el: HTMLElement, cls: string) {
    if (!el || !cls) return
    const classes = cls.split(' ')
    let curClass = ' ' + el.className + ' '
    for (let i = 0, j = classes.length; i < j; i++) {
      const clsName = classes[i]
      if (!clsName) continue

      if (el.classList) {
        el.classList.remove(clsName)
      } else if (this.hasClass(el, clsName)) {
        curClass = curClass.replace(' ' + clsName + ' ', ' ')
      }
    }
    if (!el.classList) {
      el.className = curClass?.trim()
    }
  }
  // 动态获取style值

  getStyle (element: HTMLElement, attr: keyof CSSStyleDeclaration) {
    try {
      return element.style[attr] || document.defaultView?.getComputedStyle(element, '')[attr]
    } catch (e) {
      return undefined
    }
  }

  // 动态设置style值
  setStyleValue (element: HTMLElement|undefined, styleName: keyof CSSStyleDeclaration, value: string, priority?: 'important') {
    if (!element) {
      console.log('element is lost setStyleValue fail')
      return
    }
    // @ts-ignore
    const sN = this.camelCase(styleName)
    element.style.setProperty(sN, value, priority)
  }

  // 判断是否可滚动节点
  isScroll (el: HTMLElement, vertical: boolean|undefined) {
    let overflow
    if (vertical === undefined) {
      overflow = this.getStyle(el, 'overflow')
    } else {
      if (vertical) {
        overflow = this.getStyle(el, 'overflowY')
      } else {
        overflow = this.getStyle(el, 'overflowX')
      }
    }
    return overflow?.toString().match(/(scroll|auto)/)
  }

  // 获取当前节点的上一层滚动容器
  getScrollContainer (el: any, vertical?: boolean) {
    let parent = el
    while (parent) {
      if ([window, document, document.documentElement].includes(parent)) {
        return window.document.scrollingElement
      }
      if (this.isScroll(parent, vertical)) {
        return parent
      }
      parent = parent.parentNode
    }
    return parent
  }

  // 判断当前节点是否包含在另外一个节点中
  isInContainer (el?: HTMLElement, container?: any) {
    if (!el || !container) return false
    const elRect = el.getBoundingClientRect()
    let containerRect
    if ([window, document, document.documentElement, null, undefined].includes(container)) {
      containerRect = {
        top: 0,
        right: window.innerWidth,
        bottom: window.innerHeight,
        height: window.innerHeight,
        width: window.innerWidth,
        left: 0
      }
    } else {
      containerRect = container.getBoundingClientRect()
    }

    return elRect.top < containerRect.bottom &&
      elRect.bottom > containerRect.top &&
      elRect.right > containerRect.left &&
      elRect.left < containerRect.right
  }

  // 判断当前位置是否包含在另外一个节点中
  isLocationInContainer (x:number, y:number, el?: Element|null) {
    if (!el) return false
    const elRect = el.getBoundingClientRect()
    return x > elRect.x && x < elRect.x + elRect.width && y > elRect.y && y < elRect.y + elRect.height
  }

  // 判断对象是否是element对象
  isHtmlElement (node: any) {
    return node && node.nodeType === Node.ELEMENT_NODE
  }

  // 获取元素到左边的绝对距离
  getParentLeft (e: HTMLElement) {
    const position = this.getStyle(e, 'position')
    let offset = e.offsetLeft
    // if (position === 'absolute') {
    //   return offset
    // }
    if (e.offsetParent != null) {
      offset += this.getParentLeft(e.offsetParent as HTMLElement)
    }
    return offset
  }

  // 获取元素到顶边的绝对距离
  getParentTop (e: HTMLElement) {
    const position = this.getStyle(e, 'position')
    let offset = e.offsetTop
    //
    // if (position === 'absolute') {
    //   console.log(e)
    //   return offset
    // }
    if (e.offsetParent != null) {
      offset += this.getParentTop(e.offsetParent as HTMLElement)
    }
    return offset
  }

  // 设置class是否生效
  useClass (el: HTMLElement, name: string, state: boolean) {
    if (el && name) {
      if (el.classList) {
        el.classList[state ? 'add' : 'remove'](name)
      } else {
        const className = (' ' + el.className + ' ').replace(this.R_SPACE, ' ').replace(' ' + name + ' ', ' ')
        el.className = (className + (state ? ' ' + name : '')).replace(this.R_SPACE, ' ')
      }
    }
  }

  // 获取window的滚动element
  getWindowScrollingElement () {
    const scrollingElement = document.scrollingElement
    if (scrollingElement) {
      return scrollingElement
    } else {
      return document.documentElement
    }
  }

  getHtmlFontSize () {
    return parseFloat(window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size'))
  }

  isSupportObjectFit () {
    return !!document.documentElement.style.objectFit
  }

  colorHexToRgb (str: string, alpha?: string) {
    const reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/
    if (!reg.test(str)) {
      console.log(str + '格式错误')
      return ''
    }
    let newStr = (str.toLowerCase()).replace(/\#/g, '')
    const len = newStr.length
    if (len === 3) {
      let t = ''
      for (let i = 0; i < len; i++) {
        t += newStr.slice(i, i + 1).concat(newStr.slice(i, i + 1))
      }
      newStr = t
    }
    const arr = [] // 将字符串分隔，两个两个的分隔

    for (let i = 0; i < newStr.length; i = i + 2) {
      const s = newStr.slice(i, i + 2)
      if (i === 6) {
        arr.push((parseInt('0x' + (alpha || s)) / 255).toFixedCustom(2))
      } else {
        arr.push(parseInt('0x' + s))
      }
    }
    if (arr.length === 4) {
      return 'rgba(' + arr.join(',') + ')'
    } else {
      return 'rgb(' + arr.join(',') + ')'
    }
  }

  colorRgbToHex (str: string) {
    const reg = /^(rgba|RGB)/
    if (!reg.test(str)) {
      throw new Error('格式错误')
    }

    const arr = str.slice(str.indexOf('(') + 1, str.length - 1).split(',')
    let color = '#'
    for (let i = 0; i < arr.length; i++) {
      let t
      if (i === 3) {
        t = Number(Math.ceil(Number(arr[i]) * 255)).toString(16)
      } else {
        t = Number(arr[i]).toString(16)
      }
      if (t === '0') { // 如果为“0”的话，需要补0操作,否则只有5位数
        t = t + '0'
      }
      color += t
    }
    return color
  }
}
