export enum DOCK_MODE_CODE {
  IDLE = 0,
  FIELD_DEBUG = 1,
  REMOTE_DEBUG = 2,
  UPGRADE = 3,
  WORKING = 4
}

export enum AIRCRAFT_MODE_CODE {
  // 飞行器状态     enum   {"0":"待机","1":"起飞准备","2":"起飞准备完毕","3":"手动飞行","4":"自动起飞","5":"航线飞行","6":"全景拍照","7":"智能跟随","8":"ADS-B躲避","9":"自动返航","10":"自动降落","11":"强制降落","12":"三桨叶降落","13":"升级中","14":"未连接",15":"辅助飞行功能 - APAS","16":"虚拟摇杆模式"}

  IDLE = 0,
  PREPARE = 1,
  PREPARE_READY = 2,
  M_FLY = 3,
  A_FLY = 4,
  W_FLY = 5,
  PHOTO_SPHERE = 6,
  FOLLOW = 7,
  ADS_B = 8,
  RETURN_HOME = 9,
  AUTO_LAND = 10,
  FORCE_LAND = 11,
  THREE_BLADED_BLADES_LAND = 12,
  UPGRADE = 13,
  DISCONNECTED = 14,
  APAS = 15,
  JOYSTICK = 16,
  COMMAND_FLY = 17
}
