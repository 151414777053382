import JlinkType from '@/common/global/JlinkType'
import { UserModule } from '@/store/User'
import {ASSIGNMENTS_STATUS, ASSIGNMENTS_TYPE} from "@/common/enum/JLINK_ENUMS";

export default class AssignmentsTypeMaker {
  isTempType (assignmentsOrAssignmentsType?: AssignmentsBaseVo | ASSIGNMENTS_TYPE) {
    if (typeof assignmentsOrAssignmentsType === 'object') {
      return assignmentsOrAssignmentsType?.assignmentsType === 0
    } else {
      return assignmentsOrAssignmentsType === 0
    }
  }

  isReservationType (assignmentsOrAssignmentsType?: AssignmentsBaseVo | ASSIGNMENTS_TYPE) {
    if (typeof assignmentsOrAssignmentsType === 'object') {
      return assignmentsOrAssignmentsType?.assignmentsType === 1
    } else {
      return assignmentsOrAssignmentsType === 1
    }
  }

  isQuickType (assignmentsOrAssignmentsType?: AssignmentsBaseVo | ASSIGNMENTS_TYPE) {
    if (typeof assignmentsOrAssignmentsType === 'object') {
      return assignmentsOrAssignmentsType?.assignmentsType === 2
    } else {
      return assignmentsOrAssignmentsType === 2
    }
  }

  isDockType (assignmentsOrAssignmentsType?: AssignmentsBaseVo | ASSIGNMENTS_TYPE) {
    if (typeof assignmentsOrAssignmentsType === 'object') {
      return assignmentsOrAssignmentsType?.assignmentsType === 3
    } else {
      return assignmentsOrAssignmentsType === 3
    }
  }

  isPrepareStatus (assignmentsOrAssignmentsStatus?: AssignmentsBaseVo | ASSIGNMENTS_STATUS) {
    if (typeof assignmentsOrAssignmentsStatus === 'object') {
      return assignmentsOrAssignmentsStatus.assignmentsStatus === 0
    } else {
      return assignmentsOrAssignmentsStatus === 0
    }
  }

  isGoingStatus (assignmentsOrAssignmentsStatus?: AssignmentsBaseVo | ASSIGNMENTS_STATUS) {
    if (typeof assignmentsOrAssignmentsStatus === 'object') {
      return assignmentsOrAssignmentsStatus.assignmentsStatus === 1
    } else {
      return assignmentsOrAssignmentsStatus === 1
    }
  }

  isCompleteStatus (assignmentsOrAssignmentsStatus?: AssignmentsBaseVo | ASSIGNMENTS_STATUS) {
    if (typeof assignmentsOrAssignmentsStatus === 'object') {
      return assignmentsOrAssignmentsStatus.assignmentsStatus === 2
    } else {
      return assignmentsOrAssignmentsStatus === 2
    }
  }

  isAssignmentsAdmin (assignmentsOrAssignmentsCreator?: AssignmentsBaseVo | string) {
    if (typeof assignmentsOrAssignmentsCreator === 'object') {
      return JlinkType.user.isAdmin.value || assignmentsOrAssignmentsCreator.userId === UserModule.user.userId
    } else {
      return JlinkType.user.isAdmin.value || assignmentsOrAssignmentsCreator === UserModule.user.userId
    }
  }
}
