export default class CanvasUtil {
  // 计算2个位置上相对正北的计算
  drawPoint () {
    const c = document.createElement('canvas')
    const settings = {
      canvas: c,
      canvasWidth: 150, // canvas的宽度
      canvasHeight: 65, // canvas的高度
      drawStartX: 15, // 绘制字符串起始x坐标 距离左侧
      drawStartY: 28, // 绘制字符串起始y坐标 距离顶部
      font: "12px 'Microsoft Yahei'", // 文字样式
      text: '请修改掉默认的配置', // 需要绘制的文本
      color: '#ffffff', // "#000000", //文字的颜色
      backgroundColor: 'rgba(38, 38, 38, 0.75)'// "#ffffff"//背景颜色
    }

    // 绘制带有圆角的背景
    CanvasRenderingContext2D.prototype.roundRect = function (x: number, y: number, w: number, h: number, radii?: number | DOMPointInit | (number | DOMPointInit)[]) {
      const radius = 5
      this.beginPath()
      this.moveTo(x + radius, y)
      this.lineTo(x + w - radius, y)
      this.quadraticCurveTo(x + w, y, x + w, y + radius)
      this.lineTo(x + w, y + h - radius)
      this.quadraticCurveTo(x + w, y + h, x + w - radius, y + h)
      this.lineTo(x + radius, y + h)
      this.quadraticCurveTo(x, y + h, x, y + h - radius)
      this.lineTo(x, y + radius)
      this.quadraticCurveTo(x, y, x + radius, y)
      this.fillStyle = settings.backgroundColor
      this.closePath()
    }

    // 获取2d的上线文开始设置相关属性
    const canvas = settings.canvas
    canvas.width = settings.canvasWidth
    canvas.height = settings.canvasHeight
    const ctx = canvas.getContext('2d')!

    // 绘制带有圆角的背景
    ctx.roundRect(0, 0, canvas.width, canvas.height, 15)
    // 填充文字
    ctx.font = settings.font
    ctx.fillStyle = settings.color
    ctx.fillText('终端名称:自动驾驶车01', settings.drawStartX, settings.drawStartY)
    ctx.fillText('速度:30 km/h', settings.drawStartX, settings.drawStartY + 20)
    return canvas.toDataURL()

    // 下载图片测试查看
    // var src = canvas.toDataURL();
    // var a = document.createElement('a');
    // var event = new MouseEvent('click');
    // a.download = (new Date()).getTime() + ".jpg"; // 指定下载图片的名称
    // a.href = src;
    // a.dispatchEvent(event); // 触发超链接的点击事件
  }
}
