import { watch, WatchCallback, WatchOptions, WatchSource, WatchStopHandle } from 'vue'
import JlinkUtils from '@/common/global/JlinkUtils'

export default class BaseHelper {
  protected id = JlinkUtils.random.uuid(12)
  private watchStopHandleSave: StringKeyRecord<WatchStopHandle> = {}
  private timeIntervalMap: StringKeyRecord<NodeJS.Timer> = {}

  watchSource<T, Immediate extends Readonly<boolean> = false> (source: WatchSource<T>, cb: WatchCallback<T, Immediate extends true ? T | undefined : T>, options?: WatchOptions<Immediate>, key?:string): WatchStopHandle {
    const watchKey = (key || JlinkUtils.random.uuid(8)) + this.id
    this.stopWatchSource((key || JlinkUtils.random.uuid(8)))
    const stopHandle = watch(source, cb, options)
    this.watchStopHandleSave[watchKey] = stopHandle
    return stopHandle
  }

  stopWatchSource (key:string) {
    const watchKey = key + this.id
    const stopHandle = this.watchStopHandleSave[watchKey]
    if (stopHandle !== undefined) {
      stopHandle()
      delete this.watchStopHandleSave[watchKey]
    }
  }

  addInterval (key: string, handler: TimerHandler, timeout: number) {
    const watchKey = key + this.id
    this.deleteInterval(key)
    const interval = setInterval(handler, timeout)
    this.timeIntervalMap[watchKey] = interval
  }

  haveIntervalKey (key:string) {
    return this.timeIntervalMap[key + this.id] !== undefined
  }

  deleteInterval (key: string) {
    const watchKey = key + this.id
    const interval = this.timeIntervalMap[watchKey]
    if (interval !== undefined) {
      clearInterval(interval)
      delete this.timeIntervalMap[watchKey]
    }
  }

  destroy () {
    Object.values(this.watchStopHandleSave).forEach(i => {
      i()
    })
    this.watchStopHandleSave = {}
    Object.values(this.timeIntervalMap).forEach(value => {
      clearInterval(value)
    })
    this.timeIntervalMap = {}
  }
}
