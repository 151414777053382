import DjiCloudException from '@/common/errors/DjiCloudException'

interface DjiCloudComponent {

  // 设备上云模块
  thing: {
    host: string
    connectCallback: string
    username: string
    password: string
  }

  // 直播模块
  liveshare: {
    // video-on-demand：服务器点播，依赖于thing模块，具体的点播命令参见设备物模型的直播服务。
    // video-by-manual：手动点播，配置好直播类型参数之后，在图传页面可修改直播参数，停止直播。
    // video-demand-aux-manual：混合模式，支持服务器点播，以及图传页面修改直播参数，停止直播。
    videoPublishType: 'video-demand-aux-manual' | 'video-by-manual' | 'video-on-demand',
    statusCallback: string
  }
  // API模块
  api: {
    host: string, // HTTP接口中的 ： {URI-scheme}://{Endpoint}
    token: string // header中将会带上该X-Auth-Token,
  }
  // WS模块
  ws: {
    host: string, // 连接示例中的 wss://xxx.xxxx.xxx:8883
    token: string, // token 将会被urlencode之后拼接在x-auth-token中
    connectCallback: string // 连接上之后将会回调js_callback

  }
  // 地图元素模块
  map: {
    uerName: string, // 用户名称，用于当创建地图元素时，元素的名称
    elementPreName: string // 地图元素序号前缀
  }

  // TSA态势感知模块
  tsa: {}
  // Media媒体模块
  media: {
    autoUploadPhoto?: boolean, // 是否自动上传图片, 非必需
    autoUploadPhotoType?: 0 | 1, // 自动上传的照片类型，0：原图， 1：缩略图， 非必需
    autoUploadVideo?: boolean // 是否自动上传视频， 非必需
  }
  // 航线模块
  mission: {}
}

declare let window: any

interface JsBridgeResponse<T> {
  code: number,
  message: string,
  data: T
}

export class ModuleBase {
  protected bridgeSetBlock<T> (callback: () => string): T {
    if (!window.djiBridge) {
      throw new DjiCloudException('djiBridge 模块不存在', -1)
    }
    const result = callback()
    const json = JSON.parse(result) as JsBridgeResponse<T>
    if (json.code === 0) {
      return json.data
    } else {
      let message = ''
      switch (json.code) {
        case 615000:
          message = json.message
          break
        case 615001:
          message = '设备未连接'
          break
        case 615002:
          message = '参数不合法'
          break
        case 615003:
          message = 'API模块未初始化'
          break
        case 615004:
          message = 'API模块token非法'
          break
        case 615005:
          message = 'WS模块未初始化'
          break
        case 615006:
          message = 'WS模块token/host不合法'
          break
        case 615007:
          message = 'WS模块的数据不合法'
          break
        case 615008:
          message = '直播模块未加载'
          break
        case 615009:
          message = '直播模块不是手动直播，无法设置直播参数'
          break
        case 615010:
          message = '地图模块没有初始化'
          break
        case 615011:
          message = '上云物模型模块未初始化'
          break
        case 615012:
          message = '媒体库模块未初始化'
          break
        default:
          message = json.message
          break
      }
      throw new DjiCloudException(message, json.code)
    }
  }

  isComponentLoaded<T extends keyof DjiCloudComponent> (module: T) {
    return this.bridgeSetBlock(() => window.djiBridge.platformIsComponentLoaded(module))
  }

  loadComponent<T extends keyof DjiCloudComponent> (name: T, param: DjiCloudComponent[T]) {
    return this.bridgeSetBlock(() => window.djiBridge.platformLoadComponent(name, JSON.stringify(param)))
  }

  unloadComponent<T extends keyof DjiCloudComponent> (name: T) {
    return this.bridgeSetBlock(() => window.djiBridge.platformUnloadComponent(name))
  }
}
