import FlyBigemapHelper from '@/common/helper/map/FlyBigemapHelper'
import { AssignmentsOpenModule } from '@/store/AssignmentsOpen'
import { Entity } from 'cesium'
import CesiumService from '@/components/cesium/CesiumService'
import JlinkMqtt2 from '@/common/global/JlinkMqtt2'

export default class AssignmentsBigemapHelper extends FlyBigemapHelper {
  async mapServiceLoaded (service: CesiumService) {
    await super.mapServiceLoaded(service)

    const _this = this
    this.watchSource(() => AssignmentsOpenModule.drones.mapBy('droneId'), function (value, oldValue) {
      const oldValues = oldValue || []
      const newValues = value || []
      oldValues.diffTo(newValues, function (s, t, from) {
        return s === t
      }, function (sl) {
        for (const s of sl) {
          _this.stopWatchSource('online' + s)
          _this.stopWatchSource('aircraftOsd' + s)
          _this.stopWatchSource('dockOsd' + s)
          _this.stopWatchSource('rcOsd' + s)
          _this.stopWatchSource('flightTask' + s)
        }
      }, function (sl) {
        for (const s of sl) {
          const drone = AssignmentsOpenModule.drones.findBy('droneId', s)!
          _this.watchSource(() => JlinkMqtt2.getReactiveDevice(drone).getOnlineValue(), function (value) {
            if (!value?.aircraftOnline) {
              _this.removeAircraftTrack(drone.aircraftSerialNumber)
              _this.removeTrailTrack(drone.aircraftSerialNumber)
            }
            if (!value?.gatewayOnline) {
              _this.removeGatewayTrack(drone.gatewaySerialNumber).then()
            }
          }, { immediate: true }, 'online' + s)
          _this.watchSource(() => JlinkMqtt2.getReactiveDevice(drone).getAircraftOsdValue(), function (osd) {

            if (osd) {
              if (AssignmentsOpenModule.data.trail.has(osd.aircraftSn)) {
                _this.addTrailTrack({ aircraftSn: osd.aircraftSn, selected: osd.aircraftSn === _this.selectedAircraftSn })
              } else {
                _this.removeTrailTrack(osd.aircraftSn)
              }
              _this.addAircraftTrack(osd).then()
            } else {
              _this.removeAircraftTrack(drone.aircraftSerialNumber)
              _this.removeTrailTrack(drone.aircraftSerialNumber)
            }
          }, undefined, 'aircraftOsd' + s)

          _this.watchSource(() => JlinkMqtt2.getReactiveDevice(drone).getDockOsdValue(), function (osd) {
            if (osd) {
              _this.addDockTrack(osd).then()
            } else {
              _this.removeGatewayTrack(drone.gatewaySerialNumber).then()
            }
          }, undefined, 'dockOsd' + s)
          _this.watchSource(() => JlinkMqtt2.getReactiveDevice(drone).getRcOsdValue(), function (osd) {
            if (osd) {
              _this.addRcTrack(osd).then()
            } else {
              _this.removeGatewayTrack(drone.gatewaySerialNumber).then()
            }
          }, undefined, 'rcOsd' + s)
          _this.watchSource(() => JlinkMqtt2.getReactiveDevice(drone).getDockFlightTaskProcessValue(), function (osd) {
            _this.addWaylineTrack(osd)
          }, undefined, 'flightTask' + s)
        }
      }, function (sl) {
        return sl
      })
    }, { immediate: true })

    this.setFollow(AssignmentsOpenModule.data.follow)
    this.watchSource(() => AssignmentsOpenModule.data.follow, async function (v) {
      _this.setFollow(v)
    }, { immediate: true })
    this.watchSource(() => AssignmentsOpenModule.currentSelectDrone, async function (v) {
      _this.selectGatewayAll(v)
    }, { immediate: true })
    const entityChangedAbstract = function (e?: Entity) {
      console.log(e)
      const aircraftEntityRealId = _this.getAircraftEntityRealIdFromEntity(e)
      if (aircraftEntityRealId) {
        AssignmentsOpenModule.CHANGE_DRONE_FOLLOW(AssignmentsOpenModule.drones.findBy('aircraftSerialNumber', aircraftEntityRealId))
        return
      }
      const gatewayLandEntityRealId = _this.getGatewayLandEntityRealIdFromEntity(e)

      if (gatewayLandEntityRealId) {
        AssignmentsOpenModule.CHANGE_DRONE_FOLLOW(AssignmentsOpenModule.drones.findBy('gatewaySerialNumber', gatewayLandEntityRealId))
        return
      }
      const gatewayEntityRealId = _this.getGatewayEntityRealIdFromEntity(e)
      if (gatewayEntityRealId) {
        AssignmentsOpenModule.CHANGE_DRONE_FOLLOW(AssignmentsOpenModule.drones.findBy('gatewaySerialNumber', gatewayEntityRealId))
        return
      }
      const trailEntityRealId = _this.getTrailEntityRealIdFromEntity(e)
      if (trailEntityRealId) {
        AssignmentsOpenModule.CHANGE_DRONE_FOLLOW(AssignmentsOpenModule.drones.findBy('aircraftSerialNumber', trailEntityRealId))
        return
      }
      AssignmentsOpenModule.CHANGE_DRONE_FOLLOW()
    }
    service.viewer.removeOnSelectedEntityChanged(entityChangedAbstract).addOnSelectedEntityChanged(entityChangedAbstract, _this)
  }

  async destroy () {
    await super.destroy()
  }
}
