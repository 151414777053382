export default class DockOsdTransform {
  toDockModeCodeDesc (modeCode?: number) {
    switch (modeCode) {
      case 0:return { name: '空闲中', level: 'status-free' }
      case 1:return { name: '现场调试', level: 'status-remind' }
      case 2:return { name: '远程调试', level: 'status-remind' }
      case 3:return { name: '固件升级中', level: 'status-remind' }
      case 4:return { name: '作业中', level: 'status-task' }
      default:return { name: '离线中', level: 'danger' }
    }
  }

  toNetworkQualityDesc (networkQuality: 0 | 1 | 2) {
    switch (networkQuality) {
      case 0:
        return '差'
      case 1:
        return '中'
      case 2:
        return '好'
      default:
        return 'unknown'
    }
  }

  toNetworkTypeDesc (networkQuality?: 1 | 2) {
    if (networkQuality === undefined || networkQuality === null) {
      return 'unknown'
    }
    switch (networkQuality) {
      case 1:
        return '4G'
      case 2:
        return '以太网'
      default:
        return 'unknown'
    }
  }

  toAircraftInDockDesc (inDock?: 0 | 1) {
    if (inDock === undefined || inDock === null) {
      return 'unknown'
    }
    switch (inDock) {
      case 0:
        return '舱外'
      case 1:
        return '舱内'
      default:
        return 'unknown'
    }
  }

  toWirelessLinkLinkWorkModeDesc (mode?: 0 | 1) {
    if (!mode === undefined || mode === null) {
      return 'unknown'
    }
    switch (mode) {
      case 0:
        return 'sdr模式'
      case 1:
        return '4G融合模式'
      default:
        return 'unknown'
    }
  }

  toWirelessLinkStateDesc (mode?: 0 | 1) {
    switch (mode) {
      case 0:
        return '已断开'
      case 1:
        return '已连接'
      default:
        return 'unknown'
    }
  }

  toRainFallDesc (mode?: 0 | 1 | 2 | 3) {
    if (mode === undefined || mode === null) {
      return 'unknown'
    }
    switch (mode) {
      case 0:
        return '无雨'
      case 1:
        return '小雨'
      case 2:
        return '中雨'
      case 3:
        return '大雨'
      default:
        return '--'
    }
  }

  toAirConditionerModeDesc (mode?:number) {
    switch (mode) {
      case 0:
        return '空闲模式'
      case 1:
        return '制冷模式'
      case 2:
        return '加热模式'
      case 3:
        return '除湿模式'
      case 4:
        return '制冷退出模式'
      case 5:
        return '制热退出模式'
      case 6:
        return '除湿退出模式'
      case 7:
        return '制冷准备模式'
      case 8:
        return '制热准备模式'
      case 9:
        return '除湿准备模式'
      default:
        return 'unknown'
    }
  }

  toBatteryStoreModeDesc (mode?: 1 | 2) {
    switch (mode) {
      case 1:
        return '电池计划存储策略'
      case 2:
        return '电池应急存储策略'
      default:
        return 'unknown'
    }
  }

  toAlarmStateDesc (mode?: 0 | 1) {
    switch (mode) {
      case 0:
        return '声光报警关闭'
      case 1:
        return '声光报警开启'
      default:
        return 'unknown'
    }
  }

  toAircraftBatteryMaintenanceStateDesc (mode: 0 | 1 | 2) {
    switch (mode) {
      case 0:
        return '无需保养'
      case 1:
        return '待保养'
      case 2:
        return '正在保养'

      default:
        return 'unknown'
    }
  }

  toAircraftBatteryStateDesc (mode?: 0 | 1) {
    switch (mode) {
      case 0:
        return '空闲'
      case 1:
        return '充电中'
      default:
        return 'unknown'
    }
  }

  toEmergencyStopStateDesc (mode?: 0 | 1) {
    switch (mode) {
      case 0:
        return '关闭'
      case 1:
        return '开启'
      default:
        return 'unknown'
    }
  }

  toPositionFixedDesc (mode: 0 | 1 | 2 | 3) {
    switch (mode) {
      case 0:
        return 'idle'
      case 1:
        return '收敛'
      case 2:
        return '成功'
      case 3:
        return '失败'

      default:
        return 'unknown'
    }
  }

  toCoverStateDesc (mode?: 0 | 1 | 2 | 3) {
    switch (mode) {
      case 0:
        return '关闭'
      case 1:
        return '开启'
      case 2:
        return '半开'
      case 3:
        return '异常'

      default:
        return 'unknown'
    }
  }

  toSupplementLightStateDesc (mode?: 0 | 1) {
    switch (mode) {
      case 0:
        return '关闭'
      case 1:
        return '开启'

      default:
        return 'unknown'
    }
  }

  toBackupBatterySwitch (mode?: 0 | 1) {
    switch (mode) {
      case 0:
        return '关闭'
      case 1:
        return '开启'

      default:
        return 'unknown'
    }
  }

  toAlternateLandPointIsConfigured (mode?: 0 | 1) {
    switch (mode) {
      case 0:
        return '未设置'
      case 1:
        return '已设置'
      default:
        return 'unknown'
    }
  }

  toHmsListLevel (mode: 0 | 1 | 2 | 4) {
    switch (mode) {
      case 0:
        return '通知'
      case 1:
        return '提醒'
      case 2:
      case 4:
        return '警告'

      default:
        return 'unknown'
    }
  }

  toHmsListModule (mode: 0 | 1 | 2 | 3 | 4) {
    switch (mode) {
      case 0:
        return '飞行任务'
      case 1:
        return '设备管理'
      case 2:
      case 4:
        return ''
      case 3:
        return 'hms'
      default:
        return 'unknown'
    }
  }

  translateHms (i:HmsResult) {
    let style: 'hms-warning' | 'hms-remind' | 'hms-notice'
    switch (i.level) {
      case 2:
        style = 'hms-warning'
        break
      case 1:
        style = 'hms-remind'
        break
      case 0:
        style = 'hms-notice'
        break
      default:
        style = 'hms-remind'
        break
    }
    return { style, text: i.messageZh }
  }
}
