import _ from 'lodash'
export default function JlinkThrottle (wait?:number, leading?: boolean, trailing?: boolean) {
  return function <T extends Function | Object, R extends (...arg:any) => Promise<void >|void> (target: T, key: string | symbol, descriptor: TypedPropertyDescriptor<R>) {
    if (typeof target === 'function') {
      const value = target.prototype.constructor[key].bind(target) as ()=>never
      target.prototype.constructor[key] = _.throttle(value, wait || 0, { trailing, leading })
      return target.prototype.constructor[key]
    } else {
      // const value = descriptor.value
      // // @ts-ignore
      // descriptor.value = _.throttle(value, wait || 0, { trailing: trailing, leading: leading })
      // return descriptor
      const fn = descriptor.value
      return {
        configurable: descriptor.configurable,
        enumerable: descriptor.enumerable,
        get: function () {
          const boundFn = _.throttle((fn || (descriptor.get && descriptor.get.apply(this))!.bind(this)), wait || 0, { trailing, leading })
          // 将绑定后的方法重新定义为当前属性的值
          Object.defineProperty(this, key, {
            value: boundFn,
            configurable: true,
            writable: true,
          })
          return boundFn
        },
      }
    }
  }
}
