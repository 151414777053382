import { ref, Ref, reactive, UnwrapNestedRefs, computed } from 'vue'
import { getDroneListByPage } from '@/api/api.drone'
import _ from 'lodash'
import FlyBigemapHelper from '@/common/helper/map/FlyBigemapHelper'
import NotifyException from '@/common/errors/NotifyException'
import { editWaylineInfo, makeNewWayline } from '@/api/api.wayline'
import { mittError } from '@/common/mitt/mitt'
import { Color, Entity, HeadingPitchRange } from 'cesium'
import CesiumService from '@/components/cesium/CesiumService'
import { DjiAircraft, DjiDeviceEnum, DjiPayload } from '@/common/dji/DjiDeviceEnum'
import { ActionType } from '@/app/views/pages/library/wayline/WaylineValuesEnum'
import JlinkMqtt2 from '@/common/global/JlinkMqtt2'
import JlinkTransform from '@/common/global/JlinkTransform'

type ActionsAircraftGroup={
  actionType: 'aircraft',
  actionTitle: '飞行器动作',
  actionList:{
    id:string,
    actionName:string
    data: Omit<ActionRotateYaw, 'id'>| Omit<ActionHover, 'id'>
  }[]
}

type ActionsGimbalGroup={
  actionType: 'gimbal',
  actionTitle: '云台动作',
  actionList:{
    id:string,
    actionName:string
    data: Omit<ActionGimbalRotate, 'id'>
  }[]
}

type ActionsCameraGroup={
  actionType: 'camera',
  actionTitle: '相机动作',
  actionList:{
    id:string,
    actionName:string
    data: Omit<ActionTakePhoto, 'id'> |
      Omit<ActionStartRecord, 'id'> |
      Omit<ActionStopRecord, 'id'> |
      Omit<ActionZoom, 'id'>
  }[]
}

export default abstract class WaylineBigemapHelper<T extends TemplateWaylineInfo> extends FlyBigemapHelper {
  private dockLinePrefix = 'dockLinePrefix'
  // 航线数据
  dataRef: UnwrapNestedRefs<T>
  // 航线数据快照
  private readonly snapshotData: T

  // 航线数据完整
  abstract canSave(): T|undefined

  abstract firstPoint: Ref<JlinkLocation | undefined>
  dockers = reactive<DroneBaseVo[]>([])
  currentDocker = ref<DroneBaseVo>()
  aircraftCapacity = ref<DjiAircraft>()
  payloadCapacity = ref<DjiPayload>()
  private readonly fitBoundsWhenPointChanged:boolean

  getActionTypeListValue () {
    const payloadCapacity = this.payloadCapacity.value
    const list :(ActionsAircraftGroup|ActionsGimbalGroup|ActionsCameraGroup)[] = []
    list.push({
      actionType: 'aircraft',
      actionTitle: '飞行器动作',
      actionList: [
        {
          id: ActionType.HOVER,
          actionName: '悬停',
          data: {
            actionType: ActionType.HOVER,
            hoverTime: 10
          }
        },
        {
          id: ActionType.ROTATE_YAW,
          actionName: '飞行器偏航角',
          data: {
            actionType: ActionType.ROTATE_YAW,
            aircraftHeading: 0
          }
        }
      ]
    })

    const pitchAble = payloadCapacity?.ptzPitch[0] !== payloadCapacity?.ptzPitch[1]
    if (payloadCapacity && pitchAble) {
      list.push({
        actionType: 'gimbal',
        actionTitle: '云台动作',
        actionList: [{
          id: ActionType.GIMBAL_ROTATE,
          actionName: '云台参数',
          data: {
            actionType: ActionType.GIMBAL_ROTATE,
            gimbalPitchRotateAngle: 0,
            gimbalRotateTime: 0,
          }
        }]
      })
    }
    const cameraActions:ActionsCameraGroup = {
      actionType: 'camera',
      actionTitle: '相机动作',
      actionList: []
    }

    if (payloadCapacity && payloadCapacity.supportLens.length > 0) {
      cameraActions.actionList.push({
        id: ActionType.TAKE_PHOTO,
        actionName: '拍照',
        data: {
          actionType: ActionType.TAKE_PHOTO,
          payloadLensIndex: payloadCapacity.supportLens,
        }
      })
      cameraActions.actionList.push({
        id: ActionType.START_RECORD,
        actionName: '开始录像',
        data: {
          actionType: ActionType.START_RECORD,
          payloadLensIndex: payloadCapacity.supportLens,
        }
      })
      cameraActions.actionList.push({
        id: ActionType.STOP_RECORD,
        actionName: '停止录像',
        data: {
          actionType: ActionType.STOP_RECORD,
          payloadLensIndex: payloadCapacity.supportLens,
        }
      })
    }
    if (payloadCapacity?.cameraParam.zoom && payloadCapacity.cameraParam.zoom?.hybridZoomRate[0] !== payloadCapacity.cameraParam.zoom?.hybridZoomRate[1]) {
      cameraActions.actionList.push({
        id: ActionType.ZOOM,
        actionName: '相机变焦',
        data: {
          actionType: ActionType.ZOOM,
          focalLength: payloadCapacity.cameraParam.zoom.hybridZoomRate[0]
        }
      })
    }
    if (cameraActions.actionList.length > 0) {
      list.push(cameraActions)
    }

    return list
  }

  dataChanged () {
    return !_.isEqual(JSON.stringify(this.dataRef), JSON.stringify(this.snapshotData))
  }

  protected constructor (data: T, bigemapRef: Ref<{ mapService: () => Promise<CesiumService> | CesiumService } | undefined>, options?: { fitBoundsWhenPointChanged?: boolean }) {
    super(bigemapRef)
    const _this = this
    this.fitBoundsWhenPointChanged = options?.fitBoundsWhenPointChanged || false
    this.snapshotData = JSON.parse(JSON.stringify(data))
    this.dataRef = reactive(data)
    this.watchSource(() => this.dataRef.common.droneInfo, async function (value) {
      _this.aircraftCapacity.value = DjiDeviceEnum.getAircraftByEnumKey(value)
    }, { immediate: true })
    this.watchSource(() => this.dataRef.common.payloadInfo, async function (value) {
      _this.payloadCapacity.value = DjiDeviceEnum.getPayloadByEnumKey(value)
      _this.dataRef.common.globalPayloadLens = _this.payloadCapacity.value?.supportLens || []
    }, { immediate: true })
  }

  async openAsEdit (): Promise<void> {
    await this.drawDocks()
  }

  private async drawDocks () {
    const value = await getDroneListByPage({ gatewayDomain: '3', page: 0, size: 100 }).then(i => i.data.filter(item => JlinkMqtt2.getGatewayOnline(item)))
    this.dockers.length = 0
    this.dockers.push(...value)
    // 如果是添加航线模式需要显示机场位置
    // 实体类构建
    const entityMaker = await this.getEntity()
    // 场景类构建
    const sceneMaker = await this.getScene()
    // 地图构建
    const _this = this

    for (const drone of value) {
      _this.watchSource(() => JlinkMqtt2.getReactiveDevice(drone).getDockOsdValue(), async function (osd) {
        if (osd) {
          _this.addDockTrack(osd).then()
        } else {
          await _this.removeGatewayTrack(drone.gatewaySerialNumber)
        }
      })
    }

    this.watchSource(() => [this.currentDocker.value, this.firstPoint.value], async function (value) {
      const dock = value[0] as DroneBaseVo | undefined
      const firstPoint = value[1] as JlinkLocation | undefined
      const dockPosition = JlinkMqtt2.getReactiveDevice(dock)?.getDockOsdValue()
      if (dockPosition && firstPoint) {
        const marker = entityMaker.getById(_this.makeDockLineId('dockLine'))
        if (marker) {
          marker.drawPolyline().setPositionsByPositions([dockPosition, firstPoint])
        } else {
          entityMaker.makeById(_this.makeDockLineId('dockLine'))
            .drawPolyline()
            .setWidth(4)
            .setPolylineDashMaterial({ color: Color.WHITESMOKE })
            .setClampToGround(true)
            .setPositionsByPositions([dockPosition, firstPoint])
            .end()
            .addToView()

          const distance = await _this.computeDistance(dockPosition, firstPoint)
          const betweenPoint = await _this.getBetweenCenter(dockPosition, firstPoint)
          entityMaker.makeById('dockLine')
            .drawLabel()
            .setText(distance.toFixedCustom(1) + 'm')
            .setShowBackground(true)
            .setBackgroundColor(Color.fromCssColorString('rgba(0,0,0,0.8)'))
            .setBackgroundPadding(8, 8)
            .setFont('14px sans-serif')
            .setFillColor(Color.WHITE)
            .setPixelOffset(0, 0)
            .end()
            .setPosition(betweenPoint)
            .addToView()
        }
      } else {
        entityMaker.removeById(_this.makeDockLineId('dockLine'))
        sceneMaker.requestRender()
      }
    }, { immediate: true, deep: true })

    await this.setEntityPickLeftClick(function (bigemapLocation, e) {
      const gatewayId = _this.getGatewayEntityRealIdFromEntity(e?.entity)
      if (gatewayId) {
        if (_this.currentDocker.value?.gatewaySerialNumber === gatewayId) {
          _this.selectGateway(undefined)
        } else {
          _this.selectGateway(gatewayId)
        }
      }
    })
  }

  private makeDockLineId (id: string) {
    return this.dockLinePrefix + id
  }

  override async selectGateway (gatewaySn?: string): Promise<void> {
    await super.selectGateway(gatewaySn)
    const target = await this.getGatewayEntity(gatewaySn)
    if (target) {
      this.flyTo([target]).then()
    }
    this.currentDocker.value = this.dockers.findBy('gatewaySerialNumber', gatewaySn)
  }

  async flyToDocks () {
    const dockEntities = await this.getGatewayEntityAll()
    await this.flyTo(dockEntities, { offset: new HeadingPitchRange(0, 0, 10000) })
  }

  async listChanged (list: string[]): Promise<void> {
    if (this.fitBoundsWhenPointChanged) {
      await this.flyToEntities()
    }
  }

  async addOrUpdateWayline (): Promise<void> {
    const saveData = this.canSave()
    if (saveData) {
      if (saveData.common.waylineId) {
        await editWaylineInfo(saveData)
      } else {
        try {
          const data = await makeNewWayline(saveData)
          if (data && data.waylineId) {
            this.dataRef.common.waylineId = data.waylineId
          } else {
            mittError(new NotifyException('NOTFOUND', 'error'))
          }
        } catch (e) {
          throw new NotifyException(e + '', 'error')
        }
      }
    } else {
      throw new NotifyException('航线数据不完整，提交失败', 'warning')
    }
  }
}
