import { computed, nextTick, Ref, ref, WatchStopHandle } from 'vue'
import { mittError } from '@/common/mitt/mitt'
import NotifyException from '@/common/errors/NotifyException'
import WaylineBigemapHelper from '@/common/helper/map/WaylineBigemapHelper'
import JlinkUtils from '@/common/global/JlinkUtils'
import JlinkTask from '@/common/global/JlinkTask'
import { ActionType } from '@/app/views/pages/library/wayline/WaylineValuesEnum'
import _ from 'lodash'
import { Color, HeightReference } from 'cesium'
import CesiumService from '@/components/cesium/CesiumService'
import DataException from '@/common/errors/DataException'

export default class WaypointBigemapHelper extends WaylineBigemapHelper<TemplateWaypointInfo> {
  firstPoint = computed(() => this.dataRef.points.first())
  lastPoint = computed(() => this.dataRef.points.last())

  flyToWaylineMode = computed(() => this.dataRef.common.flyToWaylineMode)
  currentPoint = ref<WaypointPoints>()
  currentPointIndex = computed(() => {
    if (this.currentPoint.value) {
      return this.pointList.value.indexOf(this.currentPoint.value)
    } else {
      return -1
    }
  })

  currentPointId = computed(() => this.currentPoint.value?.id)
  currentPointWaypointHeadingMode = computed(() => this.currentPoint.value?.waypointHeadingMode)
  currentPointNext = computed(() => {
    if (this.currentPoint.value) {
      const index = this.dataRef.points.indexOf(this.currentPoint.value)
      return this.dataRef.points[index + 1]
    } else {
      return undefined
    }
  })

  pointList = computed(() => this.dataRef?.points || [])

  currentPointCoordinateAdjustment (direction: 'up' | 'right' | 'left' | 'down') {
    if (this.currentPoint.value) {
      switch (direction) {
        case 'up':
          this.currentPoint.value.lat = (this.currentPoint.value.lat + 0.000001).toFixedCustom(9)
          break
        case 'down':
          this.currentPoint.value.lat = (this.currentPoint.value.lat - 0.000001).toFixedCustom(9)
          break
        case 'right':
          this.currentPoint.value.lng = (this.currentPoint.value.lng + 0.000001).toFixedCustom(9)
          break
        case 'left':
          this.currentPoint.value.lng = (this.currentPoint.value.lng - 0.000001).toFixedCustom(9)
          break
        default:
          break
      }
    } else {
      console.error(' this.currentPoint is lost')
    }
  }

  currentPointActionAdd (actionData: WaylinePointAction) {
    if (this.currentPoint.value) {
      this.currentPoint.value.actions.push(actionData)
    } else {
      console.error(' this.currentPoint is lost')
    }
  }

  currentPointSelect (id?: string): void {
    this.currentPoint.value = this.pointList.value.findBy('id', id)
  }

  currentPointDel () {
    const currentId = this.currentPoint.value?.id
    const index = (currentId && this.pointList.value.mapBy('id').indexOf(currentId)) || -1
    this.pointList.value.removeIf('id', currentId)
    this.currentPoint.value = this.pointList.value[index] || this.pointList.value.last()
    const last = this.pointList.value.last()
    last && (last.distance = 0)
  }

  currentPointActionDel (actionId: string) {
    if (this.currentPoint.value) {
      const actions = this.currentPoint.value.actions
      actions?.removeIf('id', actionId)
    } else {
      console.error(' this.currentPoint is lost')
    }
  }

  /* 切换航点 */
  next (): void {
    if (this.currentPoint.value) {
      const index = this.pointList.value.indexOf(this.currentPoint.value) + 1
      const target = this.pointList.value[index]
      if (target) {
        this.currentPoint.value = target
      } else {
        this.currentPoint.value = this.pointList.value.first()
      }
    } else {
      this.currentPoint.value = this.pointList.value.first()
    }
  }

  pre (): void {
    if (this.currentPoint.value) {
      const index = this.pointList.value.indexOf(this.currentPoint.value) - 1
      const target = this.pointList.value[index]
      if (target) {
        this.currentPoint.value = target
      } else {
        this.currentPoint.value = this.pointList.value.last()
      }
    } else {
      this.currentPoint.value = this.pointList.value.last()
    }
  }

  constructor (data: TemplateWaypointInfo, bigemapRef: Ref<{
    mapService: () => Promise<CesiumService>|CesiumService
  } | undefined>, options?:{fitBoundsWhenPointChanged?:boolean}) {
    super(data, bigemapRef, options)
    this.drawWaypoint().then()
  }

  async openAsEdit () {
    await super.openAsEdit()
    const _this = this
    this.watchSource(() => this.dataRef.common.flyToWaylineMode, function () {
      _this.dataRef.common.takeOffSecurityHeight = 30
    })
    // 航点高度跟随全局高度
    this.watchSource(() => this.dataRef.height, function (value) {
      if (_this.dataRef.common.heightMode === 'relativeToStartPoint') {
        _this.dataRef.ellipsoidHeight = value
      }
      _this.dataRef.points.forEach(item => {
        if (item.useGlobalHeight) {
          item.height = value
        }
      })
    })
    this.watchSource(() => this.dataRef.globalWaypointHeadingMode, function (value) {
      _this.dataRef.points.forEach(item => {
        if (item.useGlobalWaypointHeadingMode) {
          item.waypointHeadingMode = value
        }
      })
    })

    this.watchSource(() => this.dataRef.globalWaypointTurnMode, function (value) {
      _this.dataRef.points.forEach(item => {
        if (item.useGlobalWaypointTurnMode) {
          item.waypointTurnMode = value
        }
      })
    })
    this.watchSource(() => this.dataRef.globalWaypointHeadingPathMode, function (value) {
      _this.dataRef.points.forEach(item => {
        if (item.useGlobalWaypointHeadingPathMode) {
          item.waypointHeadingPathMode = value
        }
      })
    })

    // 航点速度跟随全局速度
    this.watchSource(() => this.dataRef.common.autoFlightSpeed, function (value) {
      _this.dataRef.points.forEach(item => {
        if (item.useGlobalSpeed) {
          item.waypointSpeed = value
        }
      })
    })

    const pointsLocation = computed(() => this.dataRef.points.map(i => {
      return {
        distance: i.distance,
        waypointSpeed: i.waypointSpeed
      }
    }))

    this.watchSource(() => pointsLocation.value, async function (value, oldValue) {
      _this.dataRef.common.distance = value.mapByAndFilterNullOrUndefined('distance').total()
      _this.dataRef.common.estimatedTime = value.map(i => {
        if (i.waypointSpeed && i.distance) {
          return i.distance / i.waypointSpeed
        } else {
          return 0
        }
      }).total()
    }, { deep: true })

    // 监听航点选中
    this.watchSource(() => this.currentPointId.value, async function (value, oldValue) {

      if (oldValue) {
        _this.getPointEntity(oldValue)
          .then(res => {
            res?.drawModel().setUri(_this.aircraftBlueGlb())
          })
      }
      if (value) {
        JlinkTask.asyncGet(() => _this.getPointEntity(value))
          .then(res => {
            res?.drawModel().setUri(_this.aircraftGlb())
          })
      }
    })

    this.watchSource(() => this.currentPointWaypointHeadingMode.value, async function (value) {
      if (_this.currentPoint.value && value !== 'towardPOI') {
        delete _this.currentPoint.value.waypointPoiPoint
      }
    })

    await this.setEntityDragStart(async function (id) {
      const pointId = _this.getPointIdFromEntityId(id)
      if (pointId) {
        const sceneMaker = await _this.getScene()
        _this.getPointEntity(pointId)
          .then((res) => {
            res?.drawModel().setScale(_this.gatewayGlbOptions.maxScale)
            sceneMaker.requestRender()
            sceneMaker.setEnableTranslate(false)
          })
      }
      const poiPointId = _this.getPoiPointId(id)
      if (poiPointId) {
        const sceneMaker = await _this.getScene()
        _this.getPoiPointEntity(poiPointId)
          .then((res) => {
            res?.drawModel().setScale(_this.poiPointGlbOptions.maxScale)
            sceneMaker.requestRender()
            sceneMaker.setEnableTranslate(false)
          })
      }
    })
    await this.setEntityDragEnd(async function (id) {
      const pointId = _this.getPointIdFromEntityId(id)
      if (pointId) {
        const sceneMaker = await _this.getScene()
        _this.getPointEntity(pointId)
          .then((res) => {
            res?.drawModel().setScale(_this.gatewayGlbOptions.minScale)
            sceneMaker.requestRender()
            sceneMaker.setEnableTranslate(true)
          })
      }
      const poiPointId = _this.getPoiPointId(id)
      if (poiPointId) {
        const sceneMaker = await _this.getScene()
        _this.getPoiPointEntity(poiPointId)
          .then((res) => {
            res?.drawModel().setScale(_this.poiPointGlbOptions.minScale)
            sceneMaker.requestRender()
            sceneMaker.setEnableTranslate(true)
          })
      }
    })
    await this.setEntityDragMove(function (id, p) {
      const pointId = _this.getPointIdFromEntityId(id)
      if (pointId) {
        const target = _this.pointList.value.findBy('id', pointId)
        if (target) {
          target.lat = p.lat
          target.lng = p.lng
        }
      }
      const poiPointId = _this.getPoiPointId(id)
      if (poiPointId) {
        const target = _this.pointList.value.findBy('id', poiPointId)
        if (target) {
          target.waypointPoiPoint = { ...p }
        }
      }
    })

    await this.setEntityPickLeftClick(function (bigemapLocation, e) {
      const id = e?.entity.id
      const pointId = _this.getPointIdFromEntityId(id)
      if (pointId) {
        if (_this.currentPoint.value?.id === pointId) {
          _this.currentPoint.value = undefined
        } else {
          _this.currentPoint.value = _this.pointList.value.findBy('id', pointId)
        }
      }
    })
    await this.setRightClick(function (bigemapLocation) {
      if (_this.currentPointWaypointHeadingMode.value === 'towardPOI' && _this.currentPoint.value) {
        _this.currentPoint.value.waypointPoiPoint = { ...bigemapLocation }
        mittError(new NotifyException('航点兴趣点拾取', 'success'))
      }
    })
    // 双击打点添加航点
    await this.setDoubleClick(async function (e) {
      const points: WaypointPoints = {
        lat: e.lat.toFixedCustom(9),
        lng: e.lng.toFixedCustom(9),
        id: JlinkUtils.random.uuid(8),
        waypointTurnMode: 'toPointAndStopWithDiscontinuityCurvature',
        waypointHeadingMode: 'followWayline',
        waypointHeadingPathMode: 'followBadArc',
        waypointHeadingAngle: 0,
        waypointSpeed: _this.dataRef.common.autoFlightSpeed,
        height: _this.dataRef.height,
        distance: 0,
        actions: [],
        useGlobalHeight: true,
        useGlobalSpeed: true,
        useGlobalWaypointHeadingPathMode: false,
        useGlobalWaypointHeadingMode: false,
        useGlobalWaypointTurnMode: false,
      }
      console.log('setDoubleClick', points)
      _this.currentPoint.value = points
      _this.pointList.value.push(points)
    }, false)
  }

  async flyToFirst () {
    if (this.firstPoint.value) {
      this.getPointEntity(this.firstPoint.value!.id)
        .then((res) => {
          res && this.flyTo([res.entity])
        })
    }
  }

  async flyToAllPoint () {
    const sceneMaker = await this.getScene()
    const allPoints = await this.getPointEntityAll()
    await this.flyTo(allPoints)
    sceneMaker.requestRender()
  }

  private pointPrefix = 'pointPrefix'
  private pointLinePrefix = 'pointLinePrefix'
  private pointLabelPrefix = 'pointLabelPrefix'
  private poiPointLinePrefix = 'poiPointLinePrefix'
  private poiPointPrefix = 'poiPointPrefix'

  // 获取航点实例
  async getPointEntity (pointId: string) {
    const entityMaker = await this.getEntity()
    const entityId = this.makePointId(pointId)
    return entityMaker.getById(entityId)
  }

  async getPointEntityAll () {
    const entityMaker = await this.getEntity()
    return entityMaker.getAllValues().filter(i => i.id.startsWith(this.pointPrefix))
  }

  getPointIdFromEntityId (entityId?: string) {
    return entityId?.startsWith(this.pointPrefix) && entityId.replace(this.pointPrefix, '')
  }

  private makePointId (pointId: string) {
    return this.pointPrefix + pointId
  }

  // 获取兴趣点实例
  async getPoiPointEntity (poiPointId: string) {
    const entityMaker = await this.getEntity()
    const entityId = this.makePoiPointId(poiPointId)
    return entityMaker.getById(entityId)
  }

  getPoiPointId (entityId?: string) {
    return entityId?.startsWith(this.poiPointPrefix) && entityId.replace(this.poiPointPrefix, '')
  }

  protected makePoiPointId (id: string) {
    return this.poiPointPrefix + id
  }

  async drawWaypoint () {
    const entityMaker = await this.getEntity()
    const sceneMaker = await this.getScene()
    const pointWatchSave: StringKeyRecord<WatchStopHandle> = {}
    const list = computed(() => _this.dataRef.points)
    const showList = computed(() => {
      console.log('removeById123', list.value.mapBy('id'))
      return list.value.mapBy('id')
    })
    const _this = this
    // 监听航点数组因为只有航点的坐标和id变化才会监听
    this.watchSource(() => showList.value, async function (v, o) {
      const newValue = v || []
      const oldValue = o || []
      console.log('removeById', newValue, oldValue)
      await oldValue.diffTo(newValue, function (s, t) {
        return s === t
      }, async function (l) {
        const ids = o || []
        for (const item of l) {
          if (pointWatchSave[item]) {
            pointWatchSave[item]()
            delete pointWatchSave[item]
          }
          const index = ids.indexOf(item)
          const preNode = oldValue && oldValue[index - 1]
          const currentNode = oldValue && oldValue[index]
          const nextNode = oldValue && oldValue[index + 1]
          console.log('removeById123', currentNode)
          currentNode && entityMaker.removeById(_this.makePointId(currentNode))
          currentNode && entityMaker.removeById(_this.makePointLineId(currentNode))
          currentNode && entityMaker.removeById(_this.makePoiPointLineId(currentNode))
          currentNode && entityMaker.removeById(_this.makePoiPointId(currentNode))
          if (preNode) {
            const preData = list.value.findBy('id', preNode)
            preData && await _this.addTowardMarker(preData, list.value)
            await _this.makePolyLineAndLabel(index, list.value)
          } else {
            if (nextNode) {
              entityMaker.removeById(_this.makePointLineId(nextNode))
            }
          }
        }
      }, async function (l) {
        // 这里新建
        for (const item of l) {
          const em = entityMaker.makeById(_this.makePointId(item))
          const cacheNode = ref(list.value.findBy('id', item)!!)
          const index = showList.value.indexOf(cacheNode.value.id)
          const preNode = oldValue && oldValue[index - 1]
          const preNodeData = list.value.findBy('id', preNode)
          preNodeData && await _this.addTowardMarker(preNodeData, list.value)
          em.drawModel()
            .setUri(_this.currentPoint.value?.id === cacheNode.value.id ? _this.aircraftGlb() : _this.aircraftBlueGlb())
            .setMinimumPixelSize(_this.aircraftBlueGlbOptions.minSize)
            .setImageBasedLightingFactor2(_this.aircraftBlueGlbOptions.baseLightFactor)
            .setScale(_this.aircraftBlueGlbOptions.minScale)
            .end()
            .setPosition(cacheNode.value)
            .setOrientation(cacheNode.value, _this.aircraftBlueGlbOptions.rotate, _this.aircraftBlueGlbOptions.pitch, 0)
            .addToView()
          await _this.makePolyline(index, list.value)
          const computedNode = computed<WaypointPoints>(() => JSON.parse(JSON.stringify(cacheNode.value)))

          pointWatchSave[cacheNode.value.id] = _this.watchSource(() => computedNode.value, function (value, oldValue1) {
            if ((value?.waypointHeadingMode !== oldValue1?.waypointHeadingMode) ||
              (value?.waypointHeadingAngle !== oldValue1?.waypointHeadingAngle) ||
              (value?.waypointPoiPoint !== oldValue1?.waypointPoiPoint) ||
              _this.lastRotateYawAction(value)?.aircraftHeading !== _this.lastRotateYawAction(oldValue1)?.aircraftHeading
            ) {
              // 调节航点方向
              const headingHeading = _this.getHeading(cacheNode.value, list.value)
              const index = list.value.mapBy('id').indexOf(cacheNode.value.id)
              const preNode = list.value[index - 1]
              if (preNode) {
                const headingHeading_ = _this.getHeading(preNode, list.value)
              }

              em.setOrientation(value, headingHeading + _this.aircraftGlbOptions.rotate, _this.aircraftGlbOptions.pitch, 0)
              sceneMaker.requestRender()
            }
            if (value?.lat !== oldValue1?.lat || value?.lng !== oldValue1?.lng || value?.height !== oldValue1?.height) {
              // 更新航点坐标
              value && em.setPosition(value)
              // 绘制航点连接线和label
              const index = showList.value.indexOf(value.id)
              value && _this.makePolyline(index, list.value)
              // 检查兴趣点
              value && _this.addTowardMarker(value, list.value)
              // 调节航点方向
              const headingHeading = _this.getHeading(cacheNode.value, list.value)
              const preNode = list.value[index - 1]
              if (preNode) {
                const headingHeading_ = _this.getHeading(preNode, list.value)
              }
              em.setOrientation(value, headingHeading + _this.aircraftGlbOptions.rotate, _this.aircraftGlbOptions.pitch, 0)
              sceneMaker.requestRender()
            } else if (value?.waypointPoiPoint !== oldValue1?.waypointPoiPoint) {
              value && _this.addTowardMarker(value, list.value)
            }
          }, { deep: true, immediate: true })
        }
        // 航点尾点变化
        const last = newValue.last()
        if (last && last !== oldValue.last()) {
          _this.getPointEntity(last).then((res) => {
            if (newValue.length > oldValue.length) {
              res?.drawModel().setScale(_this.aircraftGlbOptions.maxScale)
            }
          })
        }
      }, function (o) {
        return o
      })
      await nextTick()
      // 航点数据变化
      await _this.listChanged(newValue).then()
    }, { deep: true, immediate: true })
  }

  async makePolyline (index: number, list: WaypointPoints[]) {
    if (index > -1) {
      await this.makePolyLineAndLabel(index, list)
      await this.makePolyLineAndLabel(index + 1, list)
    }
  }

  private async makePolyLineAndLabel (currentIndex: number, list: WaypointPoints[]) {
    const entityMaker = await this.getEntity()
    const sceneMaker = await this.getScene()
    const currentNode = list[currentIndex]
    const preNode = list[currentIndex - 1]
    if (currentNode && preNode) {
      const target = list.findBy('id', currentNode.id)
      // 将距离存在当前航点表示距离上一个航点的距离
      const distance = await this.computeDistance(preNode, currentNode)
      const betweenPoint = await this.getBetweenCenter(preNode, currentNode)
      if (target) {
        target.distance = distance.toFixedCustom(0)
      }
      const polyline = entityMaker.getById(this.makePointLineId(currentNode.id))
      if (polyline) {
        polyline.drawPolyline()
          .setPositionsByPositions([preNode, currentNode])
          .end()
          .drawLabel()
          .setText(distance.toFixedCustom(1) + 'm')
          .end()
          .setPosition(betweenPoint)
      } else {
        entityMaker.makeById(this.makePointLineId(currentNode.id))
          .drawPolyline()
          .setWidth(4)
          .setClampToGround(true)
          .setColorMaterial(Color.WHITE)
          .setPositionsByPositions([preNode, currentNode])
          .end()
          .drawLabel()
          .setText(distance.toFixedCustom(1) + 'm')
          .setShowBackground(true)
          .setBackgroundColor(Color.BLUE)
          .setBackgroundPadding(16, 12)
          .setFont('16px sans-serif')
          .setFillColor(Color.WHITE)
          .end()
          .setPosition(betweenPoint)
          .addToView()
      }
    }
  }

  // 更新兴趣点
  private async addTowardMarker (points: WaypointPoints, list: WaypointPoints[]) {
    const entityMaker = await this.getEntity()
    const sceneMaker = await this.getScene()
    const _this = this
    const pp = points?.waypointPoiPoint
    if (pp) {
      const ppPosition: JlinkLocation = { ...pp }
      let poiMarker = entityMaker.getById(_this.makePoiPointId(points.id))
      if (!poiMarker) {
        console.log('兴趣点模型2')
        poiMarker = entityMaker.makeById(_this.makePoiPointId(points.id))
          .drawModel()
          .setUri(_this.poiPointGlb())
          .setMinimumPixelSize(_this.poiPointGlbOptions.minSize)
          .setScale(_this.poiPointGlbOptions.minScale)
          .setImageBasedLightingFactor2(_this.poiPointGlbOptions.baseLightFactor)
          .end()
          .setPosition(ppPosition)
          .setOrientation(ppPosition, _this.poiPointGlbOptions.rotate, _this.poiPointGlbOptions.pitch, 0)
          .addToView()
      } else {
        poiMarker
          .setPosition(ppPosition)
          .setOrientation(ppPosition, _this.poiPointGlbOptions.rotate, _this.poiPointGlbOptions.pitch, 0)
      }

      const linePath: JlinkLocation[] = []
      const pointPosition: JlinkLocation = { ...points }
      linePath.push(pointPosition)
      linePath.push(ppPosition)
      const index = list.mapBy('id').indexOf(points.id)
      const _Point = list[index + 1]
      if (_Point) {
        const _pointPosition: JlinkLocation = { ..._Point }
        linePath.push(_pointPosition)
      }
      const polyline = entityMaker.getById(_this.makePoiPointLineId(points.id))
      if (polyline) {
        polyline.drawPolyline().setPositionsByPositions(linePath)
      } else {
        entityMaker.makeById(_this.makePoiPointLineId(points.id))
          .drawPolyline()
          .setWidth(4)
          .setPolylineDashMaterial({ color: Color.RED })
          .setPositionsByPositions(linePath)
          .end()
          .addToView()
      }
    } else {
      entityMaker.removeById(_this.makePoiPointId(points.id))
      entityMaker.removeById(_this.makePoiPointLineId(points.id))
    }
    await JlinkTask.sleep(100)
    sceneMaker.requestRender()
  }

  private getHeading (points: WaypointPoints, list: WaypointPoints[]) {
    let headingHeading = 0
    const index = list.mapBy('id').indexOf(points.id)
    // 如果当前航点有偏航角动作则最后的显示角度为最后一个偏航角动作的角度
    if (points) {
      const rotateYaw = this.lastRotateYawAction(points)
      if (rotateYaw) {
        headingHeading = rotateYaw.aircraftHeading
      } else {
        const _Point = list[index + 1]
        // 当前航点覆盖上一个航点结束时的及角度
        switch (points.waypointHeadingMode) {
          case 'followWayline':
            headingHeading = this.getPointsDirectionAngle(points, _Point)
            break
          case 'fixed':
            headingHeading = points.waypointHeadingAngle
            break
          case 'manually':
            headingHeading = this.getPointsDirectionAngle(points, _Point)
            break
          case 'smoothTransition':
            headingHeading = points.waypointHeadingAngle
            break
          case 'towardPOI':
            const towardPOI = points.waypointPoiPoint
            try {
              if (towardPOI) {
                const position: JlinkLocation = { ...towardPOI }
                headingHeading = this.makeWaylineDirectionDegrees(points, position)
              } else {
                headingHeading = this.getPointsDirectionAngle(points, _Point)
              }
            } catch (e) {
              headingHeading = this.getPointsDirectionAngle(points, _Point)
            }
            break
        }
      }
      points.waypointHeadingAngle = Math.ceil(headingHeading)
    }
    return Math.ceil(headingHeading)
  }

  // private getHeading_ (points: WaypointPoints, list: WaypointPoints[]) {
  //   let headingHeading = 0
  //   const index = list.mapBy('id').indexOf(points.id)
  //   const Point_ = list[index - 1]
  //   const Point__ = list[index]
  //   if (Point_) {
  //     // 如果当前航点有偏航角动作则最后的显示角度为最后一个偏航角动作的角度
  //     const rotateYaw = this.lastRotateYawAction(Point_)
  //     if (rotateYaw) {
  //       headingHeading = rotateYaw.aircraftHeading
  //     } else {
  //       // 前一个航点结束时的及角度
  //       if (Point_) {
  //         switch (Point_.waypointHeadingMode) {
  //           case 'followWayline':
  //             headingHeading = this.getPointsDirectionAngle(Point__, Point_)
  //             break
  //           case 'fixed':
  //             headingHeading = Point_.waypointHeadingAngle
  //             break
  //           case 'manually':
  //             headingHeading = this.getPointsDirectionAngle(Point__, Point_)
  //             break
  //           case 'smoothTransition':
  //             headingHeading = Point_.waypointHeadingAngle
  //             break
  //           case 'towardPOI':
  //             const towardPOI = Point_.waypointPoiPoint
  //             try {
  //               if (towardPOI) {
  //                 const position: JlinkLocation = {
  //                   ...towardPOI
  //                 }
  //                 headingHeading = this.makeWaylineDirectionDegrees(points, position)
  //               } else {
  //                 headingHeading = this.getPointsDirectionAngle(Point__, Point_)
  //               }
  //             } catch (e) {
  //               headingHeading = this.getPointsDirectionAngle(Point__, Point_)
  //             }
  //             break
  //         }
  //       }
  //     }
  //     Point_.waypointHeadingAngle = Math.ceil(headingHeading)
  //   }
  //
  //   return Math.ceil(headingHeading)
  // }

  getPointsDirectionAngle (from?: JlinkLocation, target?: JlinkLocation) {
    if (from && target) {
      return this.makeWaylineDirectionDegrees(from, target)
    } else {
      return 0
    }
  }

  lastRotateYawAction (points?: WaypointPoints) {
    if (points) {
      const gimbalRotates = points.actions?.filterIf('actionType', ActionType.ROTATE_YAW) || []
      return gimbalRotates.last() as ActionRotateYaw
    } else {
      return undefined
    }
  }

  makeWaylineDirectionDegrees (p: JlinkLocation, c: JlinkLocation) {
    let realDegrees = 0
    const plat = p.lat
    const plng = p.lng
    const lat = c.lat
    const lng = c.lng
    const offsetX = plng - lng
    const offsetY = plat - lat
    const degrees = JlinkUtils.math.degreesFrom(offsetX, offsetY)
    const xt = offsetX >= 0
    const yt = offsetY >= 0
    if (!xt && !yt) {
      realDegrees = degrees
    }
    if (!xt && yt) {
      realDegrees = 180 + degrees
    }
    if (xt && !yt) {
      realDegrees = degrees
    }
    if (xt && yt) {
      realDegrees = degrees - 180
    }
    return realDegrees
  }

  protected makePoiPointLineId (id: string) {
    return this.poiPointLinePrefix + id
  }

  protected makePointLineId (id: string) {
    return this.pointLinePrefix + id
  }

  async destroy (): Promise<void> {
    await super.destroy()
    const entity = await this.getEntity()
    entity.removeAll()
  }

  canSave (): TemplateWaypointInfo|undefined {
    const dateClone = _.cloneDeep(this.dataRef)
    dateClone.points.forEach(item => {
      if (item.useGlobalHeight) {
        // @ts-ignore
        delete item.height
      }
      if (item.useGlobalSpeed) {
        // @ts-ignore
        delete item.waypointSpeed
      }
      if (item.useGlobalWaypointHeadingPathMode) {
        // @ts-ignore
        delete item.waypointHeadingPathMode
      }
      if (item.useGlobalWaypointTurnMode) {
        // @ts-ignore
        delete item.waypointTurnMode
      }
      if (item.useGlobalWaypointHeadingMode) {
        // @ts-ignore
        delete item.waypointHeadingMode
      }
      // @ts-ignore
      delete item.id
      // @ts-ignore
      delete item.useGlobalSpeed
      // @ts-ignore
      delete item.useGlobalHeight
      // @ts-ignore
      delete item.useGlobalWaypointHeadingPathMode
      // @ts-ignore
      delete item.useGlobalWaypointTurnMode
      // @ts-ignore
      delete item.useGlobalWaypointHeadingMode
    })
    if (dateClone.common.heightMode === 'relativeToStartPoint') {
      dateClone.ellipsoidHeight = dateClone.height
    } else {
      throw new DataException('航点航线仅支持相对起飞点高度模式')
    }
    if (dateClone.points.length > 0) {
      return dateClone
    } else {
      return undefined
    }
  }
}
