import { ModuleBase } from '@/common/dji/cloud/ModuleBase'
import JlinkStorage from '@/common/global/JlinkStorage'
import DjiCloudException from '@/common/errors/DjiCloudException'

declare let window: any

export class ModuleMedia extends ModuleBase {
  mediaConfig: { autoUploadPhoto: boolean, autoUploadPhotoType: 0 | 1, autoUploadVideo: boolean } = JlinkStorage.get('pilotMediaConfig') || {
    autoUploadPhoto: false,
    autoUploadPhotoType: 0,
    autoUploadVideo: false
  }

  async load () {
    if (!this.isLoaded()) {
      try {
        this.loadComponent('media', this.mediaConfig)
      } catch (e) {
        this.unload()
        throw new DjiCloudException('加载media模块失败', 100)
      }
    }
  }

  unload () {
    if (this.isLoaded()) {
      this.unloadComponent('media')
    }
  }

  isLoaded () {
    return this.isComponentLoaded('media') as boolean
  }

  // 设置自动上传照片状态
  setMediaAutoUploadPhoto (auto: boolean) {
    window.djiBridge.mediaSetAutoUploadPhoto(auto)
    this.mediaConfig.autoUploadPhoto = auto
    JlinkStorage.set('pilotMediaConfig', this.mediaConfig)
  }

  // 设置自动上传视频状态：
  setMediaAutoUploadVideo (auto: boolean) {
    window.djiBridge.mediaSetAutoUploadVideo(auto)
    this.mediaConfig.autoUploadVideo = auto
    JlinkStorage.set('pilotMediaConfig', this.mediaConfig)
  }

  // 设置自动上传照片类型：
  setMediaUploadPhotoType (type: 0 | 1) {
    window.djiBridge.mediaSetUploadPhotoType(type)
    this.mediaConfig.autoUploadPhotoType = type
    JlinkStorage.set('pilotMediaConfig', this.mediaConfig)
  }

  // 获取自动上传照片状态：
  getMediaAutoUploadPhoto () {
    return this.bridgeSetBlock<boolean>(() => window.djiBridge.mediaGetAutoUploadPhoto())
  }

  // 获取自动上传照片类型：
  getMediaUploadPhotoType () {
    return this.bridgeSetBlock<0 | 1>(() => window.djiBridge.mediaGetUploadPhotoType())
  }

  // 获取自动上传视频状态：
  getMediaAutoUploadVideo () {
    return this.bridgeSetBlock<boolean>(() => window.djiBridge.mediaGetAutoUploadVideo())
  }

  // 设置后台自动下载的遥控器：
  setMediaDownloadOwner (rc: 0 | 1) {
    window.djiBridge.mediaSetDownloadOwner(rc)
  }

  // 获取后台自动下载的遥控器编号：
  getMediaDownloadOwner () {
    return this.bridgeSetBlock<0 | 1>(() => window.djiBridge.mediaGetDownloadOwner())
  }
}
