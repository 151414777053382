export default class CameraUtil {
  // 全画幅相机元件参数(mm)
  private static fullFrame = {
    width: 36,
    height: 24,
    diagonal: 43.4,
    cmos: 2.7
  }

  static getPerInchFromCmos (cmos:number) {
    if (cmos >= 1 / 2) {
      return 16
    } else {
      return 18
    }
  }

  // 根据coms计算感光元件的对角线长度
  static calculateSensorDiagonal (cmos:number) {
    const inch = this.getPerInchFromCmos(cmos)
    return cmos * inch
  }

  // 计算焦距系数 「43.3mm」÷（16/18mm×「CMOS尺寸」）
  static calculateFocalLengthCoefficient (cmos:number) {
    return this.fullFrame.diagonal / this.calculateSensorDiagonal(cmos)
  }

  // 计算等效焦距
  static calculateEquivalentFocalLength (focalLength:number, cmos:number) {
    return focalLength * this.calculateFocalLengthCoefficient(cmos)
  }

  // 根据等效焦距计算焦距
  static calculateFocalLengthFromEquivalentFocalLength (equivalentFocalLength:number, cmos:number) {
    return equivalentFocalLength / this.calculateFocalLengthCoefficient(cmos)
  }

  // 计算感光器件尺寸
  static calculateSensorSize (cmos:number, pixel:number[]) {
    const sensorDiagonal = this.calculateSensorDiagonal(cmos)
    const sqrt = Math.sqrt(Math.pow(pixel[0], 2) + Math.pow(pixel[1], 2))
    // 传感器宽(毫米)
    const sensorW = sensorDiagonal / sqrt * pixel[0]
    // 传感器高(毫米)
    const sensorH = sensorDiagonal / sqrt * pixel[1]
    return { sensorW, sensorH, cellD: sensorDiagonal }
  }
}
