import { ModuleBase } from '@/common/dji/cloud/ModuleBase'
import DjiCloudException from '@/common/errors/DjiCloudException'

declare let window: any
interface LiveShareType {
  // 未知直播
  0: {}

  // agora 声网直播
  1: {
    uid: string,
    token: string,
    channelId: string
  }
  // RTMP
  2: {
    url: string
  }
  // RTSP
  3: {
    userName: string,
    password: string,
    port: string
  }
  // GB28181
  4: {
    serverIp: string, // 服务器IP
    serverPort: string, // 服务器端口
    serverId: string, // 服务器ID
    agentId: string, // 客户帐号
    password: string, // 密码
    agentPort: string, // 本地端口
    agentChannel: string, // 通道号
  }
}
export class ModuleLiveShare extends ModuleBase {
  async load () {
    if (!this.isLoaded()) {
      try {
        this.loadComponent('liveshare', {
          videoPublishType: 'video-on-demand',
          statusCallback: 'statusCallback'
        })
        this.initCallback()
      } catch (e) {
        this.unload()
        throw new DjiCloudException('加载liveShare模块失败', 100)
      }
    }
  }

  unload () {
    if (this.isLoaded()) {
      this.unloadComponent('liveshare')
    }
  }

  isLoaded () {
    return this.isComponentLoaded('liveshare') as boolean
  }

  // 设置直播模式
  setVideoPublishType (type: 'video-demand-aux-manual' | 'video-by-manual' | 'video-on-demand') {
    window.djiBridge.liveshareSetVideoPublishType(type)
  }

  // 获取直播参数：
  getConfig () {
    return this.bridgeSetBlock<{ type: keyof LiveShareType, params: any }>(() => window.djiBridge.liveshareGetConfig())
  }

  // 手动发起直播：
  setConfig<T extends keyof LiveShareType> (type: T, params: LiveShareType[T]) {
    window.djiBridge.liveshareSetConfig(type, JSON.stringify(params))
  }

  // 设置回调函数：
  setStatusCallback (callback: string) {
    window.djiBridge.liveshareSetStatusCallback(callback)
  }

  // 获取直播状态
  getStatus () {
    return this.bridgeSetBlock(() => window.djiBridge.liveshareGetStatus())
  }

  start () {
    window.djiBridge.liveshareStartLive()
  }

  stop () {
    window.djiBridge.liveshareStopLive()
  }

  private initCallback () {
    window.statusCallback = (res: any) => {
      console.log('ModuleLiveShare', res)
    }
  }
}
