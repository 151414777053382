import JSZip, { OutputType } from 'jszip'
import FileSaver from 'file-saver'
import axios from 'axios'
import JlinkUtils from '@/common/global/JlinkUtils'

export default class ZipUtil {
  private async getFileData (fileUrl: string): Promise<Blob> {
    const res = await axios(fileUrl, {
      method: 'GET',
      responseType: 'blob'
    })
    return res.data
  }

  async makeZip (files: { name: string, url: string }[], zipName: string) {
    const _this = this
    const zip = new JSZip()
    const notes = []
    for (const file of files) {
      try {
        zip.file(file.name, await _this.getFileData(file.url)
          , { binary: true })
        notes.push(`${file.name}:download success`)
      } catch (e) {
        notes.push(`${file.name}:download fail`)
      }
    }

    const names = files.map(i => i.name)
    if (names.includes('readme.txt')) {
      zip.file(Date.now() + JlinkUtils.random.uuid(8) + 'readme.txt', notes.join(','))
    } else {
      zip.file('readme.txt', notes.join(','))
    }
    const zipTask = await zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: { level: 9 }
    })
    FileSaver.saveAs(zipTask, zipName.endsWith('.zip') ? zipName : zipName + '.zip')
  }

  async unMakeZip<T extends OutputType> (url: string, covert:StringKeyRecord<T>):Promise<StringKeyRecord<any>> {
    const zip = new JSZip()
    const data = await this.getFileData(url)
    const zipObj = await zip.loadAsync(data)
    const result = Object.create({})
    for (const key in zipObj.files) {
      const target = covert[key]
      result[key] = await zip.file(key)?.async(target || 'blob')
    }
    return result
  }
}
