export default function JlinkBindThis<T extends Function | Object> (target: T, key: string | symbol, descriptor: TypedPropertyDescriptor<(...arg:any) => any>): TypedPropertyDescriptor<(...arg:any) => any> {
  if (typeof target === 'function') {
    target.prototype.constructor[key] = target.prototype.constructor[key].bind(target)
    return target.prototype.constructor[key]
  } else {
    const fn = descriptor.value
    return {
      configurable: descriptor.configurable,
      enumerable: descriptor.enumerable,
      get: function () {
        const boundFn = (fn || (descriptor.get && descriptor.get.apply(this)))!.bind(this)
        // 将绑定后的方法重新定义为当前属性的值
        Object.defineProperty(this, key, {
          value: boundFn,
          configurable: true,
          writable: true,
        })
        return boundFn
      },
    }
  }
}
