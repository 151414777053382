import * as turf from '@turf/turf'
import { Position } from '@turf/turf'
import JlinkUtils from '@/common/global/JlinkUtils'
import JlinkTruf from '@/common/global/JlinkTruf'
import DataException from '@/common/errors/DataException'
import CesiumInstance from '@/components/cesium/CesiumInstance'
import { Rectangle, Math as Math2 } from 'cesium'
import CameraUtil from '@/common/global/util/CameraUtil'

export default class CameraHelper {
  private readonly pixelCell: number
  private readonly focalLength: number
  private readonly pixel: number[]
  private readonly fp

  constructor (camera: { pixel: number[], cmos: number, focalLength: number[], sensorAngle?: number }) {
    if (camera.focalLength[0] !== camera.focalLength[1]) {
      throw new DataException('广角相机焦距错误')
    }
    const sensorSize = CameraUtil.calculateSensorSize(camera.cmos, camera.pixel)
    // 像元大小(微米)
    const cellSize1 = sensorSize.sensorW / camera.pixel[0]
    // 像元大小(微米)
    const cellSize2 = sensorSize.sensorH / camera.pixel[1]
    this.focalLength = camera.focalLength[0]
    this.pixelCell = cellSize1
    this.pixel = camera.pixel
    this.fp = this.focalLength / this.pixelCell
  }

  height2Gsd (height: number) {
    return height * 100 / this.fp
  }

  gsd2Height (gsd: number) {
    return gsd * this.fp / 100
  }

  private width (gsd: number) {
    return [this.pixel[0] * gsd / 100, this.pixel[1] * gsd / 100]
  }

  calculate (startPoint: JlinkLocation | undefined, wayline: TemplateMapping2DInfo, photoWide: boolean) {
    const _this = this
    // 至少3点组成一个多边形，小于3点就不需要计算
    if (wayline.polygon.length < 3) {
      wayline.points = []
      wayline.common.estimatedTime = 0
      wayline.common.photoNum = 0
      wayline.common.distance = 0
      return
    }
    if (wayline.polygonIntersecting) {
      wayline.points = []
      wayline.common.estimatedTime = 0
      wayline.common.photoNum = 0
      wayline.common.distance = 0
      return
    }
    const polygon = JlinkTruf.expandedPolygon(wayline.polygon, wayline.margin)
    let polygonPerimeter = 0

    polygon.forEach((value, index, array) => {
      const pre = array[index - 1]
      if (pre) {
        polygonPerimeter += this.computedDistance(pre, value)
      }
    })

    const polygonCenter = JlinkTruf.center(polygon)
    // 获取画幅大小
    const wide = this.width(wayline.common.wideGSD)
    // 航段间距
    let hSpace = photoWide ? Math.max(wide[0], wide[1]) : Math.min(wide[0], wide[1])
    let vSpace = photoWide ? Math.min(wide[0], wide[1]) : Math.max(wide[0], wide[1])
    // 单向旁向重叠空间(这里只计算了建图航拍的重叠方式，还有激光和倾斜需要完善)
    const trackSpaceSide = hSpace * (wayline.orthoCameraOverlapW / 100)
    const photoSpaceSide = vSpace * (wayline.orthoCameraOverlapH / 100)
    hSpace -= trackSpaceSide
    vSpace -= photoSpaceSide
    console.log(1234, vSpace)
    // 经纬度笛卡尔坐标
    const polyArr = polygon.map(item => CesiumInstance.toCartesian3FromDegrees(item))
    // 获取包含上面所有笛卡尔坐标的矩形
    const rectangle = CesiumInstance.toRectangleFromCartesianArray(polyArr)
    // 以rectangle的对角线为长宽，rectangle的中心点为中心点定义一个新的矩形rectangleRotate方便调整主航线方向
    const rectangleAngleLineWidth = Math.sqrt(Math.pow(rectangle.width, 2) + Math.pow(rectangle.height, 2)) * 1.1 // 这里乘以1.1是为了保险做的偏移
    const rectangleCenter = Rectangle.center(rectangle)
    const rectangleCenterPosition = {
      lng: Math2.toDegrees(rectangleCenter.longitude),
      lat: Math2.toDegrees(rectangleCenter.latitude),
      height: 0
    }
    const rectangleRotate = Rectangle.fromRadians(
      rectangleCenter.longitude - rectangleAngleLineWidth / 2,
      rectangleCenter.latitude - rectangleAngleLineWidth / 2,
      rectangleCenter.longitude + rectangleAngleLineWidth / 2,
      rectangleCenter.latitude + rectangleAngleLineWidth / 2
    )
    // 以rectangleRotate为基面，trackSpace为间距画平行线
    const ne = Rectangle.northeast(rectangleRotate)
    const sw = Rectangle.southwest(rectangleRotate)
    const rightTopLng = ne.longitude
    let rightTopLat = ne.latitude
    const leftBottomLng = sw.longitude
    const leftBottomLat = sw.latitude
    const trackSpaceDegrees = turf.lengthToRadians(hSpace, 'meters')
    const lines: {
      index: number,
      id: string,
      s: Position,
      e: Position,
      midpoint: Position,
      intersects: boolean,
      distance: number,
      photoNum: number,
    }[] = []
    const intersectsTestCenterOptions = { pivot: [rectangleCenterPosition.lng, rectangleCenterPosition.lat, rectangleCenterPosition.height] }
    const intersectsTestPolygonGeo = polygon.map(item => [item.lng, item.lat, item.height])
    const intersectsTestPolygon = turf.polygon([intersectsTestPolygonGeo])
    while (rightTopLat > leftBottomLat) {
      rightTopLat -= trackSpaceDegrees
      let startPoint: JlinkLocation
      let endPoint: JlinkLocation
      if (rightTopLat < leftBottomLat && lines.length === 0) {
        startPoint = {
          lat: rectangleCenter.latitude,
          lng: Math2.toDegrees(rightTopLng),
          height: 0
        }
        endPoint = {
          lat: rectangleCenter.latitude,
          lng: Math2.toDegrees(leftBottomLng),
          height: 0
        }
      } else {
        startPoint = {
          lat: Math2.toDegrees(rightTopLat),
          lng: Math2.toDegrees(rightTopLng),
          height: 0
        }
        endPoint = {
          lat: Math2.toDegrees(rightTopLat),
          lng: Math2.toDegrees(leftBottomLng),
          height: 0
        }
      }

      // 根据主航线角度direction调整所有平行线的角度
      const line = turf.lineString([[startPoint.lng, startPoint.lat, startPoint.height], [endPoint.lng, endPoint.lat, endPoint.height]])
      const rotatedPoly = turf.transformRotate(line, wayline.direction - 90, intersectsTestCenterOptions)
      const coordinates = rotatedPoly.geometry.coordinates
      // 判断线段和测区多边形是否相交
      const intersectsTestLine = turf.lineString(coordinates)
      const intersects = turf.lineIntersect(intersectsTestLine, intersectsTestPolygon)
      if (intersects.features.length > 1) {
        // 如果相交则说名当前的线段切过测区多边形，如果是航线则执行在测取多边形
        let start: Position | undefined
        intersects.features.sort(function (a, b) {
          if (a.geometry.coordinates[0] === b.geometry.coordinates[0]) {
            return a.geometry.coordinates[1] - b.geometry.coordinates[1]
          }
          return a.geometry.coordinates[0] - b.geometry.coordinates[0]
        }).forEach(item => {
          const intersectsCoordinates = item.geometry.coordinates
          if (start) {
            // 过滤掉与测区多边形相交但是整个航段不在测取多边形的线段
            // 这里只需要判断航段的中点在不在测区多边形
            const midpoint = turf.midpoint(turf.point(start), turf.point(intersectsCoordinates))
            const intersectsTestPoint = turf.point(midpoint.geometry.coordinates)
            const intersects = turf.booleanPointInPolygon(intersectsTestPoint, intersectsTestPolygon)
            const distance = _this.computedDistanceByPosition(start, intersectsCoordinates)
            lines.push({
              index: lines.length,
              id: JlinkUtils.random.uuid(8),
              midpoint: midpoint.geometry.coordinates,
              intersects: intersects.valueOf(),
              s: start,
              e: intersectsCoordinates,
              // 航段长度
              distance,
              // 照片数量
              photoNum: Math.round(distance / vSpace),
              // 航段等时拍摄
            })
          }
          start = intersectsCoordinates
        })
      }
    }

    const points = this.sortPoint(lines)
    if (startPoint) {
      points.unshift({
        id: JlinkUtils.random.uuid(8),
        position: [startPoint.lng, startPoint.lat, startPoint.height],
        type: 'end'
      })
    }
    const savePoints: WayMapping2dPoints[] = points.map((value, index, array) => {
      const pre = array[index - 1]
      let distance = 0
      if (pre) {
        distance = _this.computedDistanceByPosition(pre.position, value.position)
      }
      const pointType = index === 0 ? 'start' : 'midpoint'

      return {
        id: JlinkUtils.random.uuid(8),
        lng: value.position[0],
        lat: value.position[1],
        height: value.position[2] || 0,
        type: pointType,
        distance,
        waypointHeadingAngle: 0
      }
    })
    let elevationOptimizePhotoNum:number
    // 如果开启高程优化则给他添加中心点
    if (wayline.elevationOptimizeEnable) {
      const pre = savePoints.last()
      let distance = 0
      if (pre) {
        distance += _this.computedDistance(pre, rectangleCenterPosition)
      }
      if (polygonCenter) {
        savePoints.push({
          id: JlinkUtils.random.uuid(8),
          lng: polygonCenter.geometry.coordinates[0],
          lat: polygonCenter.geometry.coordinates[1],
          height: polygonCenter.geometry.coordinates[2],
          type: 'elevationOptimize',
          distance,
          waypointHeadingAngle: 0,
        })
      }
      elevationOptimizePhotoNum = Math.round(polygonPerimeter / hSpace)
    } else {
      elevationOptimizePhotoNum = 0
    }

    wayline.common.photoNum = lines.mapBy('photoNum').total() + elevationOptimizePhotoNum
    wayline.common.distance = savePoints.mapBy('distance').total()
    wayline.common.estimatedTime = (wayline.common.distance / wayline.common.autoFlightSpeed).toFixedCustom(1)
    if (wayline.shootType === 'time') {
      wayline.actionTriggerParam = vSpace / wayline.common.autoFlightSpeed
    } else {
      wayline.actionTriggerParam = vSpace
    }

    savePoints.forEach((item, index) => {
      item.height = wayline.height
      item.waypointHeadingAngle = savePoints[index + 1] ? this.makeWaylineDirectionDegrees(item, savePoints[index + 1]) : 0
    })
    wayline.points = savePoints
  }

  private sortPoint (lines: {
    index: number,
    id: string,
    s: Position,
    e: Position,
    midpoint: Position,
    intersects: boolean,
  }[]) {
    if (lines.length > 0) {
      const realLines = lines.filter(i => i.intersects)
      const firstLine = realLines[0]
      const savePoints: { id: string, position: Position, type: 'begin' | 'end' }[] = []
      let lastPoint: Position
      savePoints.push({ id: firstLine.id, position: firstLine.s, type: 'begin' })
      savePoints.push({ id: firstLine.id, position: firstLine.e, type: 'end' })
      lastPoint = firstLine.e
      realLines.remove(firstLine)
      while (realLines.length > 0) {
        const pointList: {
          position: Position,
          id: string,
          type: 's' | 'e',
        }[] = []
        realLines.forEach(i => {
          pointList.push({
            position: i.s,
            id: i.id,
            type: 's',
          })
          pointList.push({
            position: i.e,
            id: i.id,
            type: 'e',
          })
        })
        const totals = pointList.map(i => {
          const distance = this.computedDistanceByPosition(lastPoint, i.position)
          return { distance, id: i.id, type: i.type }
        }).sortBy('distance', 'asc')
        const nextPoint = totals.first()!
        const nextRightPoint = realLines.findBy('id', nextPoint.id)!
        if (nextPoint.type === 's') {
          savePoints.push({ id: nextRightPoint.id, position: nextRightPoint.s, type: 'begin' })
          savePoints.push({ id: nextRightPoint.id, position: nextRightPoint.e, type: 'end' })
          lastPoint = nextRightPoint.e
        } else {
          savePoints.push({ id: nextRightPoint.id, position: nextRightPoint.e, type: 'begin' })
          savePoints.push({ id: nextRightPoint.id, position: nextRightPoint.s, type: 'end' })
          lastPoint = nextRightPoint.s
        }
        realLines.remove(nextRightPoint)
      }
      return savePoints
    } else {
      return []
    }
  }

  private makeWaylineDirectionDegrees (p: JlinkLocation, c: JlinkLocation) {
    let realDegrees = 0
    const plat = p.lat
    const plng = p.lng
    const lat = c.lat
    const lng = c.lng
    const offsetX = plng - lng
    const offsetY = plat - lat
    const degrees = JlinkUtils.math.degreesFrom(offsetX, offsetY)
    const xt = offsetX >= 0
    const yt = offsetY >= 0
    if (!xt && !yt) {
      realDegrees = degrees
    }
    if (!xt && yt) {
      realDegrees = 180 + degrees
    }
    if (xt && !yt) {
      realDegrees = degrees
    }
    if (xt && yt) {
      realDegrees = degrees - 180
    }
    return realDegrees
  }

  computedDistance (f: JlinkLocation, t: JlinkLocation) {
    const from = turf.point([f.lng, f.lat, f.height])
    const to = turf.point([t.lng, t.lat, t.height])
    return turf.rhumbDistance(from, to, { units: 'meters' })
  }

  computedDistanceByPosition (f: Position, t: Position) {
    const from = turf.point(f)
    const to = turf.point(t)
    return turf.rhumbDistance(from, to, { units: 'meters' })
  }
}
