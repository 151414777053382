export default class ObjUtil {


  deleteWhenValueEmpty (obj:StringKeyRecord<any>) {
    if (obj) {
      Object.keys(obj).forEach(k => {
        if (!obj[k]) {
          delete obj[k]
        }
      })
    }
  }

  _typeof (obj: any): 'object' | boolean | number | string | 'function' | null | undefined | 'array' {
    if (obj === null) {
      return null
    }
    if (obj.constructor === Array) {
      return 'array'
    }
    if (typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol') {
      return typeof obj
    } else {
      return obj && typeof Symbol === 'function' && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj
    }
  }

  dataSet (source: any, target: any, nullSet: boolean = false, emptySet: boolean = true) {
    if (!source) {
      return
    }
    if (!target) {
      return
    }
    Object.keys(source).forEach(k => {
      const sv = source[k]
      const tv = target[k]

      if (sv === null || sv === undefined) {
        if (nullSet) {
          if (sv !== tv) {
            if (sv && (tv !== null && tv !== undefined)) {
              if (this._typeof(sv) === this._typeof(tv)) {
                if (emptySet || sv !== '') {
                  target[k] = sv
                }
              } else {
                console.error(sv + 'and' + target + 'are not the same type')
              }
            } else {
              if (emptySet || sv !== '') {
                target[k] = sv
              }
            }
          }
        }
      } else {
        if (sv !== tv) {
          if (sv && (tv !== null && tv !== undefined)) {
            if (this._typeof(sv) === this._typeof(tv)) {
              if (emptySet || sv !== '') {
                target[k] = sv
              }
            } else {
              console.error(sv + 'and' + target + 'are not the same type')
            }
          } else {
            if (emptySet || sv !== '') {
              target[k] = sv
            }
          }
        }
      }
    })
  }
}
