import {
  NavigationFailure,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationRaw,
  Router,
  RouteRecordRaw, useRoute
} from 'vue-router'
import router from '@/router'
import webRoutes from '@/router/routes.web'
import baseRoutes from '@/router/routes.base'
import { toRaw, watch } from 'vue'
import { AppConfigModule } from '@/store/AppConfig'
import routesAsync from '@/router/routes.async'
import devRoutes from '@/router/routes.dev'
import JlinkTask from '@/common/global/JlinkTask'
import { UserModule } from '@/store/User'
import {
  COMMON404,
  COMMON_CLOUD_LOGIN,
  COMMON_LIVESTREAM_SHARE,
  COMMON_LOCATION_SHARE,
  COMMON_WEB_LOGIN
} from '@/router/route.model.base'
import {
  WEB_DEFAULT, WEB_EMERGENCY,
  WEB_FLIGHT_CONTROL,
  WEB_WAYLINE_EDITOR
} from '@/router/route.model.web'
import { FlightControlModule } from '@/store/FlightControl'
import JlinkMqtt from '@/common/global/JlinkMqtt'
import WaylineLoader from '@/app/views/pages/library/wayline/editor/WaylineLoader'
/*
* JLink路由
* */
const hasPermission = (authorities: string[], route: RouteRecordRaw) => {
  if (route.meta?.permissions) {
    return route.meta.permissions.some(p => authorities.includes(p))
  } else {
    return true
  }
}

export const filterAsyncRoutes = (routes: RouteRecordRaw[], authorities: string[]) => {
  const res: RouteRecordRaw[] = []
  routes.forEach(route => {
    const r = { ...route }
    if (hasPermission(authorities, r)) {
      if (r.children) {
        r.children = filterAsyncRoutes(r.children, authorities)
      }
      res.push(r)
    }
  })
  console.log(res)
  return res
}

export default class JlinkRouter {
  static whiteList = [COMMON404.path, COMMON_LIVESTREAM_SHARE.fullPath,COMMON_LOCATION_SHARE.fullPath]
  static async goPageDefault (): Promise<NavigationFailure | void> {
    return await this.push(WEB_DEFAULT.fullPath)
  }

  static async goPageEmergency (): Promise<NavigationFailure | void> {
    return await this.push(WEB_EMERGENCY.fullPath)
  }

  static async goPageFlightControl (): Promise<NavigationFailure | void> {
    return await this.push(WEB_FLIGHT_CONTROL.fullPath)
  }

  static async goPageWaylineEditor (): Promise<NavigationFailure | void> {
    return await this.push({ path: WEB_WAYLINE_EDITOR.fullPath })
  }

  static async push (to: RouteLocationRaw): Promise<NavigationFailure | void> {
    return await router.push(to)
  }

  static async replace (to: RouteLocationRaw): Promise<NavigationFailure | void> {
    return await router.replace(to)
  }

  static go (delta: number) {
    router.go(delta)
  }

  static async back (): Promise<ReturnType<Router['go']>> {
    return router.back()
  }

  static setDocumentTitle (title: string) {
    document.title = title
  }

  static asyncLoaded = false

  static addRoutes (routes: RouteRecordRaw[]) {
    this.asyncLoaded = true
    const r = webRoutes.concat(baseRoutes).concat(routes)
    r.forEach(route => {
      if (route.name) {
        if (!router.hasRoute(route.name)) {
          router.addRoute(toRaw(route))
        }
      } else {
        console.warn('route name is lost can not add to router')
      }
    })
    router.options.routes = r
  }

  static generateRoutes (authorities: string[] | undefined) {
    try {
      if (authorities) {
        let accessedRoutes
        if (AppConfigModule.isDevDevelopment) {
          accessedRoutes = routesAsync.concat(devRoutes)
        } else {
          accessedRoutes = filterAsyncRoutes(routesAsync, authorities)
        }
        this.addRoutes(accessedRoutes)
      } else {
        this.addRoutes([])
      }
    } catch (e) {
      console.log(e)
    }
  }

  static redirectToPath (from: string, to: string) {
    return `${from}?redirect=${to}`
  }

  static beforeEach (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    console.log('beforeEach')
    JlinkTask.catchAwait(async function () {
      console.log('route hooks from:', from.path)
      console.log('route hooks to:', to.path)
      console.log('route cache:', router.options.routes)
      /* 设置标题 */
      JlinkRouter.setDocumentTitle((to.query.title as string) || to.meta.title || '疆链无人机云系统')
      /* 动态加载路由 */
      const user = UserModule.user
      if (to.matched.length === 0) {
        JlinkRouter.generateRoutes(user?.userAuthorities)
        next({ ...to, replace: true })
        return
      }
      /* 如果是白名单中页面 直接通过 */
      if (JlinkRouter.whiteList.indexOf(to.path) >= 0) {
        next()
        return
      }
      /* 判断是否是大疆pilot登录 */
      if (window.djiBridge) {
        if (to.path === COMMON_CLOUD_LOGIN.path) {
          next()
        } else {
          next(JlinkRouter.redirectToPath(COMMON_CLOUD_LOGIN.path, to.path))
        }
        return
      }
      /* 判断是否有免登陆的noPasswordRequiredKey */
      if (to.query.noPasswordRequiredKey) {
        await UserModule.loginByNPRKey(to.query.noPasswordRequiredKey as string)
      }
      /* 其他页面必须验证userToken 如果没有跳转到登录页面 */
      if (user?.userToken) {
        JlinkMqtt.autoRegister(user.userId, user.companyId).then()

        switch (to.path) {
          case WEB_FLIGHT_CONTROL.path:
            if (!FlightControlModule.data.currentDrone) {
              console.log('drone is lost')
              next({ path: WEB_DEFAULT.path, replace: true })
            } else {
              next()
            }
            break
          case WEB_WAYLINE_EDITOR.path:
            if (!WaylineLoader.waylineData) {
              next({ path: WEB_DEFAULT.path, replace: true })
            } else {
              next()
            }
            break
          case COMMON_WEB_LOGIN.path:
            next({ path: WEB_DEFAULT.path, replace: true })
            break
          default :
            next()
            break
        }
      } else {
        if (to.path === COMMON_WEB_LOGIN.path) {
          next()
        } else {
          next(JlinkRouter.redirectToPath(COMMON_WEB_LOGIN.path, to.path))
        }
      }
    }, function (e) {
      next({ path: COMMON404.fullPath, query: { error: e.message } })
      return true
    })
  }

  static getLiveStreamSharePath (secretKey:string) {
    return `${window.location.origin}${COMMON_LIVESTREAM_SHARE.fullPath}?secretKey=${secretKey}`
  }

  static getLocationSharePath (location:string) {
    return `${window.location.origin}${COMMON_LOCATION_SHARE.fullPath}?location=${location}`
  }
}
