import BigemapHelper from '@/common/helper/map/BigemapHelper'
import { Ref } from 'vue'
import JlinkUtils from '@/common/global/JlinkUtils'
import { Color, Entity, HeadingPitchRange, HeightReference } from 'cesium'
import CesiumService from '@/components/cesium/CesiumService'

export default class ShootMapBigemapHelper extends BigemapHelper {
  private p: Ref<{
  absolute_altitude?: number
  created_time?: string
  gimbal_yaw_degree?: number
  photoed_position?: Omit<JlinkLocation, 'height'>
  shoot_position?: Omit<JlinkLocation, 'height'>,
  relative_altitude?: number
} | undefined>

  constructor (map: Ref<{ mapService: () => Promise<CesiumService> | CesiumService } | undefined>, p: Ref<{
    absolute_altitude?: number
    created_time?: string
    gimbal_yaw_degree?: number
    photoed_position?: Omit<JlinkLocation, 'height'>
    shoot_position?: Omit<JlinkLocation, 'height'>,
    relative_altitude?: number
  } | undefined>) {
    super(map)
    this.p = p
  }

  mapServiceLoaded (service: CesiumService) {
    const _this = this
    this.watchSource(() => this.p.value, async function (value) {
      let position:JlinkLocation|undefined
      if (value?.shoot_position && value.shoot_position.lat && value.shoot_position.lng) {
        position = { ...value.shoot_position, height: value.absolute_altitude || 0 }
      } else if (value?.photoed_position && value.photoed_position.lng && value.photoed_position.lat) {
        position = { ...value.photoed_position, height: value.absolute_altitude || 0 }
      } else {
        position = undefined
      }

      if (position) {
        service.entity
          .makeById(_this.id)
          .drawBillboard()
          .setImagePin('P', Color.RED, 36)
          .setHeightReference(HeightReference.CLAMP_TO_GROUND)
          .end()
          .setPosition(position)
          .coverToView()
        service.scene.requestRender()
        if (value?.gimbal_yaw_degree !== undefined) {
          await _this.flyToEntities({ offset: new HeadingPitchRange(value.gimbal_yaw_degree + 180, -90, 0) })
        } else {
          await _this.flyToEntities()
        }
      } else {
        service.entity.removeById(_this.id)
      }
      service.scene.requestRender()
    }, { immediate: true, deep: true })
  }
}
