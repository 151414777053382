export default function JlinkSingleGet<R> (defaultValue?: R) {
  return function <T extends Function | Object> (target: T, key: string | symbol, descriptor: TypedPropertyDescriptor<(...arg:any) => Promise<R>>) {
    const module = target
    // @ts-ignore
    const loadingKey = `__singleGetLoading_${target.constructor.name}_${key}`
    // @ts-ignore
    const saveKey = `__singleGetSave_${target.constructor.name}_${key}`
    // 静态类中使用
    if (typeof module === 'function') {
      if (!module.prototype[loadingKey]) {
        module.prototype[loadingKey] = false
      }
      if (!module.prototype[saveKey]) {
        module.prototype[saveKey] = []
      }
      const singleGet = function (fun: (...args: any) => Promise<R>, get: (res: R) => void, args:IArguments) {
        if (module.prototype[loadingKey]) {
          module.prototype[saveKey].push(get)
        } else {
          module.prototype[loadingKey] = true
          // @ts-ignore
          fun.call(module, ...args)
            .then(res => {
              get(res)
              for (const i of module.prototype[saveKey]) {
                i(res)
              }
            })
            .catch(e => {
              throw e
            })
            .finally(() => {
              module.prototype[loadingKey] = false
              module.prototype[saveKey] = []
            })
        }
      }
      const value = module.prototype.constructor[key]
      module.prototype.constructor[key] = function () {
        const args = arguments

        return new Promise<R>((resolve, reject) => {
          try {
            singleGet(value, function (res) {
              resolve(res)
            }, args)
          } catch (e: any) {
            console.error(e.message)
            if (defaultValue) {
              resolve(defaultValue)
            } else {
              reject(e)
            }
          }
        })
      }
      return module.prototype.constructor[key]
    } else {
      // @ts-ignore
      if (!module[loadingKey]) {
        Object.defineProperty(module, loadingKey, {
          value: false,
          writable: true
        })
      }
      console.log(module)
      // @ts-ignore
      if (!module[saveKey]) {
        Object.defineProperty(module, saveKey, {
          value: [],
          writable: true

        })
      }

      const singleGet = function (fun: (...args: any) => Promise<R>, get: (res: R) => void, args:IArguments) {
        // @ts-ignore
        if (module[loadingKey]) {
          // @ts-ignore
          module[saveKey].push(get)
        } else {
          // @ts-ignore
          module[loadingKey] = true
          // @ts-ignore
          fun(...args)
            .then(res => {
              get(res)
              // @ts-ignore
              for (const i of module[saveKey]) {
                i(res)
              }
            })
            .catch(e => {
              throw e
            })
            .finally(() => {
              // @ts-ignore
              module[loadingKey] = false
              // @ts-ignore
              module[saveKey] = []
            })
        }
      }

      const fn = descriptor.value
      return {
        configurable: descriptor.configurable,
        enumerable: descriptor.enumerable,
        get: function () {
          console.log('1234567')
          const _this = this
          const boundFn = function () {
            const args = arguments
            return new Promise<R>((resolve, reject) => {
              try {
                singleGet((fn || (descriptor.get && descriptor.get.apply(_this))!.bind(_this)), function (res) {
                  resolve(res)
                }, args)
              } catch (e: any) {
                console.error(e.message)
                if (defaultValue) {
                  resolve(defaultValue)
                } else {
                  reject(e)
                }
              }
            })
          }
          // 将绑定后的方法重新定义为当前属性的值
          Object.defineProperty(this, key, {
            value: boundFn,
            configurable: true,
            writable: true,
          })
          return boundFn
        },
      }
    }
  }
}
