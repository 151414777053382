import AlsException from '@/common/errors/AlsException'
import { ExceptionEnum } from '@/common/errors/ExceptionEnum'

/* 提醒 */
export default class NotifyException extends AlsException {
  readonly errType:'warning'|'error'|'info'|'success'
  constructor (message: string, type: 'warning'|'error'|'info'|'success') {
    super(message, 9999, ExceptionEnum.NOTIFY)
    this.errType = type
  }
}
