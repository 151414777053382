import { ModuleBase } from '@/common/dji/cloud/ModuleBase'
import { ModuleThing } from '@/common/dji/cloud/ModuleThing'
import { ModuleLiveShare } from '@/common/dji/cloud/ModuleLiveShare'
import { ModuleApi } from '@/common/dji/cloud/ModuleApi'
import { ModuleMedia } from '@/common/dji/cloud/ModuleMedia'
import { getDjiCloudPlatformInfo } from '@/api/api.system'
import DjiCloudException from '@/common/errors/DjiCloudException'
import JlinkStorage from '@/common/global/JlinkStorage'

export class PilotBridge extends ModuleBase {
  platform?: ReqGetDjiCloudPlatformInfo['Response']
  // 1
  private moduleThing: ModuleThing
  // 2
  private moduleLiveShare: ModuleLiveShare
  // 3
  private moduleApi: ModuleApi
  // 6
  moduleMedia: ModuleMedia

  constructor () {
    super()
    if (!window.djiBridge) {
      throw new DjiCloudException('当前平台不支持加载djiBridge', -1)
    }
    this.platform = JlinkStorage.get('pilotInfo')
    this.moduleThing = new ModuleThing()
    this.moduleLiveShare = new ModuleLiveShare()
    this.moduleApi = new ModuleApi()
    this.moduleMedia = new ModuleMedia()
    this.afterLoaded()
  }

  // 是否已经注册license
  private isVerified () {
    try {
      return this.bridgeSetBlock<boolean>(() => window.djiBridge.platformIsVerified())
    } catch (e) {
      return false
    }
  }

  isLoaded () {
    return this.isVerified() && this.checkModules()
  }

  private checkModules () {
    return this.moduleThing.isLoaded() && this.moduleLiveShare.isLoaded() && this.moduleApi.isLoaded() && this.moduleMedia.isLoaded()
  }

  private verified () {
    if (!this.isVerified()) {
      this.bridgeSetBlock(() => window.djiBridge.platformVerifyLicense(this.platform!.appId, this.platform!.appKey, this.platform!.appLicense))
    }
  }

  private afterLoaded () {
    if (this.isLoaded() && this.platform) {
      this.setInformation(this.platform.platformName, this.platform.workspaceName, this.platform.desc)
      this.setWorkspaceId(this.platform.workspaceId)
      this.onBackClick(function () {
        // baseNotify('sss', 'sss')
        return true
      })
    }
  }

  // 1.注册license
  async load (): Promise<void> {
    if (!this.isLoaded()) {
      if (!this.platform) {
        this.platform = await getDjiCloudPlatformInfo(undefined)
        JlinkStorage.set('pilotInfo', this.platform)
      }
      this.verified()
      await this.moduleThing.load()
      await this.moduleLiveShare.load()
      await this.moduleApi.load()
      await this.moduleMedia.load()
      this.afterLoaded()
    } else {
      console.error('pilot模块已经加载,请勿重复加载')
    }
  }

  unload () {
    this.moduleLiveShare.unload()
    this.moduleApi.unload()
    this.moduleMedia.unload()
    this.moduleThing.unload()
    JlinkStorage.remove('pilotInfo')
    // @ts-ignore
    this.platform = {}
    // this.stopSelf();
  }

  setMediaAutoUploadPhoto (auto: boolean) {
    this.moduleMedia.setMediaAutoUploadPhoto(auto)
  }

  setMediaUploadPhotoType (type: 0 | 1) {
    this.moduleMedia.setMediaUploadPhotoType(type)
  }

  setMediaAutoUploadVideo (auto: boolean) {
    this.moduleMedia.setMediaAutoUploadVideo(auto)
  }

  private setInformation (platformName: string, workspaceName: string, desc: string) {
    return this.bridgeSetBlock(() => window.djiBridge.platformSetInformation(platformName, workspaceName, desc))
  }

  private setWorkspaceId (uuid: string) {
    return this.bridgeSetBlock(() => window.djiBridge.platformSetWorkspaceId(uuid))
  }

  getLogPath () {
    return this.bridgeSetBlock<string>(() => window.djiBridge.platformGetLogPath())
  }

  // 遥控器SN
  getRemoteControllerSN () {
    return this.bridgeSetBlock<string>(() => window.djiBridge.platformGetRemoteControllerSN())
  }

  // 飞机SN
  getAircraftSN () {
    return this.bridgeSetBlock<string>(() => window.djiBridge.platformGetAircraftSN())
  }

  // 退出webview平台
  stopSelf () {
    this.bridgeSetBlock(() => window.djiBridge.platformStopSelf())
  }

  // 返回键回调
  onBackClick (callback: Function) {
    // window.djiBridge.onBackClick = () => {
    //   baseNotify("","onBackClick")
    //   // 自定义业务需求
    //   return true
    // }
  }

  // 设置日志加密key
  setLogEncryptKey (key: string) {
    this.bridgeSetBlock(() => window.djiBridge.platformSetLogEncryptKey(key))
  }

  // 清除日志加密key
  clearLogEncryptKey () {
    this.bridgeSetBlock(() => window.djiBridge.platformClearLogEncryptKey())
  }

  // 清除日志加密key
  getVersion () {
    return this.bridgeSetBlock<{
      modelVersion: string,
      appVersion: string
    }>(() => window.djiBridge.platformGetVersion())
  }
}
