export default class AircraftOsdTransform {
  toAircraftModeCodeDesc (modeCode?:number) {
    // {"0":"待机","1":"起飞准备","2":"起飞准备完毕","3":"手动飞行","4":"自动起飞","5":"航线飞行","6":"全景拍照","7":"智能跟随","8":"ADS-B 躲避","9":"自动返航","10":"自动降落","11":"强制降落","12":"三桨叶降落","13":"升级中","14":"未连接","15":"APAS","16":"虚拟摇杆状态","17":"指令飞行","18":"空中 RTK 收敛模式","19":"机场选址中","20":"POI环绕"}
    switch (modeCode) {
      case 0:return { name: '待机', level: 'status-free' }
      case 1:return { name: '起飞准备', level: 'status-free' }
      case 2:return { name: '起飞准备完毕', level: 'status-free' }
      case 3: return { name: '手动飞行', level: 'status-task' }
      case 4: return { name: '自动起飞', level: 'status-task' }
      case 5: return { name: '航线飞行', level: 'status-task' }
      case 6: return { name: '全景拍照', level: 'status-task' }
      case 7: return { name: '智能跟随', level: 'status-task' }
      case 8: return { name: 'ADS-B躲避', level: 'status-task' }
      case 9: return { name: '自动返航', level: 'status-task' }
      case 10: return { name: '自动降落', level: 'status-task' }
      case 11: return { name: '强制降落', level: 'status-remind' }
      case 12: return { name: '三桨叶降落', level: 'status-task' }
      case 13: return { name: '升级中', level: 'status-remind' }
      case 14: return { name: '未连接', level: 'status-remind' }
      case 15: return { name: '辅助飞行功能 - APAS', level: 'status-task' }
      case 16: return { name: '虚拟摇杆模式', level: 'status-task' }
      case 17:return { name: '指令飞行', level: 'status-task' }
      case 18: return { name: '空中 RTK 收敛模式 - APAS', level: 'status-task' }
      case 19: return { name: '机场选址中', level: 'status-task' }
      case 20:return { name: 'POI环绕', level: 'status-task' }
      default:return { name: '离线中', level: '' }
    }
  }

  toMaintenanceStatus (networkQuality:-1 | 0 | 1) {
    switch (networkQuality) {
      case 0:return '无保养'
      case 1:return '保养中'
      default:
        return 'unknown'
    }
  }

  toObstacleAvoidanceDownside (networkQuality?: 0 | 1) {
    switch (networkQuality) {
      case 0:return '关闭'
      case 1:return '开启'
      default:
        return 'unknown'
    }
  }

  toObstacleAvoidanceHorizon (networkQuality?: 0 | 1) {
    switch (networkQuality) {
      case 0:return '关闭'
      case 1:return '开启'
      default:
        return 'unknown'
    }
  }

  toObstacleAvoidanceUpside (networkQuality?: 0 | 1) {
    switch (networkQuality) {
      case 0:return '关闭'
      case 1:return '开启'
      default:
        return 'unknown'
    }
  }

  toDistanceState (networkQuality?: 0 | 1) {
    switch (networkQuality) {
      case 0:return '未设置'
      case 1:return '已设置'
      default:
        return 'unknown'
    }
  }

  toPositionQuality (networkQuality?: 1|2|3|4|5) {
    switch (networkQuality) {
      case 1:return '1档'
      case 2:return '2档'
      case 3:return '3档'
      case 4:return '4档'
      case 5:return '5档'
      default:
        return 'unknown'
    }
  }

  toPositionIsFixed (networkQuality?: 0|1|2|3) {
    switch (networkQuality) {
      case 0:return '非开始'
      case 1:return '收敛中'
      case 2:return '收敛成功'
      case 3:return '收敛失败'
      default:
        return 'unknown'
    }
  }

  translateHms (i:HmsResult) {
    let style: 'hms-warning' | 'hms-remind' | 'hms-notice'
    switch (i.level) {
      case 2:
        style = 'hms-warning'
        break
      case 1:
        style = 'hms-remind'
        break
      case 0:
        style = 'hms-notice'
        break
      default:
        style = 'hms-remind'
        break
    }
    return { style, text: i.messageZh }
  }

  toColorPalette (modeCode: number) {
    // {"0":"白热","1":"黑热","2":"描红","3":"医疗","5":"彩虹 1","6":"铁红","8":"北极","11":"熔岩","12":"热铁","13":"彩虹 2"}
    switch (modeCode) {
      case 0:return { id: 0, img: require('@/assets/image/image-login.jpg'), text: '白热' }
      case 1:return { id: 1, img: require('@/assets/image/image-login.jpg'), text: '黑热' }
      case 2:return { id: 2, img: require('@/assets/image/image-login.jpg'), text: '描红' }
      case 3: return { id: 3, img: require('@/assets/image/image-login.jpg'), text: '医疗' }
      case 5: return { id: 5, img: require('@/assets/image/image-login.jpg'), text: '彩虹 1' }
      case 6: return { id: 6, img: require('@/assets/image/image-login.jpg'), text: '铁红' }
      case 8: return { id: 8, img: require('@/assets/image/image-login.jpg'), text: '北极' }
      case 11: return { id: 11, img: require('@/assets/image/image-login.jpg'), text: '熔岩' }
      case 12: return { id: 12, img: require('@/assets/image/image-login.jpg'), text: '热铁' }
      case 13: return { id: 13, img: require('@/assets/image/image-login.jpg'), text: '彩虹 2' }
      default:return { id: -1, img: require('@/assets/image/image-login.jpg'), text: 'unknown' }
    }
  }
}
