import JlinkStorage from '@/common/global/JlinkStorage'
import { getDeviceEnumList, getDeviceModelList } from '@/api/api.deviceenum'
import { COORDINATE } from '@/common/enum/JLINK_ENUMS'

export default class JlinkValues {
  static async getDeviceEnum (domain?: number) {
    const models = JlinkStorage.get('DeviceEnum')
    let result: DeviceEnumVo[]
    if (models) {
      result = models
    } else {
      result = await getDeviceEnumList(undefined, false)
      JlinkStorage.set('DeviceEnum', result)
    }
    if (domain !== undefined) {
      return result.filterIf('deviceEnumDomain', domain)
    } else {
      return result
    }
  }
}

// 机场rtc方法

/* 所有全局变量 */
export const DATA_COORDINATE = COORDINATE.WGS84
// 当前地图坐标系
export const MAP_COORDINATE = COORDINATE.GCJ02
export const KB = 1024
export const MB = 1024 * KB
export const GB = 1024 * MB
