import WaylineBigemapHelper from '@/common/helper/map/WaylineBigemapHelper'
import { computed, nextTick, ref, Ref, WatchStopHandle } from 'vue'
import JlinkUtils from '@/common/global/JlinkUtils'
import JlinkThrottle from '@/common/annotate/JlinkThrottle'
import JlinkTruf from '@/common/global/JlinkTruf'
import DirectionVernier from '@/app/views/pages/library/wayline/editor/mapping2d/DirectionVernier.vue'
import JlinkTask from '@/common/global/JlinkTask'
import CameraHelper from '@/common/helper/CameraHelper'
import _ from 'lodash'
import { Color, HeightReference } from 'cesium'
import CesiumService from '@/components/cesium/CesiumService'

export default class Mapping2dBigemapHelper extends WaylineBigemapHelper<TemplateMapping2DInfo> {
  cameras = computed(() => {
    return this.payloadCapacity.value?.cameraParam?.wide
  })

  wideCameraParam = computed(() => this.cameras.value && new CameraHelper(this.cameras.value))

  firstPoint = computed<JlinkLocation|undefined>(() => this.dataRef.firstPoint)
  polygonPointIds = computed(() => this.dataRef.polygon.mapBy('id'))
  polygon = computed(() => this.dataRef.polygon)
  polygonCenter = ref<JlinkLocation>()
  polygonMargin = computed(() => JlinkTruf.expandedPolygon(this.polygon.value, this.dataRef.margin).map(item => {
    return { ...item, id: JlinkUtils.random.uuid(8) }
  }
  ))

  // 红外暂时不考虑有问题
  // irCameraParam = computed(() => new CameraHelper([1280, 1024], 30, 24))
  private polygonPointStyle = {
    normal: { background: '#fff', text: '#333' },
    dragged: { text: '#fff', background: '#4758ef' }
  }

  constructor (data: TemplateMapping2DInfo, bigemapRef: Ref<{
    mapService: () => Promise<CesiumService> | CesiumService
  } | undefined>, options?: { fitBoundsWhenPointChanged?: boolean }) {
    console.log(data, 'dataRef5')
    super(data, bigemapRef, options)
    this.drawMapping2().then()
  }

  async drawPolygon () {
    const entityMaker = await this.getEntity()
    const sceneMaker = await this.getScene()
    const pointWatchSave: StringKeyRecord<WatchStopHandle> = {}
    const _this = this
    const showList = computed(() => this.polygon.value.mapBy('id'))
    this.watchSource(() => this.polygon.value, function (value) {
      const polygonEntity = entityMaker.getById(_this.dataRef.common.waylineId)
      if (polygonEntity) {
        polygonEntity.drawPolygon()
          .setHierarchy(value)
      } else {
        entityMaker.makeById(_this.dataRef.common.waylineId)
          .drawPolygon()
          .setOutline(true)
          .setOutlineColor(Color.fromCssColorString('rgb(71,88,239)'))
          .setOutlineWidth(4)
          .setPerPositionHeight(true)
          .setHeightReference(HeightReference.CLAMP_TO_GROUND)

          .setColorMaterial(Color.fromCssColorString('rgba(71,88,239,0.3)'))
          .setHierarchy(value)
          .end()
          .addToView()
      }
      _this.polygonCheck()
    }, { deep: true, immediate: true })

    this.watchSource(() => showList.value, async function (v, o) {
      const newValue = v || []
      const oldValue = o || []
      await oldValue.diffTo(newValue, function (s, t) {
        return s === t
      }, async function (l) {
        for (const item of l) {
          if (pointWatchSave[item]) {
            pointWatchSave[item]()
            delete pointWatchSave[item]
          }
          entityMaker.removeById(item)
        }
      }, async function (l) {
        // 这里新建
        for (const item of l) {
          const cacheNode = ref(_this.polygon.value.findBy('id', item)!!)
          const index = showList.value.indexOf(item)
          const em = entityMaker.makeById(item)
            .drawPoint()
            .setPixelSize(32)
            .setColor(Color.fromCssColorString(_this.polygonPointStyle.normal.background))
            .end()
            .drawLabel()
            .setText('P')
            .setFillColor(Color.fromCssColorString(_this.polygonPointStyle.normal.text))
            .setFont('16px sans-serif')
            .setEyeOffset(0, 0, -10)
            .end()
            .setPosition(cacheNode.value)
            .addToView()
          const computedNode = computed<JlinkLocation & {
            id: string
          }>(() => JSON.parse(JSON.stringify(cacheNode.value)))
          pointWatchSave[cacheNode.value.id] = _this.watchSource(() => computedNode.value, function (value, oldValue1) {
            if (value?.lat !== oldValue1?.lat || value?.lng !== oldValue1?.lng || value?.height !== oldValue1?.height) {
              // 更新航点坐标
              value && em.setPosition(value)
              const polygonEntity = entityMaker.getById(_this.dataRef.common.waylineId)
              if (polygonEntity) {
                polygonEntity.drawPolygon().setHierarchy(_this.dataRef.polygon)
              }
              _this.polygonCheck()
            }
          }, { deep: true })
        }
        // 航点尾点变化
        const last = newValue.last()
        if (last && last !== oldValue.last()) {
          const entity = entityMaker.makeById(last)
          if (entity) {
            if (newValue.length > oldValue.length) {
              entity.drawModel().setScale(_this.aircraftGlbOptions.maxScale)
            }
          }
        }
      }, function (o) {
        return o
      })

      sceneMaker.requestRender()
      await nextTick()
      // 航点数据变化
      await _this.listChanged(newValue).then()
    }, { deep: true, immediate: true })
  }

  async drawPolygonMargin () {
    const entityMaker = await this.getEntity()
    this.watchSource(() => this.polygonMargin.value, async function (value, o) {
      const polygonEntity = entityMaker.getById('polygon_margin')
      if (polygonEntity) {
        polygonEntity.drawPolygon()
          .setHierarchy(value)
      } else {
        entityMaker.makeById('polygon_margin')
          .drawPolygon()
          .setOutline(true)
          .setHeightReference(HeightReference.CLAMP_TO_GROUND)
          .setOutlineColor(Color.fromCssColorString('rgb(216,252,59)'))
          .setOutlineWidth(4)
          .setPerPositionHeight(true)
          .setColorMaterial(Color.fromCssColorString('rgba(242,250,135,0.3)'))
          .setHierarchy(value)
          .end()
          .addToView()
      }
    }, { immediate: true })
  }

  async drawFirstPoint () {
    const _this = this
    const entityMaker = await this.getEntity()
    this.watchSource(() => this.dataRef.firstPoint, async function (value, o) {
      if (value) {
        const polygonEntity = entityMaker.getById('first_point')
        if (polygonEntity) {
          polygonEntity
            .drawModel()
            .setUri(_this.gatewayLandGlb())
            .setScale(0.2)
            .end()
            .setPosition(value)
        } else {
          entityMaker.makeById('first_point')
            .drawModel()
            .setUri(_this.aircraftGlb())
            .end()
            .setPosition(value)

            .addToView()
        }
      } else {
        entityMaker.removeById('first_point')
      }
    }, { immediate: true })
  }

  async drawPoints () {
    const entityMaker = await this.getEntity()
    this.watchSource(() => this.dataRef.points, function (value) {
      // entityMaker.removeByVagueId("testlines*")
      entityMaker.removeById('pointswayline')
      entityMaker.removeById('pointswayline_start')
      entityMaker.removeById('pointswayline_end')

      const pointswayline = entityMaker.getById('pointswayline')
      if (pointswayline) {
        pointswayline.drawPolyline()
          .setPositionsByPositions(value)
      } else {
        entityMaker.makeById('pointswayline')
          .drawPolyline()
          .setPositionsByPositions(value)
          .setWidth(3)
          .setClampToGround(true)
          .setZIndex(1000)
          .setColorMaterial(Color.fromCssColorString('#3fff00'))
          .end()
          .addToView()
      }
      if (value.length >= 2) {
        entityMaker.makeById('pointswayline_start')
          .drawPoint()
          .setPixelSize(32)
          .setColor(Color.fromCssColorString('#ec2f2f'))
          .end()
          .drawLabel()
          .setText('S')
          .setFillColor(Color.fromCssColorString('#fff'))
          .setFont('16px sans-serif')
          .setEyeOffset(0, 0, -10)
          .end()
          .setPosition(value.first()!)
          .addToView()
        entityMaker.makeById('pointswayline_end')
          .drawPoint()
          .setPixelSize(32)
          .setColor(Color.fromCssColorString('#ec2f2f'))
          .end()
          .drawLabel()
          .setText('E')
          .setFillColor(Color.fromCssColorString('#fff'))
          .setFont('16px sans-serif')
          .setEyeOffset(0, 0, -10)
          .end()
          .setPosition(value.last()!)
          .addToView()
      }
    }, { immediate: true })
  }

  async drawMapping2 () {
    await this.drawPolygonMargin()
    await this.drawPolygon()

    await this.drawFirstPoint()
    await this.drawPoints()
  }

  private loopDelayTask = JlinkTask.loopDelay(async function () {
    // @ts-ignore
    const entityMaker = await this.getEntity()
    entityMaker.removeCustomOverlayById('directionVernierViewCheck')
  }.bind(this), 1000)

  @JlinkThrottle(500, true, false)
  private async directionVernierViewCheck () {
    const entityMaker = await this.getEntity()
    if (this.polygonCenter.value && this.dataRef.polygonIntersecting !== true) {
      const vernier = entityMaker.getCustomOverlayById('directionVernierViewCheck')
      if (!vernier) {
        entityMaker.makeCustomOverlayById('directionVernierViewCheck', DirectionVernier)
          .setPosition(this.polygonCenter.value)
      }
    }
    this.loopDelayTask.triggerDelay(undefined)
  }

  @JlinkThrottle(500, false, true)
  private async polygonCheck () {
    const _this = this
    const entityMaker = await this.getEntity()
    const polygon = entityMaker.getById(_this.dataRef.common.waylineId)
    this.dataRef.common.polygonSquare = 0
    this.polygonCenter.value = undefined
    if (polygon) {
      const kinks = JlinkTruf.kinks([this.dataRef.polygon])
      this.dataRef.polygonIntersecting = kinks.features.length > 0
      if (this.dataRef.polygonIntersecting) {
        polygon
          .drawPolygon()
          .setOutlineColor(Color.fromCssColorString('rgb(250,34,34)'))
          .setColorMaterial(Color.fromCssColorString('rgba(239,71,71,0.3)'))
        this.dataRef.points = []
      } else {
        polygon
          .drawPolygon()
          .setOutlineColor(Color.fromCssColorString('rgb(71,88,239)'))
          .setColorMaterial(Color.fromCssColorString('rgba(71,88,239,0.3)'))
        this.dataRef.common.polygonSquare = JlinkTruf.area([this.dataRef.polygon])
        const center = JlinkTruf.center(this.dataRef.polygon)
        const coordinates = center?.geometry.coordinates
        if (coordinates && coordinates.length === 2) {
          this.polygonCenter.value = { lng: coordinates[0], lat: coordinates[1], height: 0 }
        }
      }
    }
  }

  @JlinkThrottle(500, false, true)
  private calculate () {
    this.wideCameraParam.value?.calculate(this.firstPoint.value, this.dataRef, true)
  }

  async openAsEdit () {
    await super.openAsEdit()
    const _this = this
    this.watchSource(() => this.dataRef.direction, function (value) {
      _this.directionVernierViewCheck()
      _this.calculate()
    })
    this.watchSource(() => _this.polygonMargin.value, function (value) {
      _this.calculate()
    })
    this.watchSource(() => this.dataRef.orthoCameraOverlapH, function (value) {
      _this.calculate()
    })
    this.watchSource(() => this.dataRef.orthoCameraOverlapW, function (value) {
      _this.calculate()
    })

    this.watchSource(() => this.dataRef.elevationOptimizeEnable, function (value) {
      _this.calculate()
    })
    this.watchSource(() => this.dataRef.shootType, function (value) {
      _this.calculate()
    })
    this.watchSource(() => this.dataRef.common.autoFlightSpeed, function (value) {
      _this.calculate()
    })
    this.watchSource(() => this.wideCameraParam.value, function (value) {
      const height = _this.dataRef.common.globalShootHeight
      value && (_this.dataRef.common.wideGSD = value.height2Gsd(height))
      // _this.calculate()
    }, { immediate: true })

    this.watchSource(() => [this.dataRef.common.globalShootHeight, this.dataRef.common.shootSurfaceHeight], function (value) {
      const globalShootHeight = (value[0] as number) || 0
      const shootSurfaceHeight = (value[1] as number) || 0
      _this.wideCameraParam.value && (_this.dataRef.common.wideGSD = _this.wideCameraParam.value.height2Gsd(globalShootHeight))
      _this.dataRef.height = globalShootHeight + shootSurfaceHeight
    }, { immediate: true })

    this.watchSource(() => _this.dataRef.height, function (value) {
      _this.dataRef.ellipsoidHeight = value
      _this.calculate()
    }, { immediate: true })

    this.watchSource(() => [this.dataRef.common.wideGSD, this.wideCameraParam.value], function (value) {
      const osd = value[0] as number
      const camera = value[1] as CameraHelper
      if (osd && camera) {
        _this.dataRef.common.globalShootHeight = camera.gsd2Height(osd)
      }
    })

    await this.setEntityDragStart(async function (id) {
      if (_this.polygonPointIds.value.includes(id)) {
        const sceneMaker = await _this.getScene()
        const entityMaker = await _this.getEntity()
        entityMaker.getById(id)?.drawPoint().setColor(Color.fromCssColorString(_this.polygonPointStyle.dragged.background))
          .end().drawLabel().setFillColor(Color.fromCssColorString(_this.polygonPointStyle.dragged.text))
        sceneMaker.requestRender()
        sceneMaker.setEnableTranslate(false)
      }
    })
    await this.setEntityDragEnd(async function (id) {
      if (_this.polygonPointIds.value.includes(id)) {
        const sceneMaker = await _this.getScene()
        const entityMaker = await _this.getEntity()
        entityMaker.getById(id)?.drawPoint().setColor(Color.fromCssColorString(_this.polygonPointStyle.normal.background)).end().drawLabel().setFillColor(Color.fromCssColorString(_this.polygonPointStyle.normal.text))
        sceneMaker.requestRender()
        sceneMaker.setEnableTranslate(true)
      }
    })
    await this.setEntityDragMove(function (id, p) {
      if (_this.polygonPointIds.value.includes(id)) {
        const target = _this.dataRef.polygon.findBy('id', id)
        if (target) {
          target.lat = p.lat
          target.lng = p.lng
          target.height = p.height
        }
      }
    })

    await this.setEntityPickRightClick(function (bigemapLocation, e) {
      _this.dataRef.polygon.removeIf('id', e?.entity.id)
      console.log(_this.dataRef.polygon, e?.entity.id)
    })
    // 双击打点添加航点
    await this.setDoubleClick(async function (e) {
      _this.dataRef.polygon.push({ ...e, id: JlinkUtils.random.uuid(8) })
    }, true)

    await this.setLongPress(async function (e) {
      console.log(e)
    //  _this.dataRef.firstPoint=e
    }, false)
  }

  canSave (): TemplateMapping2DInfo|undefined {
    const dataClone = _.cloneDeep(this.dataRef)
    dataClone.ellipsoidHeight = dataClone.common.globalShootHeight + dataClone.common.shootSurfaceHeight
    dataClone.common.globalPayloadLens = dataClone.common.globalPayloadLens.filter(i => i === 'visable' || i === 'wide')

    if (this.dataRef.polygon.length > 2 && this.dataRef.points.length > 1) {
      return dataClone
    } else {
      return undefined
    }
  }

  async destroy (): Promise<void> {
    super.destroy()
    const entity = await this.getEntity()
    entity.removeAll()
  }
}
