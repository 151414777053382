export default class DockRemoteTransform {
  toStepKeyDesc (stepKey: string) {
    if (stepKey) {
      switch (stepKey) {
        case 'get_bid':
          return '获取bid'
        case 'check_work_mode':
          return '检查是否进入远程调试模式'
        case 'check_task_state':
          return '检查机场是否空闲'
        case 'land_mcu_reboot':
          return '停机坪MCU重启'
        case 'rain_mcu_reboot':
          return '气象站MCU重启'
        case 'core_mcu_reboot':
          return '中心控制MCU重启'
        case 'sdr_reboot':
          return 'SDR重启'
        case 'write_reboot_param_file':
          return '写重启标志位'
        case 'get_drone_power_state':
          return '获取电池充电状态'
        case 'close_putter':
          return '合拢推杆'
        case 'check_wired_connect_state':
          return '获取飞机状态'
        case 'open_drone':
          return '打开飞机'
        case 'open_alarm':
          return '打开声光报警'
        case 'check_scram_state':
          return '检查急停开关是否按下'
        case 'open_cover':
          return '打开舱盖'
        case 'check_drone_sdr_connect_state':
          return '建立SDR无线连接'
        case 'turn_on_drone':
          return '打开飞机'
        case 'drone_paddle_forward':
          return '开启正转桨'
        case 'close_cover':
          return '关闭舱盖'
        case 'drone_paddle_reverse':
          return '开启反转桨'
        case 'drone_paddle_stop':
          return '停止转桨'
        case 'free_putter':
          return '展开推杆'
        case 'stop_charge':
          return '停止充电'
        default:
          return 'unknown'
      }
    } else {
      return 'unknown'
    }
  }

  toStatusDesc (status: string) {
    if (status) {
      switch (status) {
        case 'sent':
          return '已下发'
        case 'in_progress':
          return '执行中'
        case 'ok':
          return '执行成功'
        case 'paused':
          return '暂停'
        case 'rejected':
          return '拒绝'
        case 'failed':
          return '失败'
        case 'canceled':
          return '取消或终止'
        case 'timeout':
          return '超时'
        default:
          return 'unknown'
      }
    } else {
      return 'unknown'
    }
  }
}
