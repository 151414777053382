import DataException from '@/common/errors/DataException'
import InfoException from '@/common/errors/InfoException'
import { mittError } from '@/common/mitt/mitt'
import { ref, Ref } from 'vue'

/*
* JLink事件task
* */
export default class JlinkTask {
  static sleep (delay: number) {
    return new Promise((resolve) => {
      setTimeout(function () {
        resolve(true)
      }, delay)
    })
  }

  static async retry (count: number, delay: number, func: (count: number) => boolean | Promise<boolean>, final?: () => void) {
    let num = 0
    let innerCallback: Function | undefined = func
    async function t () {
      const result = innerCallback && (await innerCallback(num))
      if (!result) {
        num++
        if (num < count) {
          await JlinkTask.sleep(delay)
          await t()
        } else {
          final && final()
          // 防止外向引用，造成内存泄露
          innerCallback = undefined
        }
      }
    }

    await t()
  }

  static asyncGet<T> (get: () => (T | undefined | null) | Promise<T | undefined | null>, count = 5, delay = 1000, failMessage?: string): Promise<T> {
    const _this = this
    let getCallback: Function | undefined = get
    return new Promise<T>((resolve, reject) => {
      this.retry(count, delay, async function () {
        let g
        try {
          g = getCallback && await getCallback.apply(_this)
        } catch (e) {

        }
        if (g !== undefined) {
          resolve(g)
          return true
        } else {
          return false
        }
      }, function () {
        if (failMessage) {
          reject(new DataException(failMessage))
        } else {
          console.error(getCallback)
          reject(new InfoException('retry fail'))
        }
      }).catch(e => {
        reject(e)
      })
    }).finally(() => {
      getCallback = undefined
    })
  }

  static loopDelay<T> (fun: (data: T) => void, delay: number) {
    let loopDelayMark: number | undefined
    function triggerDelay (data: T) {
      if (loopDelayMark) {
        clearTimeout(loopDelayMark)
        loopDelayMark = undefined
      }
      loopDelayMark = setTimeout(function () {
        loopDelayMark = undefined
        fun(data)
      }, delay)
    }

    function cancel () {
      if (loopDelayMark) {
        clearTimeout(loopDelayMark)
        loopDelayMark = undefined
      }
    }

    function triggerNow (data: T) {
      if (loopDelayMark) {
        clearTimeout(loopDelayMark)
        loopDelayMark = undefined
      }
      fun(data)
    }

    return { triggerDelay, triggerNow, cancel, loopDelayMark }
  }

  static catchAwait (promise: () => Promise<void>, error?: (e: Error) => boolean, final?: () => void) {
    promise().catch((e) => {
      const capture = error && error(e)
      if (!capture) {
        mittError(e)
      }
    }).finally(() => {
      final && final()
    })
  }

  static idleMaker (idleTime: number = 5000, idleValue?: Ref<boolean>, callback?: (idle: boolean) => void) {
    let wheelingTimeout: NodeJS.Timeout | undefined
    const idle = idleValue || ref(true)

    function trigger () {
      idle.value = false
      callback && callback(idle.value)
      clearTimeout(wheelingTimeout)
      wheelingTimeout = setTimeout(function () {
        idle.value = true
        callback && callback(idle.value)
      }, idleTime)
    }

    function getIdle () {
      return idle.value
    }

    return { getIdle, trigger }
  }

  // 该方法实例后只会调用一次，要想再次执行调用flush

  static singleTriggerMaker () {
    let trigger = false
    function flush () {
      trigger = false
    }
    function exec (callback: () => boolean | Promise<boolean>) {
      if (!trigger) {
        const res = callback()
        if (typeof res === 'boolean') {
          trigger = res
        } else {
          trigger = true
          res.then((res) => {
            trigger = res
          }).catch(e => {
            trigger = false
            throw e
          })
        }
      }
    }

    return { exec, flush, trigger: () => trigger }
  }

  // 异步锁定执行，如果重复调用会drop
  static asyncLockMaker<T> (fun: ((data: T) => void | Promise<void>)) {
    const lockKeySave: StringKeyRecord<boolean> = {}
    function exec (data: T, key?: string) {
      const lockKey = key || JSON.stringify(data)
      if (!lockKeySave[lockKey]) {
        lockKeySave[lockKey] = true
        JlinkTask.catchAwait(async function () {
          await fun(data)
        }, undefined, function () {
          lockKeySave[lockKey] = false
        })
      } else {
        console.warn(fun + 'is lock exec, drop!')
      }
    }

    return exec
  }

  static loopPipeline<T> (call: (data: T, index: number) => Promise<void>, done:()=>void, context?: any) {
    const _this = this
    let list: T[] = []
    let idle = true
    let index = 0

    // next 方法
    async function next () {
      if (list.length === 0) {
        console.log('done')
        done()
        idle = true
        return
      }
      try {
        await call.apply(context || _this, [list.shift()!, index])
      } catch (e: any) {
        console.error(e.message)
      }
      index++
      console.log('next')
      await next()
    }

    // 添加任务
    function push (data: T) {
      list.push(data)
      if (idle) {
        index = 0
        idle = false
        next().then()
      }
    }

    function push2 (data: T) {
      list.push(data)
      if (idle) {
        index = 0
        idle = false
        next().then()
      }
    }

    function clear () {
      index = 0
      list = []
      idle = true
    }

    return {
      list,
      push,
      clear
    }
  }
}
