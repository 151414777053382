export default class DateUtil {
  parseTime (time?: Date|string|number, f?: DateParseFormat, defaultValue?:string): string {
    if (arguments.length === 0 || !time) {
      return defaultValue || ''
    }
    try {
      const format = f || '{y}-{m}-{d}'
      let date: Date
      if (time instanceof Date) {
        date = time
      } else {
        if (typeof time === 'string') {
          if ((/^[0-9]+$/.test(time))) {
            // support "1548221490638"
            time = parseInt(time)
          } else {
            // support safari
            // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
            time = (time).replace(new RegExp('/-/gm'), '/')
          }
        }

        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      return format.replace(/{([ymdhisa])+}/g, (result, key: 'y' | 'm' | 'd' | 'h' | 'i' | 's' | 'a') => {
        const value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
          return ['周日', '周一', '周二', '周三', '周四', '周五', '周六'][value]
        }
        return value.toString().padStart(2, '0')
      })
    } catch (e:any) {
      console.warn(e.message)
      return defaultValue || ''
    }
  }

  /**
   * @param {number} time
   * @param {string} option
   * @returns {string}
   */
  formatOffTime (time: number, format: DateParseFormat) {
    if (('' + time).length === 10) {
      time = time * 1000
    } else {
      time = +time
    }
    const d = new Date(time)
    const now = Date.now()

    const diff = (now - d.getTime()) / 1000

    if (diff < 30) {
      return '刚刚'
    } else if (diff < 3600) {
      // less 1 hour
      return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
      return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
      return '1天前'
    }
    if (format) {
      return this.parseTime(time, format)
    } else {
      return (
        d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
      )
    }
  }

  splitDays (start: number, end: number) {
    const days: number[] = []
    days.push(start)
    if (start <= end) {
      const dayTime = 3600 * 24 * 1000
      let offset = end - start
      let time = start
      while (offset > dayTime) {
        days.push(time += dayTime)
        offset -= dayTime
      }
    }
    return days
  }

  // 获取当天零点的时间
  dayMorning (time: Date) {
    return new Date(time.setHours(0, 0, 0, 0))
  }

  // 获取当天23:59:59的时间
  dayNight (time: Date) {
    return new Date(time.setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000 - 1)
  }

  // 获取前几天或者后几天的date
  dayOffset (time: Date, offset:number) {
    return new Date(time.getTime() + offset * 24 * 3600000)
  }

  // 获取前几天或者后几天的date
  timeOffset (time: Date, offsetTime:number) {
    return new Date(time.getTime() + offsetTime)
  }
}
