import CesiumService from '@/components/cesium/CesiumService'
import EntityHelper from '@/components/cesium/entities/EntityHelper'
import JlinkThrottle from '@/common/annotate/JlinkThrottle'
import CesiumInstance from '@/components/cesium/CesiumInstance'
import { getDroneTrail } from '@/api/api.drone'
import { Color, Entity } from 'cesium'
import JlinkTask from '@/common/global/JlinkTask'
type HighFrequencyData = JlinkLocation & { elevation: number, heading: number, pitch: number, roll: number }
export default class AircraftEntityHelper {
  private readonly map: CesiumService
  private readonly positionCache: StringKeyRecord<HighFrequencyData[]> = {}
  private aircraftGlb = () => require('@/assets/model/gg.glb').default
  follow:string|undefined
  private trailTrackRetryCount: StringKeyRecord<number> = {}
  protected aircraftGlbOptions = {
    rotate: -90,
    pitch: 180,
    maxScale: 0.18,
    minScale: 0.16,
    minSize: 32,
    baseLightFactor: { x: 1, y: 1 }
  }

  protected firstPerspective?: string

  getFirstPerspective () {
    return this.firstPerspective
  }

  constructor (map: CesiumService) {
    this.map = map
  }

  flyToAircraft (data:{aircraftSn?: string}) {
    if (this.follow !== data.aircraftSn) {
      if (data.aircraftSn) {
        const entity = this.getAircraftEntity({ aircraftSn: data.aircraftSn })
        if (entity) {
          this.lookAt()
          this.map.viewer.flyTo([entity])
          return true
        } else {
          console.warn('aircraft entity is not exist')
          return false
        }
      }
      return false
    } else {
      return false
    }
  }

  setFollow (follow?:string) {
    this.follow = follow
    if (follow) {
      const e = this.map.entity.getById(this.makeAircraftId({ aircraftSn: follow }))
      this.lookAt(e?.entity)
    } else {
      this.lookAt()
    }
  }

  private lookAt (e?: Entity | EntityHelper) {
    this.map.lookAt(e)
  }

  aircraftFirstPerspective (data?: { aircraftSn?: string }) {
    this.firstPerspective = data?.aircraftSn
    if (data?.aircraftSn) {
      const e = this.map.entity.getById(this.makeAircraftId({ aircraftSn: data.aircraftSn }))
      e?.drawModel().setShow(false)
    }
  }

  aircraftExit (data: { aircraftSn: string }) {
    return this.map.entity.getById(this.makeAircraftId({ aircraftSn: data.aircraftSn })) !== undefined
  }

  protected makeAircraftId (data: { aircraftSn: string }) {
    return 'aircraft_marker' + data.aircraftSn
  }

  protected makeTrailId (data: { aircraftSn: string }) {
    return 'fly_track' + data.aircraftSn
  }

  aircraftRemove (data: { aircraftSn: string }) {
    this.map.entity.removeById(this.makeAircraftId(data))
  }

  @JlinkThrottle(1000, false, true)
  private appendFirstPerspectiveCachePoint (data: { aircraftSn: string }, position: HighFrequencyData) {
    if (position.lat && position.lat) {
      return
    }
    if (position.height === undefined) {
      console.log('height is need in position')
      return
    }

    let positionCache = this.positionCache[data.aircraftSn]
    if (!positionCache) {
      this.positionCache[data.aircraftSn] = []
      positionCache = this.positionCache[data.aircraftSn]
    }

    if (position.elevation > 0.3) {
      const pre = positionCache.last()
      let distance = 3
      if (pre) {
        distance = CesiumInstance.computeDistanceBetweenCartographic(pre, position)
      }
      if (distance > 3) {
        positionCache.push(position)
      }
    }
  }

  getAircraftEntityRealIdFromEntity (entity?: Entity) {
    if (entity?.id?.startsWith('aircraft_marker')) {
      return entity?.id.replace('aircraft_marker', '')
    }
  }

  getAircraftTrailEntityRealIdFromEntity (entity?: Entity) {
    return entity?.id?.startsWith('fly_track') && entity.id.replace('fly_track', '')
  }

  getAircraftEntityAll () {
    return this.map.entity.getAllValues().filter(i => i.id.startsWith('aircraft_marker'))
  }

  getAircraftEntity (data: { aircraftSn: string}) {
    return this.map.entity.getAllValues().findBy('id', this.makeAircraftId(data))
  }

  aircraftDraw (data: { aircraftSn: string, position: HighFrequencyData, selected: boolean }) {
    if (!data.position.lat || !data.position.lat || data.position.height === undefined) {
      this.map.entity.removeById(this.makeAircraftId(data))
      console.log('data.position is not a useful value')
      return null
    }
    // 第一视角
    if (this.firstPerspective === data.aircraftSn) {
      // 进入第一视角
      this.map.camera.setView(data.position, data.position.heading, 0, data.position.roll)
      this.appendFirstPerspectiveCachePoint(data, data.position)
      return null
    } else {
      let e = this.map.entity.getById(this.makeAircraftId(data))
      if (!e) {
        e = this.map.entity.makeById(this.makeAircraftId(data))
        e!!.drawModel()
          .setUri(this.aircraftGlb())
          .setMinimumPixelSize(this.aircraftGlbOptions.minSize)
          .setImageBasedLightingFactor2(this.aircraftGlbOptions.baseLightFactor)
          .setScale(data.selected ? this.aircraftGlbOptions.maxScale : this.aircraftGlbOptions.minScale)
          .end()
          .addToView()
        if (this.follow === data.aircraftSn) {
          this.lookAt(e.entity)
        }
      }
      e.setShow(this.firstPerspective !== data.aircraftSn)
        .setPosition(data.position)
        // .setOrientation(data.position, data.position.heading + this.aircraftGlbOptions.rotate, data.position.pitch + this.aircraftGlbOptions.pitch, data.position.roll)
        .setOrientation(data.position, data.position.heading + this.aircraftGlbOptions.rotate, this.aircraftGlbOptions.pitch, data.position.roll)

      const flyTrack = this.map.entity.getById(this.makeTrailId(data))
      if (flyTrack) {
        const trackPositions: HighFrequencyData[] = []
        const positionCache = this.positionCache[data.aircraftSn] || []
        if (positionCache.length > 0) {
          trackPositions.push(...positionCache)
          positionCache.length = 0
          // 退出第一视角
          this.flyToAircraft(data)
        }
        if (data.position.elevation > 0.3) {
          trackPositions.push(data.position)
        }
        if (trackPositions.length > 0) {
          this.appendPointTrail(flyTrack, trackPositions)
        }
      }
      return e
    }
  }

  async aircraftTrailTrackDraw (data: { aircraftSn: string, selected: boolean }) {
    const flyTrack = this.map.entity.getById(this.makeTrailId(data))
    if (!flyTrack) {
      this.drawTrailTrackLock(data)
    } else {
      flyTrack
        .drawPolyline()
        .setZIndex(data.selected ? 20 : 10)
        .setColorMaterial(data.selected ? Color.fromCssColorString('#323eaf') : Color.RED)
    }
  }

  aircraftTrailTrackRemove (data: { aircraftSn: string }) {
    this.map.entity.removeById(this.makeTrailId(data))
    this.trailTrackRetryCount[data.aircraftSn] = 0
  }

  private drawTrailTrack (data: { aircraftSn: string, selected: boolean }) {
    const _this = this
    if (this.trailTrackRetryCount[data.aircraftSn] === undefined) {
      this.trailTrackRetryCount[data.aircraftSn] = 0
    }
    // 最多重试5次
    if (this.trailTrackRetryCount[data.aircraftSn] < 5) {
      JlinkTask.catchAwait(async function () {
        const result = await getDroneTrail(data, false)
        const eh = _this.map.entity.makeById(_this.makeTrailId(data))
        eh.drawPolyline()
          .setPositionsByPositions(result)
          .setWidth(4)
          .setClampToGround(true)
          .setZIndex(data.selected ? 20 : 10)
          .setColorMaterial(data.selected ? Color.fromCssColorString('#323eaf') : Color.RED)
          .end()
          .addToView()
      })
      this.trailTrackRetryCount[data.aircraftSn] += 1
    }
  }

  private drawTrailTrackLock = JlinkTask.asyncLockMaker(this.drawTrailTrack.bind(this))

  aircraftTrailSelect (data: { aircraftSn: string}) {
    const _this = this
    const all = this.map.entity.getAll()
    all.filter(it => it.entity.id.startsWith('fly_track'))
      .forEach(e => {
        e.drawPolyline()
          .setColorMaterial(Color.BLUE)
          .setZIndex(10)
      })

    const flyTrack = this.map.entity.getById(_this.makeTrailId(data))
    if (flyTrack) {
      flyTrack.drawPolyline()
        .setColorMaterial(Color.BLUE)
        .setZIndex(20)
    }
  }

  aircraftSelect (data: { aircraftSn: string}) {
    const _this = this
    const all = this.map.entity.getAll()
    all.filter(it => it.entity.id.startsWith('aircraft_marker'))
      .forEach(e => {
        e.drawModel().setScale(_this.aircraftGlbOptions.minScale)
      })
    const aircraft = this.map.entity.getById(_this.makeAircraftId(data))
    aircraft?.drawModel().setScale(_this.aircraftGlbOptions.maxScale)
  }

  @JlinkThrottle(1000, false, true)
  private appendPointTrail (flyTrack: EntityHelper, positions: JlinkLocation[]) {
    flyTrack.drawPolyline().appendPositions(positions)
  }
}
