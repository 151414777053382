import JlinkType from '@/common/global/JlinkType'

export default class UserTransform {
  toRole (auth:string[]|{userAuthorities:string[]}) {
    let a
    if (Array.isArray(auth)) {
      a = auth
    } else {
      a = auth.userAuthorities
    }
    if (JlinkType.user.isSuperUser(a)) {
      return '超级管理员'
    } else if (JlinkType.user.isAdminUser(a)) {
      return '管理员'
    } else {
      return '普通'
    }
  }

  toStatus (status: number|LoginUserVo) {
    let s
    if (typeof status === 'object') {
      s = status.userStatus
    } else {
      s = status
    }
    switch (s) {
      case 0:
        return '激活'
      case 1:
        return '未激活'
      case 2:
        return '异常'
    }
  }

  toGender (gender?:number|LoginUserVo) {
    let g
    if (typeof gender === 'object') {
      g = gender.userGender
    } else {
      g = gender
    }
    switch (g) {
      case 0:
        return { type: 'female', desc: '女' }
      case 1:
        return { type: 'male', desc: '男' }
      default:
        return { type: 'unknown', desc: '未知' }
    }
  }
}
