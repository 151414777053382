export default class RegexUtil {
  /**
   * @param {string} path
   * @returns {Boolean}
   */
  isExternal (path: string) {
    return /^(https?:|http?:|mailto:|tel:)/.test(path)
  }

  isMobile (str: string) {
    // todo 正则不准确
    const reg = /^1\d{10}$/
    return reg.test(str)
  }

  isLoginName (str: string) {
    return this.isMobile(str)
  }

  isPassword (str: string) {
    return str.length >= 6 && str.length <= 20
  }

  isImage (str:string) {
    const reg = /\.(png|jpg|gif|jpeg|webp|jfif)$/
    return reg.test(str.toLowerCase())
  }

  isVideo (str:string) {
    const reg = /\.(avi|mov|mp4|mkv|m2v|rmvb|rm)$/
    return reg.test(str.toLowerCase())
  }

  isPhoneNum (num: string) {
    return (/^1[34578]\d{9}$/.test(num))
  }

  isUrl (url: string) {
    return /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/.test(url)
  }

  isUserName (str: string) {
    return str.length > 0 && str.length <= 16
  }
}
