export default class MathUtil {
  // 计算2个位置上相对正北的计算
  degreesFrom (x:number, y:number) {
    // 计算斜率,对边比斜边的值
    const slope = x / y
    // 计算斜率对应的角的弧度
    const radian = Math.atan(slope)
    // 计算弧度对应的角度
    const degrees = radian * 57.29577951308232
    return degrees
  }
}
