import { reactive, ref, Ref, toRaw, watch } from 'vue'

import JlinkMqtt from '@/common/global/JlinkMqtt'
import JlinkMqttReactiveBase from '@/common/mqtt/jlink/JlinkMqttReactiveBase'
/* 该类实现mqtt按需注册 自动销毁 */
export default class JlinkMqtt2 {
  private static drones: StringKeyRecord<JlinkMqttReactiveBase> = {}
  private static now = 0
  static {
    const _this = this
    setInterval(function () {
      _this.now = Date.now()
      // Object.keys(_this.drones).forEach(key => {
      //   if (_this.now - 10000 > _this.drones[key].active) {
      //     const target = _this.drones[key]
      //     if (target) {
      //       target.destory()
      //       delete _this.drones[key]
      //     }
      //   }
      // })
      console.log('test worker', _this.drones)
      // console.log('test mqtt ', JlinkMqtt.mqtt.listener)
    }, 5000)
  }

  static clear () {
    Object.values(this.drones).forEach(item => {
      item.destory()
    })
    this.drones = {}
  }

  static getReactiveDevice<T extends DroneBaseVo|undefined> (drone: T) :T extends undefined ?undefined:JlinkMqttReactiveBase {
    if (drone) {
      if (!this.drones[drone.droneId]) {
        this.drones[drone.droneId] = new JlinkMqttReactiveBase(toRaw(drone))
      }
      this.drones[drone.droneId].active = this.now
      return this.drones[drone.droneId] as T extends undefined ?undefined:JlinkMqttReactiveBase
    } else {
      return undefined as T extends undefined ?undefined:JlinkMqttReactiveBase
    }
  }

  static getReactiveDeviceByGatewaySn (gatewaySn: string) {
    return Object.values(this.drones).find(item => item.drone.gatewaySerialNumber === gatewaySn)
  }

  static getReactiveDeviceByAircraftSn (aircraftSn: string) {
    return Object.values(this.drones).find(item => item.drone.aircraftSerialNumber === aircraftSn)
  }

  static getAircraftOnline (drone?: DroneBaseVo) {
    return this.getReactiveDevice(drone)?.getAircraftOnline()
  }

  static getGatewayOnline (drone?: DroneBaseVo) {
    return this.getReactiveDevice(drone)?.getGatewayOnline()
  }
}
