import JlinkTask from '@/common/global/JlinkTask'

// const bigemapLockLoad = JlinkTask.asyncLockMaker(function () {
//   if (!document.getElementById('bigemap_script')) {
//     const script = document.createElement('script')
//     script.id = 'bigemap_script'
//     script.src = `${process.env.VUE_APP_BIGEMAP_HOST}/bigemap-gl.js/v1.1.0/bigemap-gl.js`
//     window.document.body.appendChild(script)
//     const css = document.createElement('link')
//     css.href = `${process.env.VUE_APP_BIGEMAP_HOST}/bigemap-gl.js/v1.1.0/Widgets/widgets.css`
//     css.rel = 'stylesheet'
//     window.document.body.appendChild(css)
//   }
//
// })
// const tentcentmapLockLoad = JlinkTask.asyncLockMaker(function () {
//   const txMapToken = 'OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77'
//   if (!document.getElementById('tencentMapScript')) {
//     const script = document.createElement('script')
//     script.id = 'tencentMapScript'
//     script.type = 'text/javascript'
//     script.src = `https://map.qq.com/api/gljs?v=1.exp&key=${txMapToken}&callback=initMap&libraries=geometry`
//     window.document.body.appendChild(script)
//   }
// })
// export async function loadBigemap () {
//   bigemapLockLoad(undefined, 'bigemap_load')
//   return await JlinkTask.asyncGet(() => window.bmgl, 30)
// }
// export async function loadTencentmap () {
//   tentcentmapLockLoad(undefined, 'tencentmap_load')
//   // @ts-ignore
//   return await JlinkTask.asyncGet(() => window.TMap, 30)
// }
export const svgLoad = function () {
  // 加载svg图标
  // @ts-ignore
  const req = require.context('@/assets/svg', false, /\.svg$/)
  req.keys().map(req)
}
