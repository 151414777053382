import {Ref} from 'vue'
import FlyBigemapHelper from '@/common/helper/map/FlyBigemapHelper'
import {Entity} from 'cesium'
import CesiumService from '@/components/cesium/CesiumService'
import JlinkMqtt2 from '@/common/global/JlinkMqtt2'
import ControlPointDIngHelper from '@/common/helper/map/ControlPointDingHelper'
import {FLIGHT_TASK_STATUS} from "@/common/enum/JLINK_ENUMS";

export default class ControlBigemapHelper extends FlyBigemapHelper {
  private readonly drone: Ref<DroneBaseVo | undefined>
  private pointsDingHelper: ControlPointDIngHelper|undefined

  constructor (map: Ref<{ mapService: () => Promise<CesiumService> | CesiumService } | undefined>, drone: Ref<DroneBaseVo|undefined>) {
    super(map)
    this.drone = drone
  }

  flyToPoints (id:string) {
    this.pointsDingHelper?.flyToPoints(id)
  }

  async destroy () {
    await super.destroy()
    this.pointsDingHelper?.destroy()
    this.pointsDingHelper=undefined
  }

  async mapServiceLoaded (service: CesiumService) {
    await super.mapServiceLoaded(service)
    const _this = this

    this.watchSource(() => this.drone.value, function (drone, oldValue, onCleanup) {
      if (oldValue) {
        _this.stopWatchSource('online' + oldValue.droneId)
        _this.stopWatchSource('aircraftOsd' + oldValue.droneId)
        _this.stopWatchSource('dockOsd' + oldValue.droneId)
        _this.stopWatchSource('flightTask' + oldValue.droneId)
        _this.removeAircraftTrack(oldValue.aircraftSerialNumber)
        _this.removeTrailTrack(oldValue.aircraftSerialNumber)
        _this.removeGatewayTrack(oldValue.gatewaySerialNumber)
      }
      if (drone) {
        const reactive = JlinkMqtt2.getReactiveDevice(drone)
        _this.watchSource(() => reactive.getOnlineValue(), function (value) {
          if (!value?.aircraftOnline) {
            _this.removeAircraftTrack(drone.aircraftSerialNumber)
            _this.removeTrailTrack(drone.aircraftSerialNumber)
          }
          if (!value?.gatewayOnline) {
            _this.removeGatewayTrack(drone.gatewaySerialNumber).then()
          }
        }, { immediate: true }, 'online' + drone.droneId)

        _this.watchSource(() => reactive.getAircraftOsdValue(), function (osd) {
          if (osd) {
            _this.addTrailTrack({ aircraftSn: osd.aircraftSn, selected: osd.aircraftSn === _this.selectedAircraftSn })
            _this.addAircraftTrack(osd).then()
          } else {
            _this.removeAircraftTrack(drone.aircraftSerialNumber)
            _this.removeTrailTrack(drone.aircraftSerialNumber)
          }
        }, undefined, 'aircraftOsd' + drone.droneId)

        _this.watchSource(() => reactive.getDockOsdValue(), function (osd) {
          if (osd) {
            _this.addDockTrack(osd).then()
          } else {
            _this.removeGatewayTrack(drone.gatewaySerialNumber).then()
          }
        }, undefined, 'dockOsd' + drone.droneId)

        _this.watchSource(() => reactive.getDockFlightTaskProcessValue(), function (osd) {
          if (osd) {
            if (osd.taskStatus === FLIGHT_TASK_STATUS.PAUSED) {
              // 航线暂停时停止所有航点信息播放
              _this.stopCurrentSpeech()
            }
            _this.addWaylineTrack(osd).then()
          }
        }, undefined, 'flightTask' + drone.droneId)
        _this.selectGatewayAll(drone)
      }
    }, { immediate: true })
    this.pointsDingHelper = new ControlPointDIngHelper(this.drone, service)

    this.pointsDingHelper.watchControlPoints()

    await this.setDoubleClick(async function (e) {
      _this.pointsDingHelper?.click(e)
    }, false)

    await this.setEntityPickRightClick(async function (bigemapLocation, e) {
      _this.pointsDingHelper?.selected(bigemapLocation, e)
    })

    const entityChangedAbstract = function (e?: Entity) {
      const aircraftEntityRealId = _this.getAircraftEntityRealIdFromEntity(e)
      if (aircraftEntityRealId) {
        _this.setFollow(aircraftEntityRealId)
        return
      }
      const gatewayLandEntityRealId = _this.getGatewayLandEntityRealIdFromEntity(e)
      if (gatewayLandEntityRealId) {
        _this.setFollow(_this.drone.value?.aircraftSerialNumber)
        return
      }
      const gatewayEntityRealId = _this.getGatewayEntityRealIdFromEntity(e)
      if (gatewayEntityRealId) {
        _this.setFollow(_this.drone.value?.aircraftSerialNumber)
        return
      }
      const trailEntityRealId = _this.getTrailEntityRealIdFromEntity(e)
      if (trailEntityRealId) {
        _this.setFollow(trailEntityRealId)
        return
      }
      _this.setFollow(undefined)
    }
    service.viewer.removeOnSelectedEntityChanged(entityChangedAbstract).addOnSelectedEntityChanged(entityChangedAbstract, _this)
  }
}
