import { computed, reactive, Ref, ref, watch } from 'vue'
import FlyBigemapHelper from '@/common/helper/map/FlyBigemapHelper'
import { mittError } from '@/common/mitt/mitt'
import DataException from '@/common/errors/DataException'
import { autoExecuteFlightTask } from '@/api/api.flighttask'
import { MAP_COORDINATE } from '@/common/global/JlinkValues'
import { getDroneListByPage } from '@/api/api.drone'
import EmergencyMarkControlPoint from '@/app/views/pages/emergencyTasks/EmergencyMarkControlPoint.vue'
import JlinkTransform from '@/common/global/JlinkTransform'
import JlinkTask from '@/common/global/JlinkTask'
import { Color, Entity, HeadingPitchRange } from 'cesium'
import CesiumService from '@/components/cesium/CesiumService'
import JlinkMqtt2 from '@/common/global/JlinkMqtt2'
import { JOB_TYPE } from '@/common/enum/JLINK_ENUMS'
export default class PageEmergencyBigemapHelper extends FlyBigemapHelper {
  selected = ref<DroneBaseVo>()
  nearest = ref<DroneBaseVo>()
  drones = ref<DroneBaseVo[]>([])
  droneExecuteJobTypeSave = ref<StringKeyRecord<{ assignmentsId?: string, jobType: JOB_TYPE }>>({})
  aircraftHelper = computed(() => JlinkMqtt2.getReactiveDevice(this.selected.value)?.getAircraftLiveStream())
  gatewayHelper = computed(() => JlinkMqtt2.getReactiveDevice(this.selected.value)?.getGatewayLiveStream())
  targetLocation = ref<JlinkLocation>()
  private polylineId = 'PageEmergencyPolyline'
  private markerId = 'PageEmergencyMarker'
  private labelId = 'PageEmergencyLabel'
  currentData = reactive<{ distance?: number }>({
    distance: undefined
  })

  private loadedSave = new Set<string>()
  private loaded = false

  async handleExecute () {
    const drone = this.selected.value
    const targetLocation = this.targetLocation.value
    const device= JlinkMqtt2.getReactiveDevice(drone)
    const droneOsd = device?.getDockOsdValue()
    if (drone&&device&&droneOsd && targetLocation) {
      if (!device.getValueTranslate().isDockIdleMode()) {
        mittError(new DataException(`${drone.droneName}不是空闲机场`))
        return
      }
      await autoExecuteFlightTask({
        gatewaySn: drone.gatewaySerialNumber,
        start: [droneOsd.lng, droneOsd.lat, droneOsd.height].join(','),
        end: [targetLocation.lng, targetLocation.lat, targetLocation.height].join(','),
        coordinate: MAP_COORDINATE
      })
      this.targetLocation.value = undefined
    } else {
      mittError(new DataException('请设置新的目的地'))
    }
  }

  async deleteExecute () {
    this.targetLocation.value = undefined
  }

  async mapServiceLoaded (service: CesiumService): Promise<void> {
    await super.mapServiceLoaded(service)
    const _this = this

    this.drones.value = await getDroneListByPage({ gatewayDomain: '3', page: 0, size: 100 }).then(i => i.data.filter(item => JlinkMqtt2.getGatewayOnline(item)))

    for (const drone of this.drones.value) {
      _this.watchSource(() => JlinkMqtt2.getReactiveDevice(drone).getOnlineValue(), function (value) {
        if (!value?.aircraftOnline) {
          _this.removeTrailTrack(drone.aircraftSerialNumber)
        }
        if (!value?.gatewayOnline) {
          _this.removeGatewayTrack(drone.gatewaySerialNumber).then()
        }
      }, { immediate: true })

      _this.watchSource(() => JlinkMqtt2.getReactiveDevice(drone).getAircraftOsdValue(), async function (osd) {
        if (osd) {
          _this.addTrailTrack({ aircraftSn: drone.aircraftSerialNumber, selected: true })
          const save = _this.droneExecuteJobTypeSave.value[drone.gatewaySerialNumber]
          // 只有在执行应急任务的飞行器才会显示
          if (save?.jobType === 1) {
            await _this.addAircraftTrack(osd).then()
          }
        } else {
          _this.removeAircraftTrack(drone.aircraftSerialNumber)
          _this.removeTrailTrack(drone.aircraftSerialNumber)
        }
      })

      _this.watchSource(() => JlinkMqtt2.getReactiveDevice(drone).getDockOsdValue(), async function (value) {
        if (value) {
          await _this.addDockTrack(value).then()
          _this.loadedSave.add(value.gatewaySn)
          if (!_this.loaded) {
            if (_this.loadedSave.size === _this.drones.value.filter(item => JlinkMqtt2.getGatewayOnline(item)).length) {
              const dockEntities = await _this.getGatewayEntityAll()
              await _this.flyTo(dockEntities, { offset: new HeadingPitchRange(0, 0, 1000) })
              _this.loaded = true
            }
          }
        } else {
          await _this.removeGatewayTrack(drone.gatewaySerialNumber)
        }
      })

      _this.watchSource(() => JlinkMqtt2.getReactiveDevice(drone).getDockFlightTaskProcessValue(), function (result) {
        if (result) {
          if (JlinkTransform.waylineJobTask.JobTaskStatusEnd(result.taskStatus)) {
            delete _this.droneExecuteJobTypeSave.value[result.gatewaySn]
          } else {
            _this.droneExecuteJobTypeSave.value[result.gatewaySn] = {
              assignmentsId: drone.assignmentsId,
              jobType: result.jobType
            }
          }
          if (result.jobType === 1) {
            _this.addWaylineTrack(result).then()
          }
        }
      })
    }

    _this.watchSource(() => [_this.selected.value, _this.targetLocation.value], async function (value) {
      const drone = value[0] as DroneBaseVo
      if (!drone) {
        return
      }
      const targetLocation = value[1] as JlinkLocation
      const entityMaker = await _this.getEntity()
      entityMaker.removeById(_this.markerId)
      entityMaker.removeById(_this.polylineId)
      entityMaker.removeCustomOverlayById(_this.labelId)
      if (_this.droneExecuteJobTypeSave.value[drone.gatewaySerialNumber]) {
        return
      }
      const droneOsd = JlinkMqtt2.getReactiveDevice(drone).getDockOsdValue()

      if (targetLocation && droneOsd) {
        _this.currentData.distance = await _this.computeDistance(droneOsd, targetLocation)
        entityMaker.makeById(_this.markerId)
          .drawModel()
          .setUri(_this.pointGlb())
          .setMinimumPixelSize(_this.pointGlbOptions.minSize)
          .setScale(_this.poiPointGlbOptions.maxScale)
          .setImageBasedLightingFactor2(_this.pointGlbOptions.baseLightFactor)
          .end()
          .setPosition(targetLocation)
          .setOrientation(targetLocation, _this.pointGlbOptions.rotate, _this.pointGlbOptions.pitch, 0)
          .addToView()
        entityMaker.makeById(_this.polylineId)
          .drawPolyline()
          .setWidth(4)
          .setPolylineDashMaterial({ color: (_this.currentData?.distance || 0) > 5000 ? Color.RED : Color.WHITE })
          .setPositionsByPositions([droneOsd, targetLocation])
          .end()
          .addToView()
        const overlay = entityMaker.getCustomOverlayById(_this.labelId)
        if (overlay) {
          overlay.setPosition(targetLocation, { y: 1.1 })
        } else {
          entityMaker.makeCustomOverlayById(_this.labelId, EmergencyMarkControlPoint)
            .setProps({ data: _this.currentData })
            .setEvents({
              trigger: _this.handleExecute.bind(_this),
              delete: _this.deleteExecute.bind(_this)
            })
            .setPosition(targetLocation, { y: 1.1 })
        }
      }
    }, { immediate: true })
    const gatewayLiveOpenDelay = JlinkTask.loopDelay<undefined>(function () {
      _this.gatewayHelper.value?.handleLiveStreamOpen()
    }, 5000)
    const aircraftLiveOpenDelay = JlinkTask.loopDelay<undefined>(async function () {
      await _this.aircraftHelper.value?.cameraAutoOpenLensSwitchableCamera(true)
    }, 5000)
    _this.watchSource(() => _this.selected.value, async function (value) {
      await _this.selectGatewayAll(value)
      if (!JlinkMqtt2.getGatewayOnline(value)) return
      if (JlinkMqtt2.getAircraftOnline(value) && !_this.aircraftHelper.value?.isPlaying()) {
        aircraftLiveOpenDelay.triggerDelay(undefined)
      } else if (!_this.aircraftHelper.value?.isPlaying() && !_this.gatewayHelper.value?.isPlaying()) {
        gatewayLiveOpenDelay.triggerDelay(undefined)
      }
    })
    watch(() => _this.aircraftHelper.value?.isPlaying(), async function (v) {
      if (!v && JlinkMqtt2.getAircraftOnline(_this.selected.value)) {
        aircraftLiveOpenDelay.triggerDelay(undefined)
      }
    })

    watch(() => _this.aircraftHelper.value?.getCurrentLens(), async function (v) {
      if (v?.length === 0 && JlinkMqtt2.getAircraftOnline(_this.selected.value)) {
        aircraftLiveOpenDelay.triggerDelay(undefined)
      }
    })

    _this.setDoubleClick(async function (e) {
      const position = e
      position.height = 150
      const idleDockValues = _this.drones.value.filter((item) => JlinkMqtt2.getReactiveDevice(item).getValueTranslate().isDockIdleMode() && JlinkMqtt2.getGatewayOnline(item))
      if (idleDockValues.length === 0) {
        mittError(new DataException('未找到任何上线的空闲机场'))
        return
      }
      const list: { drone: DroneBaseVo, distance: number }[] = []
      for (const idleValue of idleDockValues) {
        list.push({ drone: idleValue, distance: await _this.computeDistance(JlinkMqtt2.getReactiveDevice(idleValue).getDockOsdValue()!, position) })
      }
      const nearest = list.sortBy('distance', 'asc')[0]
      if (!nearest) {
        mittError(new DataException('未找到任何最近的匹配机场'))
        return
      }
      _this.nearest.value = nearest.drone
      _this.selected.value = nearest.drone
      _this.targetLocation.value = position
    }, false).then()
  }

  async destroy () {
    await super.destroy()
  }
}
