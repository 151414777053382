export enum ExceptionEnum {
  // 大疆上云
  DJI_CLOUD,
  // mqtt
  MQTT,
  // 提醒
  NOTIFY,
  // 请求
  REQUEST,
  // 响应
  RESPONSE,
  // 组件
  COMPONENTS,
  // 数据错误
  DATA,
  NEED_RE_LOGIN,
  MAP,
  INFO,
  SRS
}
