export default class DroneTypeMaker {
  check (enumKey: string) {
    const types = enumKey.split('-')
    if (types.length !== 3) {
      throw Error('设备枚举不合法')
    }
    return types
  }

  getDomain (enumKey: string) {
    return this.check(enumKey)[0]
  }

  getType (enumKey: string) {
    return this.check(enumKey)[1]
  }

  getSubType (enumKey: string) {
    return this.check(enumKey)[2]
  }

  toEnumKeyFromValue (domain: string | number, type: string | number, subType: string | number) {
    return domain + '-' + type + '-' + subType
  }

  toGatewayEnumKeyFromDrone (drone:DroneBaseVo) {
    return this.toEnumKeyFromValue(drone.gatewayDomain, drone.gatewayType, drone.gatewaySubType)
  }

  toAircraftEnumKeyFromDrone (drone:DroneBaseVo) {
    return this.toEnumKeyFromValue(drone.aircraftDomain, drone.aircraftType, drone.aircraftSubType)
  }

  toEnumKey (keys: string[]) {
    const enumKey = keys.join('-')
    this.check(enumKey)
    return enumKey
  }

  isEnumKey (enumKey?: string) {
    if (enumKey) {
      try {
        this.check(enumKey)
        return true
      } catch (e) {
        return false
      }
    } else {
      return false
    }
  }

  isDroneTemp (droneOrEnumKey ?: DroneBaseVo | string) {
    if (typeof droneOrEnumKey === 'object') {
      return droneOrEnumKey.gatewayDomain === 2 && droneOrEnumKey.gatewayType === 99999 && droneOrEnumKey.gatewaySubType === 99999
    } else {
      const types = droneOrEnumKey?.split('-')
      return types && types[0] === '2' && types[1] === '99999' && types[2] === '99999'
    }
  }

  isAircraftUnknown (enumKey: string) {
    try {
      return this.getDomain(enumKey) === '0' && this.getType(enumKey) === '99999' && this.getSubType(enumKey) === '99999'
    } catch (e) {
      return false
    }
  }

  isDock (droneOrGatewayDomain?: DroneBaseVo | number) {
    if (typeof droneOrGatewayDomain === 'object') {
      return droneOrGatewayDomain.gatewayDomain === 3
    } else {
      return droneOrGatewayDomain === 3
    }
  }

  isDock2 (droneBaseVo?: DroneBaseVo): Boolean {
    return !!droneBaseVo && this.isDock(droneBaseVo) && droneBaseVo.gatewayType === 2
  }

  isRc (droneOrGatewayDomain?: DroneBaseVo | number) {
    if (typeof droneOrGatewayDomain === 'object') {
      return droneOrGatewayDomain.gatewayDomain === 2
    } else {
      return droneOrGatewayDomain === 2
    }
  }
}
