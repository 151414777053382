import { FLIGHT_TASK_STATUS } from '@/common/enum/JLINK_ENUMS'

export default class WaylineJobTaskTransform {
  JobTaskStatusEnd (taskStatus: FLIGHT_TASK_STATUS) {
    switch (taskStatus) {
      case 1:
      case 2:
      case 3:
      case 21:
      case 51:
      case 52:
      case 54:
        return false
      default:
        return true
    }
  }

  covertTaskStatus (taskStatus: FLIGHT_TASK_STATUS) {
    switch (taskStatus) {
      case 0:
        return '错误'
      case 1:
        return '已下发'
      case 2:
        return '执行中'
      case 3:
        return '暂停'
      case 4:
        return '拒绝'
      case 5:
        return '执行失败'
      case 6:
        return '取消或终止'
      case 7:
        return '超时'
      case 8:
        return '部分完成'
      case 9:
        return '执行成功'
      case 21:
        return '等待断点续飞'
      case 51:
        return '准备起飞'
      case 52:
        return '执行中'
      case 53:
        return '执行失败'
      case 54:
        return '执行成功，已飞向目标点'
      case 55:
        return '取消飞向目标点'
      case 56:
        return '一键起飞任务完成'

      default:
        return '未知'
    }
  }

  covertTaskStep (currentStep: number) {
    switch (currentStep) {
      case 0:
        return '初始状态'
      case 1:
        return '启动前检查，飞行器是否在执行航线中'
      case 2:
        return '启动前检查，机场是否退出工作模式'
      case 3:
        return '启动前检查，航线执行中'
      case 4:
        return '启动前检查，返航中'
      case 5:
        return '航线执行进入准备状态，开始等待任务下发'
      case 6:
        return '机场进入工作状态'
      case 7:
        return '进入开机检查准备工作和开盖准备工作'
      case 8:
        return '等待飞行系统准备就绪，推送连接建立'
      case 9:
        return '等待 RTK 源监听有值报'
      case 10:
        return '检查 RTK 源是否是机场源，如果不是要重新设置'
      case 11:
        return '等待飞行控制权通知'
      case 12:
        return '机场无控制权，抢飞行器控制权抢夺'
      case 13:
        return '获取最新 KMZ URL'
      case 14:
        return '下载 KMZ'
      case 15:
        return 'KMZ 上传中'
      case 16:
        return '染色配置'
      case 17:
        return '飞行器起飞参数设置，备降点设置，起飞高度设置，染色设置'
      case 18:
        return '飞行器 flyto 起飞参数设置'
      case 19:
        return 'Home 点设置'
      case 20:
        return '触发执行航线'
      case 21:
        return '航线执行中'
      case 22:
        return '进入返航的检查准备工作'
      case 23:
        return '飞行器降落机场'
      case 24:
        return '降落以后的关盖'
      case 25:
        return '机场退出工作模式'
      case 26:
        return '机场异常恢复'
      case 27:
        return '机场上传飞行系统日志'
      case 28:
        return '相机录像状态检查'
      case 29:
        return '获取媒体文件数量'
      case 30:
        return '机场起飞开盖的异常恢复'
      case 31:
        return '通知任务结果'
      case 32:
        return '任务执行完成，通过配置文件配置，是否进行主动 log 拉取'
      case 33:
        return '日志列表拉取 - 飞行器列表'
      case 34:
        return '日志列表拉取 - 拉取机场列表'
      case 35:
        return '日志列表拉取 - 上传日志列表结果'
      case 36:
        return '日志拉取-拉取飞行器日志'
      case 37:
        return '日志拉取-拉取机场日志'
      case 38:
        return '日志拉取-压缩飞行器日志'
      case 39:
        return '日志拉取-压缩机场日志'
      case 40:
        return '日志拉取-上传飞行器日志'
      case 41:
        return '日志拉取-上传机场日志'
      case 42:
        return '日志拉取-通知结果'
      case 0xFFFD:
        return '结束后等待服务回包'
      case 0xFFFE:
        return '无具体状态'
      case 0xFFFFF:
        return 'UNKNOWN'
    }
  }
}
