import CoordinateUtil from '@/common/global/util/CoordinateUtil'
import MediaUtil from '@/common/global/util/MediaUtil'
import ObjUtil from '@/common/global/util/ObjUtil'
import RandomUtil from '@/common/global/util/RandomUtil'
import DomUtil from '@/common/global/util/DomUtil'
import RegexUtil from '@/common/global/util/RegexUtil'
import DateUtil from '@/common/global/util/DateUtil'
import ParseUtil from '@/common/global/util/ParseUtil'
import BrowserUtil from '@/common/global/util/BrowserUtil'
import MathUtil from '@/common/global/util/MathUtil'
import ZipUtil from '@/common/global/util/ZipUtil'
import FileUtil from '@/common/global/util/FileUtil'
import CanvasUtil from '@/common/global/util/CanvasUtil'
/*
* JLink工具类
* */
export default class JlinkUtils {
  // 对象相关操作
  static obj = new ObjUtil()
  // 随机数相关操作
  static random = new RandomUtil()
  // 解析相关操作
  static parse = new ParseUtil()
  // 媒体相关操作
  static media = new MediaUtil()
  // 坐标系相关操作
  static coordinate = new CoordinateUtil()
  // 节点相关操作
  static dom = new DomUtil()
  static browser = new BrowserUtil()
  static regex = new RegexUtil()
  static date = new DateUtil()
  static math = new MathUtil()
  static canvas = new CanvasUtil()
  static file = new FileUtil()
  static zip = new ZipUtil()
}
