import FlyBigemapHelper from '@/common/helper/map/FlyBigemapHelper'
import { getDroneListByPage } from '@/api/api.drone'
import { ref, Ref } from 'vue'
import JlinkTransform from '@/common/global/JlinkTransform'
import CesiumService from '@/components/cesium/CesiumService'
import JlinkMqtt2 from '@/common/global/JlinkMqtt2'
import { JOB_TYPE } from '@/common/enum/JLINK_ENUMS'

export default class MountedBigemapHelper extends FlyBigemapHelper {
  droneExecuteJobTypeSave = ref<StringKeyRecord<{ assignmentsId?: string, jobType: JOB_TYPE }>>({})
  private loadedSave = new Set<string>()
  private loaded = false

  async mapServiceLoaded (service: CesiumService) {
    await super.mapServiceLoaded(service)
    const _this = this
    const res = await getDroneListByPage({
      gatewayDomain: '3',
      page: 0,
      size: 100
    }).then(i => i.data.filter(item => JlinkMqtt2.getGatewayOnline(item)))
    for (const drone of res) {
      _this.watchSource(() => JlinkMqtt2.getReactiveDevice(drone).getDockFlightTaskProcessValue(), function (result) {
        if (result) {
          if (JlinkTransform.waylineJobTask.JobTaskStatusEnd(result.taskStatus)) {
            delete _this.droneExecuteJobTypeSave.value[result.gatewaySn]
          } else {
            _this.droneExecuteJobTypeSave.value[result.gatewaySn] = {
              assignmentsId: drone.assignmentsId,
              jobType: result.jobType
            }
          }
        }
      })
      this.watchSource(() => JlinkMqtt2.getReactiveDevice(drone).getDockOsdValue(), async function (value) {
        if (value) {
          await _this.addDockTrack(value).then()
          _this.loadedSave.add(value.gatewaySn)
          if (!_this.loaded) {
            if (_this.loadedSave.size === res.filter(item => JlinkMqtt2.getGatewayOnline(item)).length) {
              const dockEntities = await _this.getGatewayEntityAll()
              await _this.flyTo(dockEntities)
              _this.loaded = true
            }
          }
        } else {
          await _this.removeGatewayTrack(drone.gatewaySerialNumber)
        }
      })
    }
  }

  async destroy () {
    await super.destroy()
  }
}
