import JlinkUtils from '@/common/global/JlinkUtils'
import DockOsdTransform from '@/common/global/transform/DockOsdTransform'
import DockRemoteTransform from '@/common/global/transform/DockRemoteTransform'
import AircraftOsdTransform from '@/common/global/transform/AircraftOsdTransform'
import AssignmentsTransform from '@/common/global/transform/AssignmentsTransform'
import WaylineJobTaskTransform from '@/common/global/transform/WaylineJobTaskTransform'
import WaylineJobTransform from '@/common/global/transform/WaylineJobTransform'
import UserTransform from '@/common/global/transform/UserTransform'
import RcOsdTransform from '@/common/global/transform/RcOsdTransform'
/*
* JLink对象或者变量转化
* */
export default class JlinkTransform {
  static makeUploadFileWrapper (element: UploadFileVo) :UploadFileWrapperVo {
    const extType: 'image' | 'video' | 'unknown' = JlinkUtils.regex.isImage((element.fileName + '.' + element.fileExt).toLowerCase()) ? 'image' : JlinkUtils.regex.isVideo((element.fileName + '.' + element.fileExt).toLowerCase()) ? 'video' : 'unknown'
    return {
      ...element,
      extType,
      fileSizeFormat: element.fileSize.asByteSizeFormat(1),
      createTimeFormat: JlinkUtils.date.parseTime(element.createTime, '{y}-{m}-{d} {h}:{i}:{s}'),
      metadataFormat: element.metadata && JSON.parse(element.metadata),
      detectedFilesWrapper: element.detectedFiles?.map(di => {
        const metadate = di.metadata && JSON.parse(di.metadata)
        if (!di.fileExt.startsWith('.')) {
          di.fileExt = '.' + di.fileExt
        }
        return {
          ...di,
          fileSizeFormat: di.fileSize.asByteSizeFormat(1),
          metadataFormat: metadate,
          createTimeFormat: JlinkUtils.date.parseTime(metadate.created_time, '{y}-{m}-{d} {h}:{i}:{s}'),
          extType: JlinkUtils.regex.isImage(di.fileExt) ? 'image' : JlinkUtils.regex.isVideo(di.fileExt) ? 'video' : 'unknown',
        }
      })
    }
  }

  static makeDocumentWrapper (element: AssignmentsDocumentVo): DocumentWrapperVo {
    const extType: 'image' | 'video' | 'unknown' = JlinkUtils.regex.isImage(element.documentTitle + '.' + element.documentExt) ? 'image' : JlinkUtils.regex.isVideo(element.documentTitle + '.' + element.documentExt) ? 'video' : 'unknown'
    return {
      ...element,
      extType,
      fileSize: element.documentSize,
      fileSizeFormat: element.documentSize.asByteSizeFormat(1),
      createTimeFormat: JlinkUtils.date.parseTime(element.createTime, '{y}-{m}-{d} {h}:{i}:{s}'),
      fileObjectKey: element.documentUrl,
      from: (element.documentFrom === 0) ? '创建上传' : (element.documentFrom === 1) ? '编辑上传' : '云同步',
    }
  }

  static makeDocumentExt (documentExt:string) {
    let type = 'others'
    switch (documentExt.toLowerCase()) {
      case 'mp4':
        type = 'mp4'
        break
      case 'jpg':
      case 'png':
      case 'jpeg':
        type = 'jpg'
        break
      case 'jar':
      case 'rar':
      case 'zip':
        type = 'zip'
        break
      case 'pdf':
        type = 'pdf'
        break
      case 'ai':
        type = 'ai'
        break
      case 'docx':
      case 'doc':
        type = 'doc'
        break
      case 'ppt':
        type = 'ppt'
        break
      case 'xls':
        type = 'elx'
        break
      case 'mpp':
        type = 'mpp'
        break
      case 'psd':
        type = 'psd'
        break
      case 'txt':
        type = 'txt'
        break
      case 'vsd':
        type = 'vsd'
        break
    }
    return type
  }

  static assignments = new AssignmentsTransform()

  static dockOsd = new DockOsdTransform()

  static dockRemote = new DockRemoteTransform()

  static rcOsd = new RcOsdTransform()

  static aircraftOsd = new AircraftOsdTransform()

  static waylineJob = new WaylineJobTransform()

  static waylineJobTask = new WaylineJobTaskTransform()

  static user = new UserTransform()
}
