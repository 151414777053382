export default class BrowserUtil {
  userAgent (pattern: RegExp) {
    if (window.navigator) {
      return window.navigator.userAgent.match(pattern)
    } else {
      return false
    }
  }

  extractVersion (expr:RegExp, pos:number) {
    const match = window.navigator.userAgent.match(expr)
    if (match && match[pos]) {
      return parseInt(match[pos], 10)
    }
  }

  captureMode = {
    capture: false,
    passive: false
  }

  // isPad: window.matchMedia('(pointer: coarse)').matches,
  isMobile = this.userAgent(/Android|webOS|iPhone|iPad|BlackBerry/i)
  isAndroid = this.userAgent(/Android/i)
  devicePlatform = this.userAgent(/Android|webOS|iPhone|iPad|BlackBerry/i) ? 'mobile' : 'web'
  isIE11OrLess = this.userAgent(/(?:Trident.*rv[ :]?11\.|msie|iemobile|Windows Phone)/i)
  isEdge = this.userAgent(/Edge/i)
  isFireFox = this.userAgent(/firefox/i)
  isSafari = this.userAgent(/safari/i) && !this.userAgent(/chrome/i) && !this.userAgent(/android/i)
  isIos = this.userAgent(/iP(ad|od|hone)/i)
  isIPhone = this.userAgent(/iPhone|iPad/i)
  isChromeForAndroid = this.userAgent(/chrome/i) && this.userAgent(/android/i)
  isWeixin = this.userAgent(/WeChat|Weixin/i)

  // export function isChrome () {
  //   return window.navigator.userAgent.match('Chrome') !== null
  // }
  // export function isEdge () {
  //   return window.navigator.userAgent.match(/Edge\/(\d+).(\d+)$/) !== null
  // }

  detectBrowser () {
    // Returned result object.
    const result:{ browser?: 'chrome'|'firefox'|'edge'|'safari', version?: number } = { }
    // Fail early if it's not a browser
    if (typeof window === 'undefined' || !window.navigator) {
      return result
    }
    const { navigator } = window

    // @ts-ignore
    if (navigator.mozGetUserMedia) { // Firefox.
      result.browser = 'firefox'
      result.version = this.extractVersion(/Firefox\/(\d+)\./, 1)
      // @ts-ignore
    } else if (navigator.webkitGetUserMedia || (window.isSecureContext === false && window.webkitRTCPeerConnection && !window.RTCIceGatherer)) {
      // Chrome, Chromium, Webview, Opera.
      // Version matches Chrome/WebRTC version.
      // Chrome 74 removed webkitGetUserMedia on http as well so we need the
      // more complicated fallback to webkitRTCPeerConnection.
      result.browser = 'chrome'
      result.version = this.extractVersion(/Chrom(e|ium)\/(\d+)\./, 2)
    } else if (navigator.mediaDevices &&
      navigator.userAgent.match(/Edge\/(\d+).(\d+)$/)) { // Edge.
      result.browser = 'edge'
      result.version = this.extractVersion(/Edge\/(\d+).(\d+)$/, 2)
      // @ts-ignore
    } else if (window.RTCPeerConnection && navigator.userAgent.match(/AppleWebKit\/(\d+)\./)) { // Safari.
      result.browser = 'safari'
      result.version = this.extractVersion(/AppleWebKit\/(\d+)\./, 1)
      // result.supportsUnifiedPlan = window.RTCRtpTransceiver && 'currentDirection' in window.RTCRtpTransceiver.prototype
    }
    return result
  }

  on (el: EventTarget, event: string, fn: EventListenerOrEventListenerObject) {
    el.addEventListener(event, fn, !this.isIE11OrLess && this.captureMode)
  }

  off (el: EventTarget, event: string, fn: EventListenerOrEventListenerObject) {
    el.removeEventListener(event, fn, !this.isIE11OrLess && this.captureMode)
  }
}
