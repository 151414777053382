export default class RcOsdTransform {
  translateHms (i:HmsResult) {
    let style: 'hms-warning' | 'hms-remind' | 'hms-notice'
    switch (i.level) {
      case 2:
        style = 'hms-warning'
        break
      case 1:
        style = 'hms-remind'
        break
      case 0:
        style = 'hms-notice'
        break
      default:
        style = 'hms-remind'
        break
    }
    return { style, text: i.messageZh }
  }
}
