import { ModuleBase } from '@/common/dji/cloud/ModuleBase'
import { getMqtt } from '@/api/api.system'
import DjiCloudException from '@/common/errors/DjiCloudException'

declare let window: any

export class ModuleThing extends ModuleBase {
  async load () {
    if (!this.isLoaded()) {
      try {
        const mqtt = await getMqtt({ type: 'tcp' })
        console.log(JSON.stringify(mqtt), 'mqtt')
        this.loadComponent('thing', {
          host: mqtt.protocol + '://' + mqtt.host + ':' + mqtt.port,
          username: mqtt.username,
          password: mqtt.password,
          connectCallback: 'connectCallback'
        })
        this.initCallback()
      } catch (e) {
        this.unload()
        throw new DjiCloudException('加载thing模块失败', 100)
      }
    }
  }

  unload () {
    if (this.isLoaded()) {
      this.unloadComponent('thing')
    }
  }

  isLoaded () {
    return this.isComponentLoaded('thing') as boolean
  }

  // 获取连接状态：
  getConnectState () {
    return this.bridgeSetBlock<boolean>(() => window.djiBridge.thingGetConnectState())
  }

  // 启动连接：
  connect (username: string, password: string) {
    window.djiBridge.thingConnect(username, password, 'connectCallback')
    this.initCallback()
  }

  // 关闭连接：
  disconnect () {
    window.djiBridge.thingDisconnect()
  }

  // 设置回调函数：
  setConnectCallback (callback: string) {
    window.djiBridge.setConnectCallback(callback)
  }

  getConfigs () {
    return this.bridgeSetBlock<{
      host: string
      connectCallback: string
      username: string
      password: string
    }>(() => window.djiBridge.thingGetConfigs())
  }

  private initCallback () {
    window.connectCallback = (res: boolean) => {
      console.info('connectCallback', res)
      if (res) {
        // moduleMedia?.setMediaDownloadOwner(0);
        // moduleMedia?.setMediaUploadPhotoType(1);
      }
    }
  }
}
