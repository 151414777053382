import { Ref } from 'vue'
import EntityHelper from '@/components/cesium/entities/EntityHelper'
import CesiumService from '@/components/cesium/CesiumService'
import CesiumViewer from '@/components/cesium/CesiumViewer'
import CesiumCamera from '@/components/cesium/CesiumCamera'
import CesiumEntity from '@/components/cesium/CesiumEntity'
import CesiumScene from '@/components/cesium/CesiumScene'
import { Cartesian2, Cartesian3, Entity, HeadingPitchRange, Math, Rectangle } from 'cesium'
import CesiumException from '@/components/cesium/CesiumException'
import JlinkTask from '@/common/global/JlinkTask'
import CesiumClock from '@/components/cesium/CesiumClock'
import CesiumInstance from '@/components/cesium/CesiumInstance'
import BaseHelper from '@/common/helper/BaseHelper'
export default abstract class BigemapHelper extends BaseHelper {
  // 机场或者遥控模型
  protected gatewayGlb = () => require('@/assets/model/h.glb').default
  protected gatewayGlbOptions = {
    rotate: 90,
    pitch: 0,
    maxScale: 0.24,
    minScale: 0.2,
    minSize: 48,
    baseLightFactor: { x: 1, y: 1 }
  }

  // 机场或者遥控备降点模型
  protected gatewayLandGlb = () => require('@/assets/model/p.glb').default
  protected gatewayLandGlbOptions = {
    rotate: 90,
    pitch: 0,
    maxScale: 0.24,
    minScale: 0.2,
    minSize: 40,
    baseLightFactor: { x: 1, y: 1 }
  }

  // 航线兴趣点模型
  protected poiPointGlb = () => require('@/assets/model/ding.glb').default
  protected poiPointGlbOptions = {
    rotate: 90,
    pitch: 90,
    maxScale: 2,
    minScale: 1.6,
    minSize: 32,
    baseLightFactor: { x: 0.1, y: 0.1 }
  }

  // 飞行器模型
  protected aircraftGlb = () => require('@/assets/model/gg.glb').default
  protected aircraftGlbOptions = {
    rotate: -90,
    pitch: 180,
    maxScale: 0.18,
    minScale: 0.16,
    minSize: 32,
    baseLightFactor: { x: 1, y: 1 }
  }

  protected aircraftBlueGlb = () => require('@/assets/model/as.glb').default
  protected aircraftBlueGlbOptions = {
    rotate: -90,
    pitch: 180,
    maxScale: 0.2,
    minScale: 0.18,
    minSize: 32,
    baseLightFactor: { x: 1, y: 1 }
  }

  // 航点模型
  protected pointGlb = () => require('@/assets/model/ding.glb').default
  protected pointGlbOptions = {
    rotate: 90,
    pitch: 90,
    maxScale: 0.2,
    minScale: 0.18,
    minSize: 48,
    baseLightFactor: { x: 1, y: 1 }
  }

  protected map: CesiumService | undefined
  protected cameraMaker: CesiumCamera | undefined
  protected viewerMaker: CesiumViewer | undefined
  protected entityMaker: CesiumEntity | undefined
  protected sceneMaker: CesiumScene | undefined
  protected clockMarker: CesiumClock | undefined

  constructor (map: Ref<{ mapService: () => Promise<CesiumService> | CesiumService } | undefined>) {
    super()
    const _this = this
    this.watchSource(() => map.value?.mapService, async function (m) {
      if (m) {
        _this.map?.destroy()
        _this.map = await m()
        _this.cameraMaker = _this.map.camera
        _this.viewerMaker = _this.map.viewer
        _this.entityMaker = _this.map.entity
        _this.sceneMaker = _this.map.scene
        _this.clockMarker = _this.map.clock
        _this.mapServiceLoaded(_this.map)
      }
    }, { immediate: true })
  }

  abstract mapServiceLoaded(service: CesiumService):void

  protected async getMapService () {
    return JlinkTask.asyncGet(() => this.map)
  }

  protected async getClock () {
    return JlinkTask.asyncGet(() => this.clockMarker)
  }

  protected async getViewer () {
    return JlinkTask.asyncGet(() => this.viewerMaker)
  }

  protected async getScene () {
    return JlinkTask.asyncGet(() => this.sceneMaker)
  }

  protected async getCamera () {
    return JlinkTask.asyncGet(() => this.cameraMaker)
  }

  protected async getEntity () {
    return JlinkTask.asyncGet(() => this.entityMaker)
  }

  async setDoubleClick (callback: (e: JlinkLocation) => void, crossAble: boolean) {
    const viewer = await this.getViewer()
    const sceneMaker = await this.getScene()
    const _this = this
    viewer.setEvents('LEFT_DOUBLE_CLICK', async function (e) {
      const primitive = sceneMaker.pick(e.position)
      // 如果未点击到primitive对象或者允许点击穿透
      if (!primitive || crossAble) {
        const pp = await _this.pickBigemapLocation(e.position)
        callback(pp)
      }
    })
  }

  async setLongPress (callback: (e: JlinkLocation) => void, crossAble: boolean) {
    const viewer = await this.getViewer()
    const sceneMaker = await this.getScene()
    const _this = this
    viewer.setEvents('LONG_PRESS', async function (e) {
      const primitive = sceneMaker.pick(e)
      // 如果未点击到primitive对象或者允许点击穿透
      if (!primitive || crossAble) {
        const pp = await _this.pickBigemapLocation(e)
        callback(pp)
      }
    })
  }

  private dragEntityId?: string

  async setEntityDragStart (callback: (id: string) => void) {
    const viewer = await this.getViewer()
    const sceneMaker = await this.getScene()
    const _this = this
    viewer.setEvents('LONG_PRESS', function (e) {
      const primitive = sceneMaker.pick(e)
      if (primitive?.id?.helper) {
        _this.dragEntityId = primitive.id.id
        // eslint-disable-next-line n/no-callback-literal
        callback(_this.dragEntityId!)
      }
    })
  }

  // async setEvents<T extends keyof ScreenSpaceEventTypeHandlerECustom> (type: T, callback: ScreenSpaceEventTypeHandlerECustom[T]) {
  //   constant viewerMaker = await this.getViewer()
  //   await viewerMaker.setEvents(type, callback)
  // }

  async setEntityDragEnd (callback: (id: string) => void) {
    const viewer = await this.getViewer()
    const _this = this
    viewer.setEvents('LEFT_UP', function () {
      if (_this.dragEntityId) {
        // eslint-disable-next-line n/no-callback-literal
        callback(_this.dragEntityId!)
        delete _this.dragEntityId
      }
    })
  }

  async setEntityDragMove (callback: (id: string, p: JlinkLocation) => void) {
    const viewer = await this.getViewer()
    const _this = this
    viewer.setEvents('MOUSE_MOVE', async function (e) {
      if (_this.dragEntityId) {
        const pp = await _this.pickBigemapLocation(e.startPosition)
        // eslint-disable-next-line n/no-callback-literal
        callback(_this.dragEntityId!, pp)
      }
    })
  }

  async setEntityPickLeftClick (callback: (bigemapLocation: JlinkLocation, e?: EntityHelper) => void) {
    const viewer = await this.getViewer()
    const sceneMaker = await this.getScene()
    const _this = this
    viewer.setEvents('LEFT_CLICK', async function (e) {
      const position = await _this.pickBigemapLocation(e.position)
      const primitive = sceneMaker.pick(e.position)
      callback(position, primitive?.id?.helper)
    })
  }

  async setEntityPickRightClick (callback: (bigemapLocation: JlinkLocation, e?: EntityHelper) => void) {
    const viewer = await this.getViewer()
    const sceneMaker = await this.getScene()
    const _this = this
    viewer.setEvents('RIGHT_CLICK', async function (e) {
      const position = await _this.pickBigemapLocation(e.position)
      const primitive = sceneMaker.pick(e.position)
      callback(position, primitive?.id?.helper)
    })
  }

  async setDragEnd (callback: (position: JlinkLocation) => void) {
    const viewer = await this.getViewer()
    const _this = this
    viewer.setEvents('DRAG_END', async function (e) {
      // eslint-disable-next-line n/no-callback-literal
      callback(await _this.pickBigemapLocation(e))
    })
  }

  async setLeftClick (callback: (bigemapLocation: JlinkLocation) => void) {
    const viewer = await this.getViewer()
    const _this = this
    viewer.setEvents('LEFT_CLICK', async function (e) {
      console.log(e.position)
      const position = await _this.pickBigemapLocation(e.position)
      callback(position)
    })
  }

  async setRightClick (callback: (bigemapLocation: JlinkLocation) => void) {
    const viewer = await this.getViewer()
    const _this = this
    viewer.setEvents('RIGHT_CLICK', async function (e) {
      const position = await _this.pickBigemapLocation(e.position)
      callback(position)
    })
  }

  private async pickBigemapLocation (cartesian2: Cartesian2): Promise<JlinkLocation> {
    const camera = await this.getCamera()
    const scene = await this.getScene()
    // 通过指定的椭球或者地图对应的坐标系，将鼠标的二维坐标转换为对应椭球体三维坐标
    const ray = camera.getPickRay(cartesian2)
    const cartesian3 = scene.globePick(ray)
    return CesiumInstance.toBigemapLocationFromCartesian3(cartesian3)
  }

  async computeDistance (start: JlinkLocation, end: JlinkLocation) {
    return CesiumInstance.computeDistanceBetweenCartographic(start, end)
  }

  async getBetweenCenter (start: JlinkLocation, end: JlinkLocation): Promise<JlinkLocation> {
    const middle = CesiumInstance.getMidpointBetweenCartographic(start, end)
    return {
      lng: CesiumInstance.toDegreesFromRadians(middle.longitude),
      lat: CesiumInstance.toDegreesFromRadians(middle.latitude),
      height: middle.height
    }
  }

  async flyToEntities (options?: { duration?: number, maximumHeight?: number, offset?: HeadingPitchRange }) {
    const viewerMaker = await this.getViewer()
    await viewerMaker.flyToEntities(options)
  }

  async zoomToEntities (offset?: HeadingPitchRange) {
    const viewerMaker = await this.getViewer()
    await viewerMaker.zoomToEntities(offset)
  }

  async flyTo (target: Entity[] | EntityHelper[], options?: {
    duration?: number,
    maximumHeight?: number,
    offset?: HeadingPitchRange
  }) {
    const viewerMaker = await this.getViewer()
    const values = target.map(e => {
      if (e instanceof EntityHelper) {
        return e.entity
      } else {
        return e
      }
    })
    await viewerMaker.flyTo(values, options)
  }

  async flyToById (target: string [], options?: { duration?: number, maximumHeight?: number, offset?: HeadingPitchRange }) {
    const viewerMaker = await this.getViewer()
    console.log('地图缩放9', target)
    await viewerMaker.flyToById(target, options)
  }

  async zoomTo (target: Entity[], offset?: HeadingPitchRange) {
    const viewerMaker = await this.getViewer()
    console.log('地图缩放7')
    await viewerMaker.zoomTo(target, offset)
  }

  async flyToPosition (center: JlinkLocation, options?: {
    duration?: number
    maximumHeight?: number
    heading?: number
    pitch?: number
    roll?: number
    range?: number
  }) {
    const camera = await this.getCamera()
    await camera.flyTo(center, options)
  }

  center (positions: JlinkLocation[]) {
    const rectangle = Rectangle.fromCartesianArray(positions.map(p => {
      if (p.lng === null || p.lng === undefined) {
        throw new CesiumException('location lng is lost')
      }
      if (p.lat === null || p.lat === undefined) {
        throw new CesiumException('location lat is lost')
      }
      if (p.height === null || p.height === undefined) {
        throw new CesiumException('location height is lost')
      }
      return Cartesian3.fromDegrees(p.lng, p.lat, p.height)
    }))
    const c = Rectangle.center(rectangle)
    // 将笛卡尔坐标转换为地理坐标
    // 将弧度转为度的十进制度表示
    const longitudeString = Math.toDegrees(c.longitude)
    const latitudeString = Math.toDegrees(c.latitude)
    // constant heightString = this.radiansToDegrees(cartographic.height)
    // console.log(this.sceneMaker?.globeHeight(cartographic))
    return { lng: longitudeString.toFixedCustom(9), lat: latitudeString.toFixedCustom(9), height: c.height }
  }
}
