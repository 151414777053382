import {mittLoading} from '@/common/mitt/mitt'
import {
  ElLoading,
  ElMessage,
  ElMessageBox,
  ElMessageBoxOptions,
  ElNotification,
  LoadingOptions,
  MessageBoxData,
  MessageParams,
  NotificationOptions
} from 'element-plus'
import {onBeforeMount, onMounted, Ref} from 'vue'
import _ from 'lodash'
/*
* JLinkUi控制
* */
export default class JlinkUi {
  static baseConfirm <T extends ElMessageBoxOptions> (content: string, title: string = '', options?: T): Promise<MessageBoxData> {
    return ElMessageBox.confirm(content, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      showCancelButton: true,
      closeOnClickModal: false,
      showClose: false,
      type: 'warning',
      ...options
    }).then(res => {
      console.log(res)
      if (typeof res === 'string') {
        return {
          value: '',
          action: res
        }
      } else {
        return res
      }
    })
      .catch(e => {
        console.log(e)

        if (typeof e === 'string') {
          return {
            value: '',
            action: e
          }
        } else {
          return e
        }
      })
  }

  static baseNotify (title: string, message: string, options?: NotificationOptions) {
    if (message) {
      ElNotification({
        title,
        message,
        ...options
      })
    } else {
      console.warn('notify message is empty')
    }
  }

  //! * 全局Alert *!//
  static baseAlert (content: string, title: string, options?: ElMessageBoxOptions): Promise<MessageBoxData> {
    return ElMessageBox.alert(content, title || '温馨提示', {
      confirmButtonText: '确定',
      dangerouslyUseHTMLString: true,
      ...options
    })
  }

  //! * 全局Message *!//
  static baseMessage (message: string, options?: MessageParams) {
    if (message) {
      ElMessage({
        offset: 60,
        message,
        // @ts-ignore
        ...options
      })
    } else {
      console.warn('message is empty')
    }
  }

  //! * 全局加载层 *!//
  static async baseLoading<T> (promise: Promise<T>, options?: LoadingOptions): Promise<T> {
    // startLoading(options);

    const loading = this.startLoading({
      lock: true,
      fullscreen: false,
      background: '#00000000',
      ...options
    })

    mittLoading(true)
    try {
      const result = await promise
      console.log('loadingend')
      mittLoading(false)
      loading.close()
      return result
    } catch (e) {
      mittLoading(false)
      console.log('loadingend')
      loading.close()

      throw e
    }
  }

  static startLoading (options?: LoadingOptions) {
    return ElLoading.service({
      lock: true,
      fullscreen: false,
      background: '#00000000',
      ...options
    })
  }

  // 只能在组件中使用不然生命周期异常
  static resizeObserver (element: Ref<HTMLElement|undefined|null>, cb: (e:HTMLElement) => void, throttleDelay?:number) {
    function register (e: Ref<HTMLElement|undefined|null>, cb: (e:HTMLElement) => void, throttleDelay?:number) {
      let resizeObserver: ResizeObserver | undefined
      onMounted(() => {
        const h = e.value
        if (!resizeObserver) {
          if (h) {
            if (throttleDelay) {
              resizeObserver = new ResizeObserver(_.throttle(function () {
                cb(h)
              }, throttleDelay))
            } else {
              resizeObserver = new ResizeObserver(function () {
                cb(h)
              })
            }
            resizeObserver.observe(h)
          } else {
            console.error('element is lost')
          }
        }
      })
      onBeforeMount(() => {
        if (resizeObserver) {
          resizeObserver.disconnect()
          resizeObserver = undefined
        }
      })
    }
    register(element, cb, throttleDelay)
  }
}
