export default class MediaUtil {
  changeAudioSampleRate (file: File, targetSampleRate: number, name: string) {
    return new Promise<File>((resolve, reject) => {
      // @ts-ignore
      const audioContext = new (window.AudioContext || window.webkitAudioContext)()
      const reader = new FileReader()
      reader.onload = function (e) {
        // 解码音频数据
        // @ts-ignore
        audioContext.decodeAudioData(e.target.result, async function (buffer) {
          // 计算采样率的变化比例
          const ratio = targetSampleRate / buffer.sampleRate
          // 创建一个新的AudioBuffer
          const newBuffer = audioContext.createBuffer(buffer.numberOfChannels, buffer.length * ratio, targetSampleRate)
          // 复制并修改采样率
          for (let channel = 0; channel < buffer.numberOfChannels; channel++) {
            const channelData = buffer.getChannelData(channel)
            const newChannelData = newBuffer.getChannelData(channel)
            for (let i = 0; i < newBuffer.length; i++) {
              newChannelData[i] = channelData[Math.floor(i / ratio)]
            }
          }
          const newAudioBuffer = audioContext.createBuffer(newBuffer.numberOfChannels, newBuffer.length, newBuffer.sampleRate)
          for (let channel = 0; channel < newBuffer.numberOfChannels; channel++) {
            const oldData = newBuffer.getChannelData(channel)
            const newData = newAudioBuffer.getChannelData(channel)
            newData.set(oldData)
          }

          function bufferToWave (abuffer: AudioBuffer, len: number) {
            const numOfChan = abuffer.numberOfChannels
            const length = len * numOfChan * 2 + 44
            const buffer = new ArrayBuffer(length)
            const view = new DataView(buffer)
            const channels = []
            let i
            let sample
            let offset = 0
            let pos = 0
            setUint32(0x46464952) // "RIFF"
            setUint32(length - 8) // file length - 8
            setUint32(0x45564157) // "WAVE"
            setUint32(0x20746d66) // "fmt " chunk
            setUint32(16) // length = 16
            setUint16(1) // PCM (uncompressed)
            setUint16(numOfChan)
            setUint32(abuffer.sampleRate)
            setUint32(abuffer.sampleRate * 2 * numOfChan) // avg. bytes/sec
            setUint16(numOfChan * 2) // block-align
            setUint16(16) // 16-bit (hardcoded in this demo)
            setUint32(0x61746164) // "data" - chunk
            setUint32(length - pos - 4) // chunk length
            for (i = 0; i < abuffer.numberOfChannels; i++) {
              channels.push(abuffer.getChannelData(i))
            }
            while (pos < length) {
              for (i = 0; i < numOfChan; i++) { // interleave channels
                sample = Math.max(-1, Math.min(1, channels[i][offset])) // clamp to [-1,1]
                sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0 // scale to 16-bit signed int
                view.setInt16(pos, sample, true) // write 16-bit sample
                pos += 2
              }
              offset++// next source sample
            }
            return new Blob([buffer], { type: 'audio/wav' })

            function setUint16 (data: number) {
              view.setUint16(pos, data, true)
              pos += 2
            }

            function setUint32 (data: number) {
              view.setUint32(pos, data, true)
              pos += 4
            }
          }

          const blob = bufferToWave(newBuffer, newBuffer.length)

          function getSampleRateFromBlob (blob: Blob) {
            return new Promise((resolve, reject) => {
              const audioContext = new AudioContext()
              const fileReader = new FileReader()

              fileReader.onload = function () {
                const arrayBuffer = this.result
                // @ts-ignore
                audioContext.decodeAudioData(arrayBuffer, function (decodedData) {
                  resolve(decodedData.sampleRate)
                }, function (err) {
                  reject(err)
                })
              }

              fileReader.readAsArrayBuffer(blob)
            })
          }

          getSampleRateFromBlob(blob)
            .then(sampleRate => {
              console.log('采样率:', sampleRate)
            })
            .catch(error => {
              console.error('获取采样率时出错:', error)
              reject(error)
            })
          const url = URL.createObjectURL(blob)
          console.log(url)
          const file = new File([blob], name, { type: 'audio/wav' })
          resolve(file)
        })
      }
      reader.readAsArrayBuffer(file)
    })
  }

  // 获取视频文件的封面
  getVideoPoster (url: string): Promise<{ url: string; widthBg: number; heightBg: number }> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video')
      video.setAttribute('crossOrigin', 'Anonymous')
      video.src = url
      // 如果不设置currentTime，画出来的图片是空的
      video.currentTime = 0.001
      video.onloadeddata = (e: any) => {
        const canvas = document.createElement('canvas')
        const vWidth = e.path[0].videoWidth
        const vHeight = e.path[0].videoHeight
        // 判断视频的高度和宽度
        // 画布的大小，由设置的视频显示的大小决定，以免画布太大，画的图片太大，浪费资源，这里设置显示视频盒子为width:140px，height:140px;
        // 设置倍数是rate，倍数越大画图的图片越大，加载速度越慢
        // https://www.w3school.com.cn/html5/canvas_drawimage.asp
        canvas.width = 140 * 4
        canvas.height = vHeight * 140 / vWidth * 4
        const c2d = canvas.getContext('2d')
        if (c2d) {
          c2d.drawImage(video, 0, 0, canvas.width, canvas.height)
          const url = canvas.toDataURL('device/png', 1)
          // 显示背景图片的大小，保证在不压缩图片的情况下，背景图片能铺满
          let widthBg, heightBg
          if (vWidth < vHeight) {
            widthBg = 140
            heightBg = vHeight * 140 / vWidth
          } else {
            widthBg = vWidth * 140 / vHeight
            heightBg = 140
          }
          resolve({ url, widthBg, heightBg })
        } else {
          reject(new Error('canvas.getContext(\'2d\') fail'))
        }
      }
    })
  }

  // 播放tts语音
  startTtsSpeech (options: {
    text: string
    pitch?: number
    rate?: number
    volume?: number
  }) {
    return new Promise(function (resolve, reject) {
      // 创建一个新的SpeechSynthesis对象
      const speech = window.speechSynthesis
      // 播放中取消其它播放
      if (speech.speaking) {
        reject(new Error('speech is speaking'))
        return
      }
      // 创建一个SpeechSynthesisUtterance对象
      const utterance = new SpeechSynthesisUtterance()
      // 设置要说的文本
      utterance.text = options.text
      // 你可以设置语言，音调，音速，音量等
      utterance.lang = 'zh-CN'
      utterance.pitch = options?.pitch ? options.pitch >= 0 && options.pitch <= 2 ? options.pitch : 1 : 1
      utterance.rate = options?.pitch ? options.pitch >= 0.1 && options.pitch <= 10 ? options.pitch : 1 : 1
      utterance.volume = options?.pitch ? options.pitch >= 0 && options.pitch <= 1 ? options.pitch : 1 : 1
      utterance.addEventListener('error', function (e) {
        reject(new Error(e.error))
      })
      utterance.addEventListener('end', function (e) {
        resolve('success')
      })
      speech.speak(utterance)
    })
  }
}
