import { COORDINATE } from '@/common/enum/JLINK_ENUMS'

export default class CoordinateUtil {
  x_PI = Math.PI * 3000.0 / 180.0
  PI = Math.PI
  a = 6378245.0
  // eslint-disable-next-line no-loss-of-precision
  ee = 0.00669342162296594323
  chinaLngRange = { max: 137.8347, min: 72.004 }
  chinaLatRange = { max: 55.8271, min: 0.8293 }

  coordinateTransform (data: JlinkLocation, fromCoordinate: COORDINATE, toCoordinate: COORDINATE): JlinkLocation {
    if (fromCoordinate === COORDINATE.WGS84) {
      if (toCoordinate === COORDINATE.WGS84) {
        return data
      } else if (toCoordinate === COORDINATE.GCJ02) {
        const res = this.wgs84togcj02(data.lng, data.lat)
        return { lng: res[0], lat: res[1], height: data.height }
      } else {
        throw Error(`地图坐标系转换目标暂不支持 from:${fromCoordinate} to:${toCoordinate}}`)
      }
    }
    if (fromCoordinate === COORDINATE.GCJ02) {
      if (toCoordinate === COORDINATE.GCJ02) {
        return data
      } else if (toCoordinate === COORDINATE.WGS84) {
        const res = this.gcj02towgs84(data.lng, data.lat)
        return { lng: res[0], lat: res[1], height: data.height }
      } else {
        throw Error(`地图坐标系转换目标暂不支持 from:${fromCoordinate} to:${toCoordinate}}`)
      }
    }
    if (fromCoordinate === COORDINATE.BD09) {
      if (toCoordinate === COORDINATE.BD09) {
        return data
      } else if (toCoordinate === COORDINATE.WGS84) {
        throw Error(`地图坐标系转换目标暂不支持 from:${fromCoordinate} to:${toCoordinate}}`)
      } else {
        throw Error(`地图坐标系转换目标暂不支持 from:${fromCoordinate} to:${toCoordinate}}`)
      }
    }

    throw Error(`地图坐标系转换目标暂不支持 from:${fromCoordinate} to:${toCoordinate}}`)
  }

  /**
   * WGS84 to Mars coordinate system GCj02
   * @param lng
   * @param lat
   * @returns {*[]}
   */
  wgs84togcj02 (lng: number, lat: number) {
    if (this.out_of_china(lng, lat)) {
      return [lng, lat]
    } else {
      let dlat = this.transformlat(lng - 105.0, lat - 35.0)
      let dlng = this.transformlng(lng - 105.0, lat - 35.0)
      const radlat = lat / 180.0 * this.PI
      let magic = Math.sin(radlat)
      magic = 1 - this.ee * magic * magic
      const sqrtmagic = Math.sqrt(magic)
      dlat = (dlat * 180.0) / ((this.a * (1 - this.ee)) / (magic * sqrtmagic) * this.PI)
      dlng = (dlng * 180.0) / (this.a / sqrtmagic * Math.cos(radlat) * this.PI)
      const mglat = lat + dlat
      const mglng = lng + dlng
      return [mglng, mglat]
    }
  }

  /**
   * GCJ02 transform WGS84
   * @param lng
   * @param lat
   * @returns {*[]}
   */
  gcj02towgs84 (lng: number, lat: number) {
    // var lat = +lat
    // var lng = +lng
    if (this.out_of_china(lng, lat)) {
      return [lng, lat]
    } else {
      let dlat = this.transformlat(lng - 105.0, lat - 35.0)
      let dlng = this.transformlng(lng - 105.0, lat - 35.0)
      const radlat = lat / 180.0 * this.PI
      let magic = Math.sin(radlat)
      magic = 1 - this.ee * magic * magic
      const sqrtmagic = Math.sqrt(magic)
      dlat = (dlat * 180.0) / ((this.a * (1 - this.ee)) / (magic * sqrtmagic) * this.PI)
      dlng = (dlng * 180.0) / (this.a / sqrtmagic * Math.cos(radlat) * this.PI)
      const mglat = lat + dlat
      const mglng = lng + dlng
      return [lng * 2 - mglng, lat * 2 - mglat]
    }
  }

  transformlat (lng: number, lat: number) {
    let ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng))
    ret += (20.0 * Math.sin(6.0 * lng * this.PI) + 20.0 * Math.sin(2.0 * lng * this.PI)) * 2.0 / 3.0
    ret += (20.0 * Math.sin(lat * this.PI) + 40.0 * Math.sin(lat / 3.0 * this.PI)) * 2.0 / 3.0
    ret += (160.0 * Math.sin(lat / 12.0 * this.PI) + 320 * Math.sin(lat * this.PI / 30.0)) * 2.0 / 3.0
    return ret
  }

  transformlng (lng: number, lat: number) {
    let ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
    ret += (20.0 * Math.sin(6.0 * lng * this.PI) + 20.0 * Math.sin(2.0 * lng * this.PI)) * 2.0 / 3.0
    ret += (20.0 * Math.sin(lng * this.PI) + 40.0 * Math.sin(lng / 3.0 * this.PI)) * 2.0 / 3.0
    ret += (150.0 * Math.sin(lng / 12.0 * this.PI) + 300.0 * Math.sin(lng / 30.0 * this.PI)) * 2.0 / 3.0
    return ret
  }

  /**
   * Judge whether you are in the country or not if you are not in the country
   * @param lng
   * @param lat
   * @returns {boolean}
   */
  out_of_china (lng: number, lat: number) {
    return this.out_of_china_lat(lat) || this.out_of_china_lng(lng)
  }

  out_of_china_lng (lng: number) {
    return (lng < this.chinaLngRange.min || lng > this.chinaLngRange.max)
  }

  out_of_china_lat (lat: number) {
    return (lat < this.chinaLatRange.min || lat > this.chinaLatRange.max)
  }
}
