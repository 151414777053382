import { ExceptionEnum } from '@/common/errors/ExceptionEnum'

export default class AlsException extends Error {
  readonly code: number
  readonly type: ExceptionEnum
  constructor (message: string, code: number, type: ExceptionEnum) {
    super(message)
    this.code = code
    this.type = type
  }
}
