export enum COORDINATE {
  WGS84 = 0,
  BD09 = 1,
  GCJ02 = 2
}

export enum JOB_STATUS {
  PENDING = 0,
  PUBLISH = 1,
  UNKNOWN = -1,
}

export enum JOB_TYPE {
  NORMAL = 0,
  EMERGENCY = 1,
  TAKEOFF = 2,
}

export enum USER_GENDER {
  MALE = 1,
  FEMALE = 0,
  UNKNOWN = -1
}
export enum USER_STATUS {
  ACTIVE=0,
  UN_ACTIVE=1,
  UNUSUAL=2
}

export enum ASSIGNMENTS_STATUS {
  PREPARE = 0,
  DOING = 1,
  COMPLETE = 2,
}

export enum ASSIGNMENTS_TYPE {
  TEMP = 0,
  RESERVATION = 1,
  QUICK = 2,
  DOCK = 3
}

export enum FLIGHT_TASK_STATUS {
  // 错误
  ERROR = 0,
  // 已下发
  SENT = 1,
  // 执行中
  IN_PROGRESS = 2,
  // 暂停
  PAUSED = 3,
  // 拒绝
  REJECTED = 4,
  // failed
  FAILED = 5,
  // 取消或终止
  CANCELED = 6,
  // 超时
  TIMEOUT = 7,
  // 部分完成
  PARTIALLY_DONE = 8,
  // 执行成功
  OK = 9,
  // 未知状态
  UNKNOWN = -1,

  BREAK_CONTINUE = 21,
  /* 一键起飞任务 */
  TASK_READY = 51,
  WAYLINE_PROGRESS = 52,
  WAYLINE_FAILED = 53,
  WAYLINE_OK = 54,
  WAYLINE_CANCEL = 55,
  TASK_FINISH = 56,

}

export enum PSDK_PLAY_FILE_FROM {
  UNKNOWN = -1,
  RECORD = 0,
  UPLOAD = 1,
  TTS = 2
}

export enum JOB_EXPRESSION_TYPE {


    PENDING=0, // 立即执行

    TIMER=1, // 定时执行

    CIRCLE=2// 循环执行
}


