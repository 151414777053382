import JlinkUi from '@/common/global/JlinkUi'
import { AxiosProgressEvent } from 'axios'
import JlinkWorkerAxios from '@/common/workers/JlinkWorkerAxios'
import { DjiCloudUserModule } from '@/store/DjicloudUser'
import { UserModule } from '@/store/User'
import DataException from '@/common/errors/DataException'
import JlinkUtils from '@/common/global/JlinkUtils'
import { toRaw } from 'vue'
import JlinkAxiosRequest from '@/common/workers/components/JlinkAxiosRequest'

export default class JlinkRequest {
  private static timeOut = 30000
  private static axiosThread: JlinkWorkerAxios | undefined
  private static myRequest : JlinkAxiosRequest|undefined
  private static makeObj (param:any) {
    const _this = this
    if (param) {
      Object.keys(param).forEach(item => {
        if (item === 'jlinkBlobCovertFlag') {
          throw new DataException('jlinkBlobCovertFlag 是 JlinkWorkerAxios的内置字段，无法当做参数的key值')
        }
        const value = param[item]
        if (value instanceof File) {
          param[item] = { jlinkBlobCovertFlag: true, filename: value.name, blob: new Blob([value]) }
        } else if (typeof value === 'object') {
          _this.makeObj(value)
        }
      })
    }
  }

  static async request<T extends RequestAndResponse> (url: T['url'], method: T['method'], param: T['Request'], showLoading: ((e: AxiosProgressEvent) => void) | boolean = true, onUploadProgress?: (e: AxiosProgressEvent) => void, onDownloadProgress?: (e: AxiosProgressEvent) => void): Promise<T['Response']> {
    const bid = JlinkUtils.random.uuid(8)
    const headers = {
      token: window.djiBridge ? DjiCloudUserModule.data.user?.userToken : UserModule.user.userToken,
      provider: window.djiBridge ? 'djicloud' : 'web'
    }
    let promise:Promise<T['Response']>
    const data = toRaw(param)
    this.makeObj(data)
    if (typeof Worker !== 'undefined') {
      if (!this.axiosThread?.working) {
        this.axiosThread = new JlinkWorkerAxios({ baseURL: process.env.VUE_APP_BASE_API, timeout: this.timeOut })
      }
      promise = this.axiosThread.request(bid, url, method, data, headers, onUploadProgress, onDownloadProgress)
    } else {
      if (!this.myRequest) {
        this.myRequest = new JlinkAxiosRequest({ baseURL: process.env.VUE_APP_BASE_API, timeout: this.timeOut })
      }
      promise = this.myRequest.action(bid, url, method, data, headers, onUploadProgress, onDownloadProgress)
    }
    if (showLoading) {
      return JlinkUi.baseLoading(promise)
    } else {
      return promise
    }
  }
}
