import { ref } from 'vue'
import BaseHelper from '@/common/helper/BaseHelper'
import { MAP_COORDINATE } from '@/common/global/JlinkValues'
import JlinkStorage from '@/common/global/JlinkStorage'
import JlinkSingleGet from '@/common/annotate/JlinkSingleGet'
import JlinkThrottle from '@/common/annotate/JlinkThrottle'
import JlinkUtils from '@/common/global/JlinkUtils'
import CesiumService from '@/components/cesium/CesiumService'
import { Cartesian2, Color, Math as Math2, Rectangle } from 'cesium'

export default class FlyZoneHelper extends BaseHelper {
  private flyZone = ref<FlyZoneData[]>([])
  constructor () {
    super()
    const _this = this
    this.loadZone()
      .then((res) => {
        _this.flyZone.value = res
      })
  }

  @JlinkSingleGet<FlyZoneData[]>([])
  async loadZone (): Promise<FlyZoneData[]> {
    try {
      const zjAreaRange = {
        sw: { lat: 27.139635917, lng: 118.000000000, height: 0 },
        ne: { lat: 31.200000000, lng: 122.942072614, height: 0 }
      }
      zjAreaRange.sw = JlinkUtils.coordinate.coordinateTransform(zjAreaRange.sw, 2, MAP_COORDINATE)
      zjAreaRange.ne = JlinkUtils.coordinate.coordinateTransform(zjAreaRange.ne, 2, MAP_COORDINATE)
      // 兼容老数据
      // @ts-ignore
      JlinkStorage.remove('zoneData')
      const saveValues = JlinkStorage.get('zoneData2')
      if (saveValues?.coordinate === MAP_COORDINATE && saveValues.values) {
        return saveValues.values
      } else {
        const response = await fetch('https://senen-public.oss-cn-hangzhou.aliyuncs.com/test/%E5%85%A8%E5%9B%BD%E7%A6%81%E9%A3%9E%E5%8C%BA.json')
        const dataJson = await response.json() as KmlDataValue[]
        const flyZoneDataValue: FlyZoneData[] = []
        for (const data of dataJson) {
          let n = 0
          let e = 0
          let s = 0
          let w = 0
          let loopSuccess = true
          for (const geometry of data.geometry) {
            // 如果区域不在浙江范围则退出遍历
            if (geometry.lat > zjAreaRange.ne.lat || geometry.lat < zjAreaRange.sw.lat) {
              loopSuccess = false
              break
            }
            if (geometry.lng > zjAreaRange.ne.lng || geometry.lng < zjAreaRange.sw.lng) {
              loopSuccess = false
              break
            }
            if (n === 0) {
              n = geometry.lat
            } else {
              n = Math.max(geometry.lat, n)
            }
            if (s === 0) {
              s = geometry.lat
            } else {
              s = Math.min(geometry.lat, s)
            }

            if (e === 0) {
              e = geometry.lng
            } else {
              e = Math.max(geometry.lng, e)
            }
            if (w === 0) {
              w = geometry.lng
            } else {
              w = Math.min(geometry.lng, w)
            }
          }
          if (loopSuccess) {
            flyZoneDataValue.push({
              geometry: data.geometry.map(i => {
                return { ...i, height: i.height || 0 }
              }),
              id: data.id,
              name: data.name,
              ne: { lat: n, lng: e },
              sw: { lat: s, lng: w }
            })
          }
        }
        if (flyZoneDataValue.length > 0) {
          JlinkStorage.set('zoneData2', { coordinate: MAP_COORDINATE, values: flyZoneDataValue })
        }
        return flyZoneDataValue
      }
    } catch (e: any) {
      console.error(e.message)
      return []
    }
  }

  @JlinkThrottle(800, false, true)
  mapViewChange (map?:CesiumService) {
    if (map) {
      const camera = map.camera
      const extend = camera.camera.computeViewRectangle()
      let n: number; let s: number; let w: number; let e: number = 0
      if (extend) {
        n = Math2.toDegrees(extend.north)
        s = Math2.toDegrees(extend.south)
        w = Math2.toDegrees(extend.west)
        e = Math2.toDegrees(extend.east)
      } else {
        const scene = map.scene
        const coordToLnglat = (x: number, y: number) => {
          const d2 = new Cartesian2(x, y)
          const ellipsoid = scene.scene.globe.ellipsoid
          // 2D转3D世界坐标
          const d3 = camera.camera.pickEllipsoid(d2, ellipsoid)
          if (d3) {
          // 3D世界坐标转弧度
            const upperLeftCartographic = scene.scene.globe.ellipsoid.cartesianToCartographic(d3)

            // 弧度转经纬度
            const lng = Math2.toDegrees(upperLeftCartographic.longitude)
            const lat = Math2.toDegrees(upperLeftCartographic.latitude)
            return { lng, lat }
          } else {
            return { lng: 0, lat: 0 }
          }
        }
        const canvas = scene.scene.canvas
        const upperLeftLonLat = coordToLnglat(0, 0)
        const lowerRightLonLat = coordToLnglat(canvas.clientWidth, canvas.clientHeight)
        n = upperLeftLonLat.lat
        s = lowerRightLonLat.lat
        w = upperLeftLonLat.lng
        e = lowerRightLonLat.lng
      }

      const viewerRect = Rectangle.fromDegrees(w, s, e, n)
      const entityMaker = map.entity
      const final = this.flyZone.value.filter(z => {
        const dn = z.ne.lat
        const ds = z.sw.lat
        const dw = z.sw.lng
        const de = z.ne.lng
        const dr = Rectangle.fromDegrees(dw, ds, de, dn)
        return Rectangle.intersection(viewerRect, dr) != null
      })

      console.log("final",final)
      final.forEach(f => {
        const e = entityMaker.getById('zonePolygonPrefix' + f.id)
        if (!e) {
          entityMaker.makeById('zonePolygonPrefix' + f.id)
            .drawPolygon()
            .setOutline(true)
            .setOutlineColor(Color.fromCssColorString('rgba(255,0,0,0.8)'))
            .setHeight(0)
            .setColorMaterial(Color.fromCssColorString('rgba(255,0,0,0.4)'))
            .setHierarchy(f.geometry)
            .end()
            .addToView()
        }
        const el = entityMaker.getById('zoneLabelPrefix' + f.id)
        if (!el) {
          const offsetX = f.ne.lng - f.sw.lng
          const offsetY = f.ne.lat - f.sw.lat
          const center: JlinkLocation = { lat: f.sw.lat + offsetY / 2.0, lng: f.sw.lng + offsetX / 2.0, height: 51 }
          console.log(12345, f, center)
          entityMaker.makeById('zoneLabelPrefix' + f.id)
            .drawLabel()
            .setText(f.name)
            .setFillColor(Color.fromCssColorString('#fff'))
            .setBackgroundPadding(5, 10)
            .setFont('20px sans-serif')
            // .setShowBackground(true)
            // .setBackgroundColor('#4953AD')
            .end()
            .setPosition(center)
            .addToView()
        }
      })
    }
  }

  destroy (map?:CesiumService) {
    super.destroy()
    if (map) {
      const entityMaker = map.entity
      entityMaker.removeByVagueId('zonePolygonPrefix*')
      entityMaker.removeByVagueId('zoneLabelPrefix*')
    }
  }
}
