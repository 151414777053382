import axios, { AxiosResponse } from 'axios'
import NotifyException from '@/common/errors/NotifyException'

export default class FileUtil {
  openFile (type: 'office', path: string) {
    if (type === 'office') {
      path = encodeURIComponent(path)
      window.open('https://view.officeapps.live.com/op/view.aspx?src=' + path)
    } else {
      console.error(`type ${type} is not support`)
    }
  }

  async readFile (url: string) {
    const res = await axios.get(url, {
      responseType: 'blob',
    })
    if (res.status !== 200) {
      throw new NotifyException('url错误或者文件不存在', 'error')
    }

    if (res.data) {
      if (res.data.size === 0) {
        throw Error('读取文件不是有效的文件')
      }
      const blob = new Blob([res.data], { type: res.data.type })
      const blobText = await blob.text()
      return JSON.parse(blobText)
    }
  }

  async openOssFile (ossKey: string) {
    const res = await window.$oss.getSignUrl(ossKey)
    window.open(res)
  }
}
