import BigemapHelper from '@/common/helper/map/BigemapHelper'
import { Ref } from 'vue'
import JlinkUtils from '@/common/global/JlinkUtils'
import { Color, Entity, HeightReference } from 'cesium'
import CesiumService from '@/components/cesium/CesiumService'

export default class MapPickBigemapHelper extends BigemapHelper {
  private p: Ref<JlinkLocation | undefined>
  private clickAble: Ref<boolean>
  private callback: (p: JlinkLocation) => void
  constructor (map: Ref<{ mapService: () => Promise<CesiumService> | CesiumService } | undefined>, p: Ref<JlinkLocation | undefined>, clickAble: Ref<boolean>, callback: (p: JlinkLocation) => void) {
    super(map)
    this.p = p
    this.clickAble = clickAble
    this.callback = callback
  }

  async mapServiceLoaded (service: CesiumService) {
    const _this = this
    this.watchSource(() => this.p.value, async function (value) {
      if (value) {
        service.entity
          .makeById(_this.id)
          .drawBillboard()
          .setImagePin('P', Color.RED, 36)
          .setHeightReference(HeightReference.CLAMP_TO_GROUND)
          .end()
          .setPosition({ ...value, height: 100 })
          .coverToView()
        service.scene.requestRender()
        await _this.flyToEntities()
      } else {
        service.entity.removeById(_this.id)
      }
    }, { immediate: true, deep: true })

    await this.setLeftClick(function (position) {
      if (_this.clickAble.value) {
        _this.callback(position)
      }
    })
  }
}
