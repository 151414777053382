import { reactive, Ref } from 'vue'
import JlinkTask from '@/common/global/JlinkTask'
import BigemapHelper from '@/common/helper/map/BigemapHelper'
import CesiumService from '@/components/cesium/CesiumService'
import { ColorBlendMode, Entity, SceneMode } from 'cesium'
import AircraftEntityHelper from '@/common/helper/map/AircraftEntityHelper'
import JlinkMqtt2 from '@/common/global/JlinkMqtt2'
import AircraftWaylineTrackHelper from '@/common/helper/map/AircraftWaylineTrackHelper'
import { DOCK_MODE_CODE } from '@/common/enum/DEVICE_ENUMS'
import JlinkBindThis from '@/common/annotate/JlinkBindThis'

type highFrequencyData = JlinkLocation & { elevation: number, heading: number, pitch: number, roll: number }
export default abstract class FlyBigemapHelper extends BigemapHelper {
  private aircraftEntityHelper: AircraftEntityHelper | undefined
  private aircraftWaylineTrackHelper: AircraftWaylineTrackHelper | undefined

  private markerFunSave = new Map<string, {
    updateData:(o: MqttAircraftOsdResult & { highFrequency?: boolean, pitch?: number, roll?: number }) => void,
    updateTick: () => void,
    remove: () => void
  }>()

  private gatewayPrefix = 'gateway_marker'
  private gatewayLandPrefix = 'gateway_land_marker'
  protected selectedGateway: string | undefined
  protected selectedAircraftSn: string | undefined
  highFrequencyOsd = reactive<StringKeyRecord<highFrequencyData>>({})

  private fps = 150

  constructor (map: Ref<{ mapService: () => Promise<CesiumService> | CesiumService } | undefined>) {
    super(map)
    this.markerFunSave = new Map()
    // 开启定时器用于更新飞行器高频数据
    const _this = this
    this.addInterval('FlyBigemapHelperFps', function () {
      _this.markerFunSave.forEach(i => {
        i.updateTick()
      })
    }, this.fps)
  }

  // 获取网关设备的地图实体
  async getGatewayEntity (gatewaySn?: string) {
    if (gatewaySn) {
      const entityMaker = await this.getEntity()
      const entityId = this.makeGatewayId({ gatewaySn })
      return entityMaker.getById(entityId)
    } else {
      return undefined
    }
  }

  // 获取所有网关设备的地图实体
  async getGatewayEntityAll () {
    const entityMaker = await this.getEntity()
    return entityMaker.getAllValues().filter(i => i.id.startsWith(this.gatewayPrefix))
  }

  // 根据实体id获取实际的网关序列号
  getGatewayEntityRealIdFromEntity (entity?: Entity) {
    return entity?.id?.startsWith(this.gatewayPrefix) && entity?.id.replace(this.gatewayPrefix, '')
  }

  // 获取网关设备备降点的地图实体
  async getGatewayLandEntity (gatewaySn: string) {
    const entityMaker = await this.getEntity()
    const entityId = this.makeLandPointId({ gatewaySn })
    return entityMaker.getById(entityId)
  }

  // 获取所有网关设备备降点的地图实体

  async getGatewayLandEntityAll () {
    const entityMaker = await this.getEntity()
    return entityMaker.getAllValues().filter(i => i.id.startsWith(this.gatewayLandPrefix))
  }

  // 根据实体id获取实际的网关序列号
  getGatewayLandEntityRealIdFromEntity (entity?: Entity) {
    return entity?.id?.startsWith(this.gatewayLandPrefix) && entity?.id.replace(this.gatewayLandPrefix, '')
  }

  getTrailEntityRealIdFromEntity (entity?: Entity) {
    return this.aircraftEntityHelper?.getAircraftTrailEntityRealIdFromEntity(entity)
  }

  geAircraftEntity (aircraftSn: string) {
    return this.aircraftEntityHelper?.getAircraftEntity({ aircraftSn })
  }

  async getAircraftEntityAll () {
    return this.aircraftEntityHelper?.getAircraftEntityAll()
  }

  getAircraftEntityRealIdFromEntity (entity?: Entity) {
    return this.aircraftEntityHelper?.getAircraftEntityRealIdFromEntity(entity)
  }

  async selectGatewayAll (drone?: DroneBaseVo): Promise<void> {
    const selectedGateway = drone?.gatewaySerialNumber || ''
    const selectAircraft = drone?.aircraftSerialNumber || ''
    await this.selectGateway(selectedGateway)
    await this.selectAircraft(selectAircraft)
    if (this.aircraftEntityHelper?.getFirstPerspective() !== selectAircraft) {
      this.openFirstPerspective(undefined)
    }
  }

  setFollow (aircraftSn?:string) {
    this.aircraftEntityHelper?.setFollow(aircraftSn)
  }

  async selectAircraft (aircraftSn: string) {
    if (aircraftSn === this.selectedAircraftSn) {
      return
    }
    this.aircraftEntityHelper?.aircraftSelect({ aircraftSn })
    this.aircraftEntityHelper?.aircraftTrailSelect({ aircraftSn })
    this.selectedAircraftSn = aircraftSn
    if (this.aircraftEntityHelper?.follow !== aircraftSn) {
      this.aircraftEntityHelper?.setFollow(undefined)
    }

    const aircraftEntity = this.aircraftEntityHelper?.getAircraftEntity({ aircraftSn })
    if (aircraftEntity) {
      this.aircraftEntityHelper?.flyToAircraft({ aircraftSn })
    } else {
      const gateway = await JlinkTask.asyncGet(() => this.getGatewayEntity(this.selectedGateway), 10, 500)
      if (gateway) {
        this.flyTo([gateway])
      }
    }
  }

  async selectGateway (gatewaySn?: string) {
    const entityMaker = await this.getEntity()
    entityMaker.getAll().filter(it => it.entity.id.startsWith(this.gatewayPrefix))
      .forEach(e => {
        e.drawModel().setScale(this.gatewayGlbOptions.minScale)
      })
    if (gatewaySn) {
      const gateway = entityMaker.getById(this.makeGatewayId({ gatewaySn }))
      if (gateway) {
        gateway.drawModel().setScale(this.gatewayGlbOptions.maxScale)
      }
    }
    this.selectedGateway = gatewaySn
  }

  async destroy () {
    super.destroy()
    this.markerFunSave.clear()
    this.aircraftWaylineTrackHelper?.destory()
  }

  /* 逻辑代码 */
  private getMarkerFun (aircraftSn: string) {
    let fun = this.markerFunSave.get(aircraftSn)
    if (!fun) {
      this.markerFunSave.set(aircraftSn, this.makeMarkFun(aircraftSn))
      fun = this.markerFunSave.get(aircraftSn)
    }
    return fun!
  }

  // async locationGateway (gatewaySn: string) {
  //   if (gatewaySn) {
  //     const entityMarker = await this.getEntity()
  //     const entities = entityMarker.getAllValues().filter(i => i.id.startsWith(this.gatewayPrefix) && i.id.includes(gatewaySn))
  //     await JlinkTask.asyncGet(() => entities.length)
  //     await this.lookAt()
  //     await this.flyTo(entities)
  //     return entities.length > 0
  //   }
  //   return false
  // }

  private makeMarkFun (aircraftSn: string) {
    let osd: MqttAircraftOsdResult & { highFrequency?: boolean, pitch: number, roll: number } | undefined
    let offsetLng: number = 0
    let offsetLat: number = 0
    let offsetHeight: number = 0
    let offsetAttitudeHead: number = 0
    let offsetPitch = 0
    let offsetRoll = 0
    const _this = this
    let oldRotate = 0
    let initHead = 0
    let flushPipeline: highFrequencyData[] = []

    function transRotate (to: number) {
      const rotateNear = to - initHead
      if (Math.abs(rotateNear) < 0.1) {
        return oldRotate
      }
      const rotateFar = rotateNear > 0 ? rotateNear - 360 : rotateNear + 360
      if (Math.abs(rotateNear) > Math.abs(rotateFar)) {
        oldRotate += rotateFar
      } else {
        oldRotate += rotateNear
      }
      initHead = to
      return oldRotate
    }

    let Hz = 0.5

    async function updateData (o: MqttAircraftOsdResult & { highFrequency?: boolean, pitch?: number, roll?: number }) {
      o.attitudeHead = transRotate(o.attitudeHead || 0)
      o.pitch = o.payloads?.first()?.gimbal_pitch || 0
      o.roll = o.payloads?.first()?.gimbal_roll || 0
      const pipelineCache = flushPipeline.shift()
      offsetAttitudeHead = o.attitudeHead - (pipelineCache?.heading || osd?.attitudeHead || o.attitudeHead)
      offsetLng = o.lng - (pipelineCache?.lng || osd?.lng || o.lng)
      offsetLat = o.lat - (pipelineCache?.lat || osd?.lat || o.lat)
      offsetHeight = o.height - (pipelineCache?.height || osd?.height || o.height)
      offsetPitch = o.pitch - (osd?.pitch || o.pitch)
      offsetRoll = o.roll - (pipelineCache?.roll || osd?.roll || o.roll)
      if (o.highFrequency) {
        Hz = 1
      } else {
        Hz = 0.5
      }
      const count = (1 / Hz * 1000) / _this.fps + 5
      osd = { ...o, pitch: o.pitch || 0, roll: o.roll || 0 }
      flushPipeline = []
      for (let i = 0; i < count; i++) {
        const offset = (i / count) - 1
        flushPipeline.push({
          lng: o.lng + offsetLng * offset,
          lat: o.lat + offsetLat * offset,
          height: o.height + offsetHeight * offset,
          elevation: o.elevation,
          heading: o.attitudeHead + offsetAttitudeHead * offset,
          pitch: o.pitch + offsetPitch * offset,
          roll: o.roll + offsetRoll * offset
        })
      }
    }

    function remove () {
      flushPipeline = []
    }

    function updateTick () {
      const position = flushPipeline.shift()
      if (position) {
        _this.highFrequencyOsd[aircraftSn] = position
        if (_this.aircraftEntityHelper) {
          _this.aircraftEntityHelper.aircraftDraw({
            aircraftSn,
            position,
            selected: aircraftSn === _this.selectedAircraftSn
          })
        }
      }
    }

    return { updateData, updateTick, remove }
  }

  // 取消当前播放
  stopCurrentSpeech () {
    this.aircraftWaylineTrackHelper?.stopCurrentSpeech()
  }

  async addWaylineTrack (result?: FlightTaskProcessResult) {
    this.aircraftWaylineTrackHelper?.progress(result)
    if (this.selectedGateway === result?.gatewaySn) {
      this.aircraftWaylineTrackHelper?.speaker(result)
    }
  }

  async addRcTrack (msg: MqttRcOsdResult) {
    if (msg.lat === 0 || msg.lng === 0) {
      await this.removeGatewayTrack(msg.gatewaySn)
      return
    }
    const entityMaker = await this.getEntity()
    const em = entityMaker.getById(this.makeGatewayId(msg))
    if (!em) {
      const selected = this.selectedGateway === msg.gatewaySn
      const eh = entityMaker.makeById(this.makeGatewayId(msg))
      eh.drawModel()
        .setUri(this.gatewayGlb())
        .setMinimumPixelSize(this.gatewayGlbOptions.minSize)
        .setScale(selected ? this.gatewayGlbOptions.maxScale : this.gatewayGlbOptions.minScale)
        .end()
        // .setMaximumScale(this.gatewayGlbOptions.maxScale)
        // .setImageBasedLightingFactor2(this.gatewayGlbOptions.baseLightFactor)
        // .setScale(selected ? this.gatewayGlbOptions.maxScale : this.gatewayGlbOptions.normalScale)
        .setOrientation({ ...msg, height: 20 }, this.gatewayGlbOptions.rotate, this.gatewayGlbOptions.pitch, 0)
        .addToView()
    } else {
      em.setPosition({ ...msg, height: 20 })
    }
  }

  async removeGatewayTrack (gatewaySn: string) {
    const entityMaker = await this.getEntity()
    entityMaker.removeById(this.makeGatewayId({ gatewaySn }))
    entityMaker.removeById(this.makeLandPointId({ gatewaySn }))
  }

  async addDockTrack (msg: MqttDockOsdResult) {
    if (msg.lat === 0 || msg.lng === 0) {
      await this.removeGatewayTrack(msg.gatewaySn)
      return
    }
    if (msg.gatewaySn) {
      const entityMaker = await this.getEntity()
      let gem = entityMaker.getById(this.makeGatewayId(msg))
      const pp: JlinkLocation = { ...msg }
      if (!gem) {
        const selected = this.selectedGateway === msg.gatewaySn
        const eh = entityMaker.makeById(this.makeGatewayId(msg))
        gem = eh.drawModel()
          .setUri(this.gatewayGlb())
          .setMinimumPixelSize(this.gatewayGlbOptions.minSize)
          .setImageBasedLightingFactor2(this.gatewayGlbOptions.baseLightFactor)
          .setScale(selected ? this.gatewayGlbOptions.maxScale : this.gatewayGlbOptions.minScale)
          .setColorBlendMode(ColorBlendMode.HIGHLIGHT)
          .end()
          .setOrientation(pp, this.gatewayGlbOptions.rotate, this.gatewayGlbOptions.pitch, 0)
          .setPosition(pp)
          .addToView()
      } else {
        gem
          .setOrientation(pp, this.gatewayGlbOptions.rotate, this.gatewayGlbOptions.pitch, 0)
          .setPosition(pp)
      }
      if (msg.alternateLandPointIsConfigured === 1 && (
        msg.modeCode === DOCK_MODE_CODE.FIELD_DEBUG ||
        msg.modeCode === DOCK_MODE_CODE.REMOTE_DEBUG ||
        msg.modeCode === DOCK_MODE_CODE.UPGRADE ||
        msg.modeCode === DOCK_MODE_CODE.WORKING)) {
        let glem = entityMaker.getById(this.makeLandPointId(msg))
        if (!glem) {
          const eh = entityMaker.makeById(this.makeLandPointId(msg))
          console.log(this.gatewayLandGlbOptions, 'gatewayLandGlbOptions')
          glem = eh.drawModel()
            .setUri(this.gatewayLandGlb())
            .setMinimumPixelSize(this.gatewayLandGlbOptions.minSize)
            // .setMaximumScale(this.gatewayGlbOptions.maxScale)
            .setImageBasedLightingFactor2(this.gatewayLandGlbOptions.baseLightFactor)
            .setScale(this.gatewayLandGlbOptions.minScale)
            .end()
            .addToView()
        }
        const scene = await this.getScene()
        const mode = scene.scene.mode
        const landPosition: JlinkLocation = {
          lng: msg.alternateLandPointLongitude,
          lat: msg.alternateLandPointLatitude,
          height: mode === SceneMode.SCENE2D ? pp.height - 10 : msg.alternateLandPointSafeLandHeight
        }
        glem
          .setOrientation(landPosition, this.gatewayLandGlbOptions.rotate, this.gatewayLandGlbOptions.pitch, 0)
          .setPosition(landPosition)
      } else {
        entityMaker.removeById(this.makeLandPointId(msg))
      }
    }
    return true
  }

  addTrailTrack (data: { aircraftSn: string, selected: boolean }) {
    //this.aircraftEntityHelper?.aircraftTrailTrackDraw(data)
  }

  removeTrailTrack (aircraftSn: string) {
    this.aircraftEntityHelper?.aircraftTrailTrackRemove({ aircraftSn })
  }

  removeAircraftTrack (aircraftSn: string) {
    this.aircraftEntityHelper?.aircraftRemove({ aircraftSn })
    this.markerFunSave.get(aircraftSn)?.remove()
  }

  async addAircraftTrack (msg: MqttAircraftOsdResult & { highFrequency?: boolean }) {
    if (msg.lat === 0 || msg.lng === 0) {
      this.removeAircraftTrack(msg.aircraftSn)
      return
    }
    if (this.aircraftEntityHelper) {
      if (!this.aircraftEntityHelper.aircraftExit(msg)) {
        this.aircraftEntityHelper.aircraftDraw({
          aircraftSn: msg.aircraftSn,
          position: {
            lat: msg.lat,
            lng: msg.lng,
            height: msg.height,
            elevation: msg.elevation,
            heading: 0,
            pitch: 0,
            roll: 0
          },
          selected: msg.aircraftSn === this.selectedAircraftSn
        })
      }
    }
    this.getMarkerFun(msg.aircraftSn).updateData(msg)
    return true
  }

  private makeGatewayId (data: { gatewaySn: string }) {
    return this.gatewayPrefix + data.gatewaySn
  }

  protected makeLandPointId (data: { gatewaySn: string }) {
    return this.gatewayLandPrefix + data.gatewaySn
  }

  openFirstPerspective (aircraftSn?: string) {
    const beh = this.aircraftEntityHelper
    if (beh) {
      beh && beh.aircraftFirstPerspective({ aircraftSn })
    } else {
      console.log('aircraftEntityHelper is lost')
    }
  }

  // date = Date.now()
  // @JlinkBindThis
  // private sssss () {
  //   console.log(Date.now() - this.date)
  //   this.markerFunSave.forEach(i => {
  //     i.updateTick()
  //   })
  // }

  async mapServiceLoaded (service: CesiumService) {
    // service.scene.removePostUpdateListener(this.sssss)
    //   .addPostUpdateListener(this.sssss)
    this.aircraftEntityHelper = new AircraftEntityHelper(service)
    this.aircraftWaylineTrackHelper = new AircraftWaylineTrackHelper(service)
    if (this.selectedAircraftSn) {
      const d = JlinkMqtt2.getReactiveDeviceByAircraftSn(this.selectedAircraftSn)?.getAircraftOnline()
      if (d) {
        const aircraft = this.geAircraftEntity(this.selectedAircraftSn)
        if (aircraft) {
          await this.flyTo([aircraft])
          this.setFollow(this.selectedAircraftSn)
        } else {
          const gateway = await this.getGatewayEntity(this.selectedGateway)
          if (gateway) {
            await this.flyTo([gateway])
            this.setFollow(undefined)
          }
        }
      } else {
        const gateway = await this.getGatewayEntity(this.selectedGateway)
        if (gateway) {
          await this.flyTo([gateway])
          this.setFollow(undefined)
        }
      }
    }
  }
}
