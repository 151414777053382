import { computed, Ref } from 'vue'
import { FlightControlModule } from '@/store/FlightControl'
import JlinkUtils from '@/common/global/JlinkUtils'
import { Color } from 'cesium'
import CesiumService from '@/components/cesium/CesiumService'
import JlinkMqtt2 from '@/common/global/JlinkMqtt2'
import BaseHelper from '@/common/helper/BaseHelper'
import EntityHelper from '@/components/cesium/entities/EntityHelper'
import MarkControlPoint from '@/app/views/pages/flightControl3/flightControlLeft/components/MarkControlPoint.vue'
import { FLIGHT_TASK_STATUS, JOB_TYPE } from '@/common/enum/JLINK_ENUMS'
import JlinkType from '@/common/global/JlinkType'
import JlinkTransform from '@/common/global/JlinkTransform'
import { DATA_COORDINATE, MAP_COORDINATE } from '@/common/global/JlinkValues'

export default class ControlPointDingHelper extends BaseHelper {
  private pointDingPrefix = 'pointDingPrefix'
  private pointDingHeightLinePrefix = 'pointDingHeightLinePrefix'
  private service: CesiumService

  private makePointDingId (id: string) {
    return this.pointDingPrefix + id
  }

  private makeDingHeightLineId (id: string) {
    return this.pointDingHeightLinePrefix + id
  }

  protected makeFlyToId (id: string) {
    return 'makeFlyToId' + id
  }

  private drone: Ref<DroneBaseVo | undefined>

  // 航点模型
  private pointGlb = () => require('@/assets/model/ding.glb').default
  private pointGlbOptions = {
    rotate: 90,
    pitch: 90,
    maxScale: 1.2,
    minScale: 1,
    minSize: 48,
    baseLightFactor: { x: 1, y: 1 }
  }

  constructor (drone: Ref<DroneBaseVo | undefined>, service: CesiumService) {
    super()
    this.drone = drone
    this.service = service
  }

  click (e: JlinkLocation) {
    if (FlightControlModule.pointDingAble) {
      const id = JlinkUtils.random.uuid(12)
      const points: ControlPoints = {
        title: '',
        type: 'ding',
        id,
        ...e,
      }
      FlightControlModule.pickPoints(points)
    }
  }

  selected (position: JlinkLocation, e?: EntityHelper) {
    if (e?.entity.id.startsWith(this.pointDingPrefix)) {
      FlightControlModule.pointsControl.currentDingData = FlightControlModule.controlPointsList.findBy('id', e?.entity.id.replace(this.pointDingPrefix, ''))
      // bigemapLocation.lat = target?.lat || 0
      // bigemapLocation.lng = target?.lng || 0
      // bigemapLocation.height = target?.height || 0
    } else {
      FlightControlModule.pointsControl.currentDingData = undefined
    }
  }

  flyToPoints (id:string) {
    const ding = this.service.entity.getById(this.makePointDingId(id))
    if (ding) {
      this.service.viewer.flyTo([ding.entity])
    }
  }

  watchControlPoints () {
    const _this = this
    const controlPointsList = computed(() => FlightControlModule.controlPointsList)
    const pointIds = computed(() => controlPointsList.value.mapBy('id'))
    const currentDingData = computed(() => FlightControlModule.pointsControl.currentDingData)
    this.watchSource(() => currentDingData.value, function (value, oldValue, onCleanup) {
      if (oldValue) {
        _this.service.entity.removeCustomOverlayById(oldValue.id)
        const em = _this.service.entity.getById(_this.makePointDingId(oldValue.id))
        em?.drawModel()
          .setScale(_this.pointGlbOptions.minScale)
      }
      if (value) {
        _this.service.entity.makeCustomOverlayById(value.id, MarkControlPoint)
          .setProps({ data: currentDingData.value })
          .setPosition(value, { y: 1.2 })
        const ding = _this.service.entity.getById(_this.makePointDingId(value.id))
        ding?.drawModel()
          .setScale(_this.pointGlbOptions.maxScale)
      }
    })
    this.watchSource(() => JlinkMqtt2.getReactiveDevice(this.drone.value)?.getDockFlightTaskProcessValue(), function (value) {
      const em = _this.service.entity
      if (value?.taskStatus === FLIGHT_TASK_STATUS.WAYLINE_PROGRESS && value.plannedPathPoints && value.plannedPathPoints?.length > 0) {
        const points = value.plannedPathPoints.map(item => JlinkUtils.coordinate.coordinateTransform({ lng: item.longitude, lat: item.latitude, height: item.height }, DATA_COORDINATE, MAP_COORDINATE))
        const entity = em.getById('plannedPathPoints')
        if (!entity) {
          em.makeById('plannedPathPoints')
            .drawPolyline()
            .setPositionsByPositions(points)
            .setPolylineDashMaterial({ color: Color.fromCssColorString('#089324') })
            .setWidth(4)
            .end()
            .addToView()
        } else {
          entity.drawPolyline()
            .setPositionsByPositions(points)
        }
      } else {
        em.removeById('plannedPathPoints')
      }
    })
    this.watchSource(() => JlinkMqtt2.getReactiveDevice(this.drone.value)?.getDockFlyToProcessValue(), function (value) {
      const em = _this.service.entity
      if (value) {
        const flyId = value.flyToId.split('-').first()
        if (value.taskStatus === 'wayline_progress') {
          if (value.plannedPathPoints && value.plannedPathPoints?.length > 0) {
            const points = value.plannedPathPoints.map(item => JlinkUtils.coordinate.coordinateTransform({
              lng: item.longitude,
              lat: item.latitude,
              height: item.height
            }, DATA_COORDINATE, MAP_COORDINATE))
            const entity = em.getById('plannedPathPoints')
            if (!entity) {
              em.makeById('plannedPathPoints')
                .drawPolyline()
                .setPositionsByPositions(points)
                .setPolylineDashMaterial({ color: Color.fromCssColorString('#089324') })
                .setWidth(4)
                .end()
                .addToView()
            } else {
              entity.drawPolyline()
                .setPositionsByPositions(points)
            }
          }
        } else {
          em.removeById('plannedPathPoints')
        }
      } else {
        em.removeById('plannedPathPoints')
      }
    })

    this.watchSource(pointIds, async function (v, o) {
      const newValue = v || []
      const oldValue = o || []
      await oldValue.diffTo(newValue, function (s, t) {
        return s === t
      }, async function (l) {
        for (const item of l) {
          _this.service.entity.removeById(_this.makePointDingId(item))
          _this.service.entity.removeById(_this.makeDingHeightLineId(item))
        }
      }, async function (l) {
        // 这里新建
        for (const item of l) {
          const cacheNode = controlPointsList.value.findBy('id', item)!!
          const em = _this.service.entity.makeById(_this.makePointDingId(item))
          em
            .drawModel()
            .setUri(_this.pointGlb())
            .setMinimumPixelSize(_this.pointGlbOptions.minSize)
            .setScale(_this.pointGlbOptions.minScale)
            .setImageBasedLightingFactor2(_this.pointGlbOptions.baseLightFactor)
            .end()
            .setPosition(cacheNode)
            .setOrientation(cacheNode, _this.pointGlbOptions.rotate, _this.pointGlbOptions.pitch, 0)
            .addToView()
            // flyTo标记到地面投影线
          const eml = _this.service.entity.makeById(_this.makeDingHeightLineId(item))
          eml
            .drawPolyline()
            .setWidth(4)
            .setPolylineDashMaterial({ color: Color.RED })
            .setPositionsByPositions([cacheNode, { ...cacheNode, height: 0 }])
            .end()
            .addToView()
        }
      }, function (o) {
        return o
      }
      )
    }, { deep: true, immediate: true })
  }
}
