import CesiumService from '@/components/cesium/CesiumService'
import JlinkTransform from '@/common/global/JlinkTransform'
import { FLIGHT_TASK_STATUS, JOB_TYPE } from '@/common/enum/JLINK_ENUMS'
import JlinkThrottle from '@/common/annotate/JlinkThrottle'
import JlinkMqtt2 from '@/common/global/JlinkMqtt2'
import { Color, HeightReference } from 'cesium'
import JlinkTask from '@/common/global/JlinkTask'
import JlinkUtils from '@/common/global/JlinkUtils'
import BaseHelper from '@/common/helper/BaseHelper'

export default class AircraftWaylineTrackHelper extends BaseHelper {
  private readonly map: CesiumService
  // private readonly trackPolylinePatten = 'polyline_+'
  // private readonly trackPoiPointPatten = 'poiPoint_+_-'
  // private readonly trackPointIndexPatten = 'pointIndex_+_-'
  private readonly liveSave:StringKeyRecord<number> = {}

  // 取消当前播放
  stopCurrentSpeech () {
    this.speakLoop.clear()
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis?.cancel()
    }
  }

  // 航线兴趣点模型
  private poiPointGlb = () => require('@/assets/model/ding.glb').default
  private poiPointGlbOptions = {
    rotate: 90,
    pitch: 90,
    maxScale: 2,
    minScale: 1.6,
    minSize: 32,
    baseLightFactor: { x: 0.1, y: 0.1 }
  }

  private waylineActionsSave: StringKeyRecord<number> = {}
  private speakLoop = JlinkTask.loopPipeline<WaylinePointAction>(this.speechWaylineAction.bind(this), function () {
  })

  private async speechWaylineAction (action: WaylinePointAction) {
    switch (action.actionType) {
      case 'hover':
        await JlinkUtils.media.startTtsSpeech({ text: `飞机悬停${action.hoverTime}秒` })
        break
      case 'rotateYaw':

        const h = action.aircraftHeading
        let ht: string
        if (h === 0) {
          ht = '指北' + action.aircraftHeading + '度'
        } else if (h > 0) {
          ht = '指北右向' + action.aircraftHeading + '度'
        } else {
          ht = '指北左向' + action.aircraftHeading + '度'
        }

        await JlinkUtils.media.startTtsSpeech({ text: `飞行器偏航角${ht}` })
        break
      case 'gimbalRotate':

        const s = action.gimbalPitchRotateAngle
        let t: string
        if (s === 0) {
          t = '向前' + action.gimbalPitchRotateAngle + '度'
        } else if (s > 0) {
          t = '向上' + action.gimbalPitchRotateAngle + '度'
        } else {
          t = '向下' + action.gimbalPitchRotateAngle + '度'
        }

        await JlinkUtils.media.startTtsSpeech({ text: `云台俯仰角${t}` })
        break
      case 'takePhoto':
        await JlinkUtils.media.startTtsSpeech({ text: '相机拍照' })
        break
      case 'startRecord':
        await JlinkUtils.media.startTtsSpeech({ text: '相机开始录像' })
        break
      case 'stopRecord':
        await JlinkUtils.media.startTtsSpeech({ text: '相机停止录像' })
        break
      case 'zoom':
        await JlinkUtils.media.startTtsSpeech({ text: `相机变焦${(action.focalLength).toFixed(0)}倍` })
        break
      default:
        break
    }
    await JlinkTask.sleep(500)
  }

  constructor (map: CesiumService) {
    super()
    this.map = map
    const _this = this
    this.addInterval('wayline', function () {
      const now = Date.now()
      Object.keys(_this.liveSave).forEach(item => {
        const markTime = _this.liveSave[item]
        // 超过8秒没有收到该事件更新的时候认为他已经断链或者结束
        if (now - markTime > 8000) {
          const entityMaker = _this.map.entity
          if (entityMaker.getAllIds().includes(item as string)) {
            entityMaker.removeById(item as string)
            entityMaker.removeByVagueId(item + 'poiPoint' + '*')
            entityMaker.removeByVagueId(item + 'pointIndex' + '*')
          }
          delete _this.liveSave[item]
        }
      })
    }, 5000)
  }

  progress (result?: FlightTaskProcessResult) {
    console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwwwww")
    if (result) {
      if (result.jobType === JOB_TYPE.TAKEOFF) {
        return
      }
      this.liveSave[result.taskId] = Date.now()
      const end = JlinkTransform.waylineJobTask.JobTaskStatusEnd(result.taskStatus)
      if (end || result.taskStatus === FLIGHT_TASK_STATUS.BREAK_CONTINUE) {
        this.remove(result)
      } else {
        this.add(result)
      }
    }
  }

  speaker (result?: FlightTaskProcessResult) {
    if (result) {
      const index = result.currentWayPointIndex
      if (this.waylineActionsSave[result.taskId] !== index) {
        this.waylineActionsSave[result.taskId] = index
        this.speakLoop.clear()
        this.stopCurrentSpeech()
        const res = JlinkMqtt2.getReactiveDeviceByGatewaySn(result.gatewaySn)?.getValueTranslate().getFlightTaskWayline()
        const actions = res?.points[index - 1]?.actions || []
        actions.forEach(item => {
          this.speakLoop.push(item)
        })
      }
      if (JlinkTransform.waylineJobTask.JobTaskStatusEnd(result.taskStatus)) {
        this.speakLoop.clear()
        this.stopCurrentSpeech()
      }
    }
  }

  private remove (process: FlightTaskProcessResult) {
    const entityMaker = this.map.entity
    if (entityMaker.getAllIds().includes(process.taskId)) {
      entityMaker.removeById(process.taskId)
      entityMaker.removeByVagueId(process.taskId + 'poiPoint' + '*')
      entityMaker.removeByVagueId(process.taskId + 'pointIndex' + '*')
    }
  }

  destory () {
    super.destroy()
    this.stopCurrentSpeech()
  }

  @JlinkThrottle(1000, true, false)
  private add (message: FlightTaskProcessResult) {
    const entityMaker = this.map.entity
    if (entityMaker.getAllIds().includes(message.taskId)) {
      return
    }
    const dockHelper = JlinkMqtt2.getReactiveDeviceByGatewaySn(message.gatewaySn)
    const _this = this
    const dockPosition = dockHelper?.getDockOsdValue()
    if (!dockPosition) {
      console.error('this.dockOsdSave is lost')
      return
    }
    const res = dockHelper?.getValueTranslate().getFlightTaskWayline()
    if (res) {
      const newPath: (JlinkLocation & { index: number })[] = []
      if (dockPosition.lat && dockPosition.lng) {
        newPath.push({ ...dockPosition, index: -1 })
      }

      const heightMode = res.common.heightMode
      let heightOffset: number

      switch (heightMode) {
        case 'relativeToStartPoint':
          heightOffset = dockPosition.height
          break
        case 'EGM96':
          heightOffset = 0
          break
        case 'aboveGroundLevel':
          heightOffset = 0
          // TODO 这里的逻辑需要验证
          console.warn(`heightMode ${heightMode} cal heightOffset fail `)
          break
        case 'realTimeFollowSurface':
          heightOffset = 0
          // TODO 这里的逻辑需要验证
          console.warn(`heightMode ${heightMode} cal heightOffset fail `)
          break
        default:
          heightOffset = 0
          // TODO 这里的逻辑需要验证
          console.warn(`heightMode ${heightMode} cal heightOffset fail `)
          break
      }
      newPath.push(...res.points.map((value, index) => {
        return { ...value, height: value.height + heightOffset, index }
      }))
      res.points?.forEach((value, index) => {
        if (value.waypointPoiPoint) {
          // 这里有问题
          const pointToPoint = value.waypointPoiPoint
          const position: JlinkLocation = { ...pointToPoint }
          const poie = entityMaker.getById(message.taskId + 'poiPoint' + index)
          if (!poie) {
            entityMaker.makeById(message.taskId + 'poiPoint' + index)
              .drawModel()
              .setUri(_this.poiPointGlb())
              .setMinimumPixelSize(_this.poiPointGlbOptions.minSize)
              .setScale(_this.poiPointGlbOptions.minScale)
              .setImageBasedLightingFactor2(_this.poiPointGlbOptions.baseLightFactor)
              .end()
              .setOrientation(position, _this.poiPointGlbOptions.rotate, _this.poiPointGlbOptions.pitch, 0)
              .setPosition(position)
              .addToView()
          } else {
            poie
              .setOrientation(position, _this.poiPointGlbOptions.rotate, _this.poiPointGlbOptions.pitch, 0)
              .setPosition(position)
          }
        }
      })
      if (!entityMaker.getById(message.taskId)) {
        entityMaker.makeById(message.taskId)
          .drawPolyline()
          .setPolylineDashMaterial({ color: Color.WHITE })
          .setWidth(3)
          .setClampToGround(true)
          .setZIndex(-1)
          .setPositionsByPositions(newPath)
          .end()
          .addToView()
      } else {
        entityMaker.getById(message.taskId)!
          .drawPolyline()
          .setPositionsByPositions(newPath)
      }
      newPath.forEach(i => {
        const aaa = entityMaker.getById(message.taskId + 'pointIndex' + i.index)
        if (!aaa) {
          entityMaker.makeById(message.taskId + 'pointIndex' + i.index)
            .drawPoint()
            .setPixelSize(24)
            .setColor(Color.fromCssColorString('#4953AD'))
            .setHeightReference(HeightReference.NONE)
            .end()
            .drawLabel()
            .setText((i.index + 1) + '')
            .setFillColor(Color.WHITE)
            .setFont('16px sans-serif')
            .setEyeOffset(0, 0, -10)
            .end()
            .setPosition(i)
            .addToView()
        } else {
          aaa.setPosition(i)
        }
      })
    }
  }
}
