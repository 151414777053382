import CameraUtil from '@/common/global/util/CameraUtil'

interface DjiDevice {
    type: 'gateway' | 'aircraft' | 'camera'
    name: string
    enumKey: string
}
export interface DjiPayload extends DjiDevice {
  type: 'camera'
  // ...还有其他属性
  ptzRoll: number[] // 横滚
  ptzPitch: number[] // 俯仰角
  ptzYaw: number[] // 偏航角
  supportLens: ('visable' | 'zoom' | 'wide' | 'ir' | 'narrow_band') []
  cameraParam: {
    wide?: {
      pixel: number[]
      cmos: number,
      focalLength: number[]
      equivalentFocalLength:number[]
    },
    zoom?: {
      pixel: number[]
      cmos: number,
      focalLength: number[]
      // 等效焦距
      equivalentFocalLength: number[]
      // 混合变焦倍数
      hybridZoomRate:number[]
    },
    ir?:{
      hybridZoomRate:number[]
    }
  }
}
export interface DjiAircraft extends DjiDevice {
  type: 'aircraft'
  camera?: () => DjiPayload
  payload: (() => DjiPayload)[]
  upSpeed: number
  downSpeed: number
  horizontalSpeed: number
}
interface DjiGateway extends DjiDevice {
    type: 'gateway'
    remote: {
        method: string
        param?: { action: string, supportValue: any[] }[]
    }[]
    aircraft: (() => DjiAircraft)[]
    payload: (() => DjiPayload)[]
}

export class DjiDeviceEnum {
  static deviceEnumKeys = {
    M350RTK: '0-89-0',
    M300RTK: '0-60-0',
    M30: '0-67-0',
    M30T: '0-67-1',
    M3E: '0-77-0',
    M3T: '0-77-1',
    M3M: '0-77-2',
    M3D: '0-91-0',
    M3TD: '0-91-1',
    CAMERA_Z30: '1-20-0',
    CAMERA_XT2: '1-26-0',
    CAMERA_FPV: '1-39-0',
    CAMERA_XTS: '1-41-0',
    CAMERA_H20: '1-42-0',
    CAMERA_H20T: '1-43-0',
    CAMERA_M30: '1-52-0',
    CAMERA_M30T: '1-53-0',
    /* ---没有图片 */
    CAMERA_H20N: '1-61-0',
    /* 没有图片--- */
    CAMERA_M3E: '1-66-0',
    CAMERA_M3T: '1-67-0',
    CAMERA_M3M: '1-68-0',
    CAMERA_M3D: '1-80-0',
    CAMERA_M3TD: '1-81-0',
    CAMERA_H30: '1-82-0',
    CAMERA_H30T: '1-83-0',
    DJI_DOCK_CAMERA: '1-165-0',
    CAMERA_P1: '1-50-65535',
    CAMERA_L1: '1-90742-0',
    DJIRC_TEMP: '2-99999-99999',
    DJIRC: '2-56-0',
    DJIRCPlus: '2-119-0',
    DJIRCPro: '2-144-0',
    DJIDock: '3-1-0',
    DJIDock2: '3-2-0'
  }

  static camera: StringKeyRecord<() => DjiPayload> = {
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_XT2]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'XT2',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_XT2,
        ptzRoll: [0, 0],
        ptzPitch: [0, 0],
        ptzYaw: [0, 0],
        cameraParam: {},
        supportLens: ['zoom', 'wide', 'ir']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_XTS]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'XTS',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_XTS,
        ptzRoll: [0, 0],
        ptzPitch: [0, 0],
        ptzYaw: [0, 0],
        cameraParam: {},
        supportLens: ['zoom', 'wide', 'ir']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_H30]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'H30',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_H30,
        ptzRoll: [0, 0],
        ptzPitch: [0, 0],
        ptzYaw: [0, 0],
        cameraParam: {},
        supportLens: ['zoom', 'wide', 'ir']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_H30T]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'H30T',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_H30T,
        ptzRoll: [0, 0],
        ptzPitch: [0, 0],
        ptzYaw: [0, 0],
        cameraParam: {},
        supportLens: ['zoom', 'wide', 'ir']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_Z30]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'Z30',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_Z30,
        ptzRoll: [0, 0],
        ptzPitch: [-120, 30],
        ptzYaw: [-320, 320],
        cameraParam: {},
        supportLens: ['zoom', 'wide', 'ir']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_H20]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'H20',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_H20,
        ptzRoll: [0, 0],
        ptzPitch: [-120, 30],
        ptzYaw: [-320, 320],
        cameraParam: {},
        supportLens: ['zoom', 'wide', 'ir']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_H20N]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'H20N',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_H20N,
        ptzRoll: [0, 0],
        ptzPitch: [-120, 60],
        ptzYaw: [-320, 320],
        cameraParam: {},
        supportLens: ['zoom', 'wide', 'ir']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_H20T]: function () :DjiPayload {
      return {
        type: 'camera',
        name: 'H20T',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_H20T,
        ptzRoll: [0, 0],
        ptzPitch: [-120, 30],
        ptzYaw: [-320, 320],
        cameraParam: {},
        supportLens: ['zoom', 'wide', 'ir']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_P1]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'P1',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_P1,
        ptzRoll: [0, 0],
        ptzPitch: [0, 0],
        ptzYaw: [0, 0],
        cameraParam: {},
        supportLens: ['zoom', 'wide', 'ir']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_L1]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'L1',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_L1,
        ptzRoll: [0, 0],
        ptzPitch: [-120, 30],
        ptzYaw: [-320, 320],
        cameraParam: {},
        supportLens: ['zoom', 'wide', 'ir']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_FPV]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'FPV',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_FPV,
        ptzRoll: [0, 0],
        ptzPitch: [0, 0],
        ptzYaw: [0, 0],
        cameraParam: {},
        supportLens: []
      }
    },

    [DjiDeviceEnum.deviceEnumKeys.CAMERA_M3E]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'M3E',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_M3E,
        ptzRoll: [0, 0],
        ptzPitch: [-90, 35],
        ptzYaw: [-5, 5],
        cameraParam: {},
        supportLens: ['zoom', 'wide', 'ir']

      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_M3T]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'M3T',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_M3T,
        ptzRoll: [0, 0],
        ptzPitch: [-90, 35],
        ptzYaw: [-5, 5],
        cameraParam: {},
        supportLens: ['zoom', 'wide', 'ir']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.DJI_DOCK_CAMERA]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'DJI_DOCK_CAMERA',
        enumKey: DjiDeviceEnum.deviceEnumKeys.DJI_DOCK_CAMERA,
        ptzRoll: [0, 0],
        ptzPitch: [0, 0],
        ptzYaw: [0, 0],
        cameraParam: {},
        supportLens: ['zoom', 'wide', 'ir']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_M3D]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'M3D',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_M3D,
        ptzRoll: [0, 0],
        ptzPitch: [-90, 35],
        ptzYaw: [0, 0],
        cameraParam: {
          wide: {
            pixel: [5280, 3956],
            cmos: 4 / 3,
            focalLength: [12.03, 12.03],
            equivalentFocalLength: [CameraUtil.calculateEquivalentFocalLength(12.03, 4 / 3), CameraUtil.calculateEquivalentFocalLength(12.03, 4 / 3)]

          },
          zoom: {
            pixel: [4000, 3000],
            cmos: 1 / 2,
            // @TODO 焦距需要确认（需要验证）
            focalLength: [12, 162],
            equivalentFocalLength: [CameraUtil.calculateEquivalentFocalLength(12, 1 / 2), CameraUtil.calculateEquivalentFocalLength(162, 1 / 2)],
            hybridZoomRate: [1, 56]
          }
        },
        supportLens: ['visable']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_M3TD]: function () :DjiPayload {
      return {
        type: 'camera',
        name: 'M3TD',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_M3TD,
        ptzRoll: [0, 0],
        ptzPitch: [-90, 35],
        ptzYaw: [0, 0],
        cameraParam: {
          wide: {
            pixel: [8064, 6048],
            cmos: 1 / 1.32,
            // @TODO 这里计算出来的等效焦距与文档里面不一样（需要验证）
            focalLength: [3.42, 3.42],
            equivalentFocalLength: [CameraUtil.calculateEquivalentFocalLength(3.42, 1 / 1.32), CameraUtil.calculateEquivalentFocalLength(3.42, 1 / 1.32)]

          },
          zoom: {
            pixel: [4000, 3000],
            cmos: 1 / 2,
            // @TODO 焦距需要确认（需要验证）
            focalLength: [12, 162],
            equivalentFocalLength: [CameraUtil.calculateEquivalentFocalLength(12, 1 / 2), CameraUtil.calculateEquivalentFocalLength(162, 1 / 2)],
            hybridZoomRate: [1, 56]
          },
          ir: {
            hybridZoomRate: [1, 28]
          }
        },
        supportLens: ['visable', 'ir']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_M3M]: function () :DjiPayload {
      return {
        type: 'camera',
        name: 'M3M',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_M3M,
        ptzRoll: [0, 0],
        ptzPitch: [-90, 35],
        ptzYaw: [-5, 5],
        cameraParam: {},
        supportLens: ['zoom', 'wide', 'ir']
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_M30]: function () :DjiPayload {
      return {
        type: 'camera',
        name: 'M30',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_M30,
        ptzRoll: [0, 0],
        ptzPitch: [-120, 45],
        ptzYaw: [-90, 90],
        cameraParam: {
          wide: {
            pixel: [4000, 3000],
            cmos: 1 / 2,
            focalLength: [4.5, 4.5],
            equivalentFocalLength: [CameraUtil.calculateEquivalentFocalLength(4.5, 1 / 2), CameraUtil.calculateEquivalentFocalLength(4.5, 1 / 2)]

          },
          zoom: {
            pixel: [8000, 6000],
            cmos: 1 / 2,
            focalLength: [21, 75],
            equivalentFocalLength: [CameraUtil.calculateEquivalentFocalLength(21, 1 / 2), CameraUtil.calculateEquivalentFocalLength(75, 1 / 2)],
            hybridZoomRate: [2, 200]
          }
        },
        supportLens: ['zoom', 'wide']

      }
    },
    [DjiDeviceEnum.deviceEnumKeys.CAMERA_M30T]: function ():DjiPayload {
      return {
        type: 'camera',
        name: 'M30T',
        enumKey: DjiDeviceEnum.deviceEnumKeys.CAMERA_M30T,
        ptzRoll: [0, 0],
        ptzPitch: [-120, 45],
        ptzYaw: [-90, 90],
        cameraParam: {
          wide: {
            pixel: [4000, 3000],
            cmos: 0.5,
            focalLength: [4.5, 4.5],
            equivalentFocalLength: [CameraUtil.calculateEquivalentFocalLength(4.5, 1 / 2), CameraUtil.calculateEquivalentFocalLength(4.5, 1 / 2)]
          },
          zoom: {
            pixel: [8000, 6000],
            cmos: 0.5,
            focalLength: [21, 75],
            equivalentFocalLength: [CameraUtil.calculateEquivalentFocalLength(21, 1 / 2), CameraUtil.calculateEquivalentFocalLength(75, 1 / 2)],
            hybridZoomRate: [2, 200]
          },
          ir: {
            hybridZoomRate: [2, 20]
          }
        },
        supportLens: ['zoom', 'wide', 'ir']
      }
    }
  }

  static aircraft: StringKeyRecord<() => DjiAircraft> = {
    [DjiDeviceEnum.deviceEnumKeys.M350RTK]: function () {
      return {
        type: 'aircraft',
        name: 'M350 RTK',
        enumKey: DjiDeviceEnum.deviceEnumKeys.M350RTK,
        camera: undefined,
        payload: [
          DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_Z30],
          DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_H20],
          DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_H20N],
          DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_H20T],
          DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_P1],
          DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_L1]
        ],
        upSpeed: 6,
        downSpeed: 5,
        horizontalSpeed: 23
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.M300RTK]: function () {
      return {
        type: 'aircraft',
        name: 'M300 RTK',
        enumKey: DjiDeviceEnum.deviceEnumKeys.M300RTK,
        payload: [
          DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_Z30],
          DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_H20],
          DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_H20N],
          DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_H20T],
          DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_P1],
          DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_L1]
        ],
        camera: undefined,
        upSpeed: 6,
        downSpeed: 5,
        horizontalSpeed: 23
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.M30]: function () {
      return {
        type: 'aircraft',
        name: 'M30',
        enumKey: DjiDeviceEnum.deviceEnumKeys.M30,
        camera: DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_FPV],
        payload: [DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_M30]],
        upSpeed: 6,
        downSpeed: 5,
        horizontalSpeed: 23
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.M30T]: function () {
      return {
        type: 'aircraft',
        name: 'M30T',
        enumKey: DjiDeviceEnum.deviceEnumKeys.M30T,
        camera: DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_FPV],
        payload: [DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_M30T]],
        upSpeed: 6,
        downSpeed: 5,
        horizontalSpeed: 23
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.M3E]: function () {
      return {
        type: 'aircraft',
        name: 'M3E',
        enumKey: DjiDeviceEnum.deviceEnumKeys.M3E,
        camera: DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_FPV],
        payload: [DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_M3E]],
        upSpeed: 8,
        downSpeed: 6,
        horizontalSpeed: 21
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.M3T]: function () {
      return {
        type: 'aircraft',
        name: 'M3T',
        enumKey: DjiDeviceEnum.deviceEnumKeys.M3T,
        camera: DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_FPV],
        payload: [DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_M3T]],
        upSpeed: 8,
        downSpeed: 6,
        horizontalSpeed: 21
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.M3M]: function () {
      return {
        type: 'aircraft',
        name: 'M3M',
        enumKey: DjiDeviceEnum.deviceEnumKeys.M3M,
        camera: DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_FPV],
        payload: [DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_M3M]],
        upSpeed: 8,
        downSpeed: 6,
        horizontalSpeed: 21
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.M3D]: function () {
      return {
        type: 'aircraft',
        name: 'M3D',
        enumKey: DjiDeviceEnum.deviceEnumKeys.M3D,
        camera: DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_FPV],
        payload: [DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_M3D]],
        upSpeed: 6,
        downSpeed: 6,
        horizontalSpeed: 15
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.M3TD]: function () {
      return {
        type: 'aircraft',
        name: 'M3TD',
        enumKey: DjiDeviceEnum.deviceEnumKeys.M3TD,
        camera: DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_FPV],
        payload: [DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.CAMERA_M3TD]],
        upSpeed: 6,
        downSpeed: 6,
        horizontalSpeed: 15
      }
    }
  }

  static gateway: StringKeyRecord<() => DjiGateway> = {

    [DjiDeviceEnum.deviceEnumKeys.DJIRC]: function () {
      return {
        type: 'gateway',
        name: 'DJI RC',
        enumKey: DjiDeviceEnum.deviceEnumKeys.DJIRC,
        aircraft: [DjiDeviceEnum.aircraft[DjiDeviceEnum.deviceEnumKeys.M300RTK]],
        payload: [],
        remote: []
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.DJIRCPlus]: function () {
      return {
        type: 'gateway',
        name: 'DJI RC Plus',
        enumKey: DjiDeviceEnum.deviceEnumKeys.DJIRCPlus,
        aircraft: [
          DjiDeviceEnum.aircraft[DjiDeviceEnum.deviceEnumKeys.M350RTK],
          DjiDeviceEnum.aircraft[DjiDeviceEnum.deviceEnumKeys.M300RTK],
          DjiDeviceEnum.aircraft[DjiDeviceEnum.deviceEnumKeys.M30],
          DjiDeviceEnum.aircraft[DjiDeviceEnum.deviceEnumKeys.M30T],
        ],
        payload: [],
        remote: []
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.DJIRCPro]: function () {
      return {
        type: 'gateway',
        name: 'DJI RC Pro 行业版',
        enumKey: DjiDeviceEnum.deviceEnumKeys.DJIRCPro,
        aircraft: [
          DjiDeviceEnum.aircraft[DjiDeviceEnum.deviceEnumKeys.M3E],
          DjiDeviceEnum.aircraft[DjiDeviceEnum.deviceEnumKeys.M3T],
          DjiDeviceEnum.aircraft[DjiDeviceEnum.deviceEnumKeys.M3M]],
        payload: [],
        remote: []
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.DJIDock]: function () {
      return {
        type: 'gateway',
        name: 'DJI Dock',
        enumKey: DjiDeviceEnum.deviceEnumKeys.DJIDock,
        remote: [
          { method: 'drone_format' },
          { method: 'device_format' },
          { method: 'putter_open' },
          { method: 'putter_close' },
          { method: 'debug_mode_open' },
          { method: 'debug_mode_close' },
          { method: 'supplement_light_open' },
          { method: 'supplement_light_close' },
          { method: 'device_reboot' },
          { method: 'drone_open' },
          { method: 'drone_close' },
          { method: 'air_conditioner_mode_switch', param: [{ action: 'action', supportValue: [0, 1, 2, 3] }] },
          { method: 'cover_open' },
          { method: 'cover_close' },
          { method: 'charge_open' },
          { method: 'charge_close' },
          { method: 'battery_maintenance_switch', param: [{ action: 'action', supportValue: [0, 1] }] },
          { method: 'alarm_state_switch', param: [{ action: 'action', supportValue: [0, 1] }] },
          { method: 'battery_store_mode_switch', param: [{ action: 'action', supportValue: [1, 2] }] },
          { method: 'sdr_workmode_switch', param: [{ action: 'link_workmode', supportValue: [0, 1] }] }],
        aircraft: [DjiDeviceEnum.aircraft[DjiDeviceEnum.deviceEnumKeys.M30], DjiDeviceEnum.aircraft[DjiDeviceEnum.deviceEnumKeys.M30T]],
        payload: [DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.DJI_DOCK_CAMERA]]
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.DJIDock2]: function () {
      return {
        type: 'gateway',
        name: 'DJI Dock 2',
        enumKey: DjiDeviceEnum.deviceEnumKeys.DJIDock2,
        remote: [
          { method: 'drone_format' },
          { method: 'device_format' },
          { method: 'debug_mode_open' },
          { method: 'debug_mode_close' },
          { method: 'supplement_light_open' },
          { method: 'supplement_light_close' },
          { method: 'device_reboot' },
          { method: 'drone_open' },
          { method: 'drone_close' },
          { method: 'air_conditioner_mode_switch', param: [{ action: 'action', supportValue: [0, 1, 2, 3] }] },
          { method: 'cover_open' },
          { method: 'cover_close' },
          { method: 'charge_open' },
          { method: 'charge_close' },
          { method: 'battery_maintenance_switch', param: [{ action: 'action', supportValue: [0, 1] }] },
          { method: 'alarm_state_switch', param: [{ action: 'action', supportValue: [0, 1] }] },
          { method: 'battery_store_mode_switch', param: [{ action: 'action', supportValue: [1, 2] }] },
          { method: 'sdr_workmode_switch', param: [{ action: 'link_workmode', supportValue: [0, 1] }] },
          {
            method: 'esim_activate',
            param: [{ action: 'imei', supportValue: [] }, { action: 'device_type', supportValue: ['drone', 'dock'] }]
          },
          {
            method: 'sim_slot_switch',
            param: [{ action: 'imei', supportValue: [] }, {
              action: 'device_type',
              supportValue: ['drone', 'dock']
            }, { action: 'sim_slot', supportValue: [1, 2] }]
          },
          {
            method: 'esim_operator_switch',
            param: [{ action: 'imei', supportValue: [] }, {
              action: 'device_type',
              supportValue: ['drone', 'dock']
            }, { action: 'telecom_operator', supportValue: [1, 2, 3] }]
          }],
        aircraft: [
          DjiDeviceEnum.aircraft[DjiDeviceEnum.deviceEnumKeys.M3D],
          DjiDeviceEnum.aircraft[DjiDeviceEnum.deviceEnumKeys.M3TD]
        ],
        payload: [DjiDeviceEnum.camera[DjiDeviceEnum.deviceEnumKeys.DJI_DOCK_CAMERA]]
      }
    },
    [DjiDeviceEnum.deviceEnumKeys.DJIRC_TEMP]: function () {
      return {
        type: 'gateway',
        name: '临时网关',
        enumKey: DjiDeviceEnum.deviceEnumKeys.DJIRC_TEMP,
        remote: [],
        aircraft: [],
        payload: []
      }
    }
  }

  static lensZh (type: 'visable' | 'zoom' | 'wide' | 'ir' | 'narrow_band') {
    switch (type) {
      case 'zoom':
        return '变焦（Z）'
      case 'wide':
        return '广角（W）'
      case 'ir':
        return '热成像(T）'
      case 'visable':
        return '可见光(V）'
      case 'narrow_band':
        return '窄带(N）'
    }
  }

  static getByEnumKey (key: string): DjiDevice {
    const target = (this.gateway[key] || this.aircraft[key] || this.camera[key])
    return target && target()
  }

  static getGateway () {
    return Object.values(this.gateway).map(f => f())
  }

  static getGatewayByEnumKey (key: string) {
    if (this.gateway[key]) {
      return this.gateway[key]()
    } else {
      return undefined
    }
  }

  static getAircraft () {
    return Object.values(this.aircraft).map(f => f())
  }

  static getAircraftByEnumKey (key: string) {
    if (this.aircraft[key]) {
      return this.aircraft[key]()
    } else {
      return undefined
    }
  }

  static getPayload () {
    return Object.values(this.camera).map(f => f())
  }

  static getPayloadByEnumKey (key: string) {
    if (this.camera[key]) {
      return this.camera[key]()
    } else {
      return undefined
    }
  }

  static toEnumKeyFromValue (domain: string | number, type: string | number, subType: string | number) {
    return domain + '-' + type + '-' + subType
  }

  static check (enumKey: string) {
    const types = enumKey.split('-')
    if (types.length !== 3) {
      throw Error('设备枚举不合法')
    }
    return types
  }

  static getDomain (enumKey: string) {
    return this.check(enumKey)[0]
  }

  static getType (enumKey: string) {
    return this.check(enumKey)[1]
  }

  static getSubType (enumKey: string) {
    return this.check(enumKey)[2]
  }

  toEnumKeyFromValue (domain: string | number, type: string | number, subType: string | number) {
    return domain + '-' + type + '-' + subType
  }

  static toGatewayEnumKeyFromDrone (drone: DroneBaseVo) {
    return this.toEnumKeyFromValue(drone.gatewayDomain, drone.gatewayType, drone.gatewaySubType)
  }

  static toAircraftEnumKeyFromDrone (drone: DroneBaseVo) {
    return this.toEnumKeyFromValue(drone.aircraftDomain, drone.aircraftType, drone.aircraftSubType)
  }

  static toEnumKey (keys: string[]) {
    const enumKey = keys.join('-')
    this.check(enumKey)
    return enumKey
  }

  static isEnumKey (enumKey?: string) {
    if (enumKey) {
      try {
        this.check(enumKey)
        return true
      } catch (e) {
        return false
      }
    } else {
      return false
    }
  }

  static isDroneTemp (droneOrEnumKey ?: DroneBaseVo | string) {
    if (typeof droneOrEnumKey === 'object') {
      return droneOrEnumKey.gatewayDomain === 2 && droneOrEnumKey.gatewayType === 99999 && droneOrEnumKey.gatewaySubType === 99999
    } else {
      const types = droneOrEnumKey?.split('-')
      return types && types[0] === '2' && types[1] === '99999' && types[2] === '99999'
    }
  }

  static isAircraftUnknown (enumKey: string) {
    try {
      return this.getDomain(enumKey) === '0' && this.getType(enumKey) === '99999' && this.getSubType(enumKey) === '99999'
    } catch (e) {
      return false
    }
  }

  static isDock (droneOrGatewayDomain?: DroneBaseVo | number) {
    if (typeof droneOrGatewayDomain === 'object') {
      return droneOrGatewayDomain.gatewayDomain === 3
    } else {
      return droneOrGatewayDomain === 3
    }
  }

  static isDock2 (droneBaseVo?: DroneBaseVo): Boolean {
    return !!droneBaseVo && this.isDock(droneBaseVo) && droneBaseVo.gatewayType === 2
  }

  static isRc (droneOrGatewayDomain?: DroneBaseVo | number) {
    if (typeof droneOrGatewayDomain === 'object') {
      return droneOrGatewayDomain.gatewayDomain === 2
    } else {
      return droneOrGatewayDomain === 2
    }
  }

  static remoteAble (drone: DroneBaseVo) {
    const enumKey = this.toEnumKeyFromValue(drone.gatewayDomain, drone.gatewayType, drone.gatewaySubType)
    const target = DjiDeviceEnum.getGateway().findBy('enumKey', enumKey)
    return (target?.remote !== undefined) && target.remote.length > 0
  }

  private static deviceImages = new Map<string, string>()

  static getDeviceImage (enumKey: string) {
    const icon = this.deviceImages.get(enumKey)
    if (icon) {
      return icon
    } else {
      this.deviceImages.set(enumKey, require(`@/assets/device/image-${enumKey}.png`))
      const icon = this.deviceImages.get(enumKey)
      if (icon) {
        return icon
      } else {
        // TODO 这里需要一张未知设备的图
        this.deviceImages.set(enumKey, require('@/assets/image/image-drone-placeholder.png'))
        return this.deviceImages.get(enumKey)
      }
    }
  }
}
