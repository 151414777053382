import * as turf from '@turf/turf'
import { round } from 'lodash'
// https://turfjs.fenxianglu.cn/category/measurement/area.html
export default class JlinkTruf {
  static area (coordinates: JlinkLocation[][], precision: number = 2) {
    const geoList = coordinates.map(c => {
      const geo = c.map(p => [p.lng, p.lat, p.height])
      const first = c.first()
      if (first) {
        geo.push([first.lng, first.lat, first.height])
      }
      return geo
    })

    const p = turf.polygon(geoList.filter(i => i.length > 3))
    return round(turf.area(p), precision)
  }

  static lineSegment (coordinates: JlinkLocation[][]) {
    const geoList = coordinates.map(c => {
      const geo = c.map(p => [p.lng, p.lat, p.height])
      const first = c.first()
      if (first) {
        geo.push([first.lng, first.lat, first.height])
      }
      return geo
    })
    const p = turf.polygon(geoList.filter(i => i.length > 3))
    return turf.lineSegment(p)
  }

  static kinks (coordinates: JlinkLocation[][]) {
    const geoList = coordinates.map(c => {
      const geo = c.map(p => [p.lng, p.lat, p.height])
      const first = c.first()
      if (first) {
        geo.push([first.lng, first.lat, first.height])
      }
      return geo
    })
    const p = turf.polygon(geoList.filter(i => i.length > 3))
    return turf.kinks(p)
  }

  static lineToPolygon (coordinates: JlinkLocation[][]) {
    const geoList = coordinates.map(c => {
      const geo = c.map(p => [p.lng, p.lat, p.height])
      const first = c.first()
      if (first) {
        geo.push([first.lng, first.lat, first.height])
      }
      return geo
    })
    const p = turf.lineStrings(geoList.filter(i => i.length > 3))
    return turf.lineToPolygon(p)
  }

  static center (coordinates: JlinkLocation[]) {
    if (coordinates.length >= 3) {
      const features = coordinates.map(p => turf.point([p.lng, p.lat, p.height]))
      const p = turf.featureCollection(features)
      return turf.centroid(p)
    } else {
      return undefined
    }
  }

  static expandedPolygon (coordinates: JlinkLocation[], margin: number): JlinkLocation[] {
    const geoList = coordinates.map(p => [p.lng, p.lat, p.height])
    const first = coordinates.first()
    if (first) {
      geoList.push([first.lng, first.lat, first.height])
    }
    if (geoList.length < 4) {
      return []
    }
    const polygon = turf.polygon([geoList])

    const expandedTriangle = turf.buffer(polygon, margin, { units: 'meters' })
    return expandedTriangle.geometry.coordinates.map(item => {
      return item.map(i => {
        return { lng: i[0] as number, lat: i[1] as number, height: i[2] as number || 0 }
      })
    })[0]
  }
}
