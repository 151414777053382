import { GB, KB, MB } from '@/common/global/JlinkValues'

Number.prototype.prefixZero = function (whenLess?: number) {
  const thisValue = this.valueOf()

  return thisValue < (whenLess || 10) ? '0' + thisValue : thisValue
}

Number.prototype.equal = function (t?: number) {
  return this.valueOf() === t
}

Number.prototype.range = function (max, min) {
  return Math.max(Math.min(max, this.valueOf()), min)
}

Number.prototype.toFixedCustom = function (fractionDigits: number) {
  const newValue = this.valueOf().toFixed(fractionDigits)
  if (fractionDigits === 0) {
    return Number(newValue)
  }
  const split = newValue.split('.')
  const prefix = split[0]
  let letterfix = split[1]
  const offSet = fractionDigits - letterfix.length
  for (let i = 0; i < offSet; i++) {
    letterfix += '0'
  }
  return Number(prefix + '.' + letterfix)
}

Number.prototype.asByteSizeFormat = function (f: number) {
  const thisValue = this.valueOf()

  if (thisValue > GB) {
    return (thisValue / GB).toFixedCustom(f) + 'GB'
  }
  if (thisValue > MB) {
    return (thisValue / MB).toFixedCustom(f) + 'MB'
  }
  if (thisValue > KB) {
    return (thisValue / KB).toFixedCustom(f) + 'KB'
  }
  return thisValue + 'B'
}

Number.prototype.asBigNumFormat = function (f: number, unit: string) {
  const thisValue = this.valueOf()

  // if (thisValue > 1000 * 1000) {
  //   return (this.valueOf() / (1000 * 1000)).toFixedCustom(f) + 'm' + unit
  // }
  if (thisValue > 1000) {
    return (thisValue / (1000)).toFixedCustom(f) + 'k' + unit
  }
  return this.toFixedCustom(f) + unit
}

Number.prototype.asBigSquareFormat = function (f: number) {
  const thisValue = this.valueOf()
  if (thisValue > 1000 * 1000) {
    return (thisValue / (1000 * 1000)).toFixedCustom(f) + 'k㎡'
  }
  return this.toFixedCustom(f) + '㎡'
}

Number.prototype.asBigTimeFormat = function (f: number) {
  const thisValue = this.valueOf()
  if (thisValue > 60 * 60 * 24 * 365) {
    return (this.valueOf() / (60 * 60 * 24 * 365)).toFixedCustom(f) + 'y'
  }
  if (thisValue > 60 * 60 * 24) {
    return (this.valueOf() / (60 * 60 * 24)).toFixedCustom(f) + 'd'
  }
  if (thisValue >= 60 * 60) {
    return (thisValue / (60 * 60)).toFixedCustom(f) + 'h'
  }
  if (thisValue > 60) {
    return (thisValue / (60)).toFixedCustom(f) + 'm'
  }
  return this.toFixedCustom(f) + 's'
}
