import _, { isArray } from 'lodash'
Array.prototype.last = function () {
  if (this.length === 0) {
    return undefined
  }
  return this[this.length - 1]
}

Array.prototype.first = function () {
  if (this.length === 0) {
    return undefined
  }
  return this[0]
}

Array.prototype.sortBy = function (key, d) {
  return this.sort(function (a: any, b: any) {
    const objA = a[key]
    const objB = b[key]
    if (typeof objA === 'number' && typeof objB === 'number') {
      if (d === 'asc') {
        // 升序
        return objA - objB
      } else {
        // 降序
        return objB - objA
      }
    } else if (typeof objA === 'string' && typeof objB === 'string') {
      if (d === 'asc') {
        // 升序
        return (objA).localeCompare(objB, 'zh-CN')
      } else {
        // 降序
        return (objB).localeCompare(objA, 'zh-CN')
      }
    } else if (typeof objA === 'boolean' && typeof objB === 'boolean') {
      if (d === 'asc') {
        // 升序
        return (objA ? 1 : 0) - (objB ? 1 : 0)
      } else {
        // 降序
        return (objB ? 1 : 0) - (objA ? 1 : 0)
      }
    } else {
      console.warn(`sortBy just support order  element  when it is typeof number , string or boolean but current is ${JSON.stringify(objA)} : ${JSON.stringify(objB)}`)
      return 0
    }
  })
}

Array.prototype.mapBy = function (key, executeWhenElementIsFunction) {
  return this.map((i: any) => {
    const res = i[key]
    if (typeof res === 'function') {
      if (executeWhenElementIsFunction === false) {
        return res
      } else {
        return res.apply(i)
      }
    } else {
      return res
    }
  })
}

Array.prototype.mapByAndFilterNullOrUndefined = function (key) {
  return this.mapBy(key).filter((i: any) => (i !== undefined && i !== null))
}

Array.prototype.findBy = function (key, value) {
  return this.find((i: any) => i[key] === value)
}

Array.prototype.findByElement = function (value) {

  return this.find((i: any) => i === value)
}


Array.prototype.second = function () {
  if (this.length <= 1) {
    return undefined
  }
  return this[1]
}

Array.prototype.total = function () {
  let res = 0
  this.forEach((i: any) => {
    if (typeof i === 'number') {
      res += i
    } else {
      console.warn(`the element ${res} is not number so exclude`)
    }
  })
  return res
}

Array.prototype.trim = function () {
  return Array.from(new Set(this))
}

Array.prototype.all = function (predicate: (item: any) => boolean) {
  if (this.length === 0) return false
  for (const element of this) if (!predicate(element)) return false
  return true
}

Array.prototype.any = function (predicate: (item: any) => boolean) {
  if (this.length === 0) return false
  for (const element of this) if (predicate(element)) return true
  return false
}

Array.prototype.remove = function (predicate?) {
  return _.remove(this, predicate)
}

Array.prototype.removeElement = function (e) {
  return _.remove(this, function (value) {
    if (isArray(e)) {
      return e.includes(value)
    } else {
      return value === e
    }
  })
}

Array.prototype.removeIf = function (key, data) {
  return _.remove(this, function (value) {
    if (data !== undefined) {
      if (isArray(data)) {
        return data.includes(value[key])
      } else {
        return value[key] === data
      }
    } else {
      return false
    }
  })
}

Array.prototype.filterIf = function (key, data) {
  if (data !== undefined) {
    if (isArray(data)) {
      return this.filter(function (value:any) {
        return data.includes(value[key])
      })
    } else {
      return this.filter(function (value:any) {
        return value[key] === data
      })
    }
  } else {
    return this.filter(function (value:any) {
      return value[key] !== undefined && value[key] !== null
    })
  }
}

Array.prototype.groupBy = function (predicate) {
  return _.groupBy(this, predicate)
}

Array.prototype.diffTo = async function<T,R> (target:R[], compare:(s: T, t: R, from: 's' | 't') => boolean,  needRemove: (sl: T[]) => void, needAdd: (sl: T[]) => void, newInstance: (sl: R) => T) {
 const source=this as T[]
  const diff1 = source.filter((s: T) => {
    if (target.length === 0) {
      return true
    }
    return target.map(t => compare(s, t, 's')).all(i => !i)
  })

  const addList: T[] = []

  const result = target.map(t => {
    let fixed:T|undefined
      source.forEach((it) => {
      if (compare(it, t, 't')) {
        fixed = it
      }
    })
    if (fixed===undefined) {
      fixed = newInstance(t)
      addList.push(fixed)
    }
    return fixed
  }
  )
  await needRemove(diff1)
  console.log("test3Valueadd",addList)

  await needAdd(addList)
  return result
}


