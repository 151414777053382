import { JOB_STATUS,JOB_TYPE } from '@/common/enum/JLINK_ENUMS'

export default class WaylineJobTransform {
  toStatus (job:WaylineJobVo|JOB_STATUS) {
    let status
    if (typeof job === 'object') {
      status = job.jobStatus
    } else {
      status = job
    }
    switch (status) {
      case 0:
        return '待发布'
      case 1:
        return '已发布'
      default:
        return '未知'
    }
  }

  toType (job:WaylineJobVo|JOB_TYPE) {
    let status
    if (typeof job === 'object') {
      status = job.jobType
    } else {
      status = job
    }
    switch (status) {
      case 0:
        return '航线任务'
      case 1:
        return '应急任务'
      case 2:
        return '一键起飞'
      default:
        return '未知任务'
    }
  }
}
