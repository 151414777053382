import JlinkTask from '@/common/global/JlinkTask'
import { IClientPublishOptions } from 'mqtt/types/lib/client-options'
import { getMqtt } from '@/api/api.system'
import { UserNotifyMessageModule } from '@/store/UserNotifyMessage'
import { CompanyNotifyMessageModule } from '@/store/CompanyNotifyMessage'
import BasicMqtt from '@/common/workers/components/BasicMqtt'
import JlinkUtils from '@/common/global/JlinkUtils'
declare type AssignmentsMessageHandler = MqttMsgHandler<AssignmentsMessageVo>
declare type CompanyNotifyMessageHandler = MqttMsgHandler<NotifyMessageBase<unknown>>
declare type UserNotifyMessageHandler = MqttMsgHandler<NotifyMessageBase<unknown>>
declare type NewMessageArriveHandler = MqttMsgHandler<{ newCount: number }>
declare type WaylineJobTaskCreateHandler = MqttMsgHandler<FlightTaskVo>
declare type WaylineJobTaskExecuteHandler = MqttMsgHandler<WaylineJobVo>

export default class JlinkMqtt {
  static mqtt: BasicMqtt
  static options:ConnectionOptions|undefined
  private static async connectCheck () {
    if (this.options) {
      if (!this.connected()) {
        if (!this.connecting()) {
          await this.mqtt.connection(this.options)
          console.log('mqttService is success')
        } else {
          console.debug('mqttService is connecting')
        }
      } else {
        console.debug('mqttService is connected')
      }
    } else {
      console.debug('mqttService is disconnection')
      await this.mqtt.disconnection()
    }
  }

  private static asyncLock = JlinkTask.asyncLockMaker(this.connectCheck.bind(this))

  static async setOptions (res?: ConnectionOptions): Promise<void> {
    this.options = res
    this.asyncLock(undefined, 'connection')
  }

  static connected () {
    return this.mqtt.getConnected()
  }

  static connecting () {
    return this.mqtt.getConnecting()
  }

  static disConnected () {
    return !this.connected()
  }

  static async autoRegister (userId:string, companyId:string) {
    if (this.disConnected() && !this.connecting()) {
      try {
        const res = await getMqtt({ type: 'websocket' })
        await this.subscribeUserNotifyMessage(userId, false, UserNotifyMessageModule.HANDLE_MESSAGE)
        await this.subscribeNewMessageArrive(userId, false, UserNotifyMessageModule.HANDLE_NEW_ARRIVE)
        await this.subscribeCompanyNotifyMessage(companyId, false, CompanyNotifyMessageModule.HANDLE_MESSAGE)
        await this.setOptions({ ...res, clientId: 'web_server_' + JlinkUtils.random.uuid(23) })
        await this.subscribeUserNotifyMessage(userId, true, UserNotifyMessageModule.HANDLE_MESSAGE)
        await this.subscribeNewMessageArrive(userId, true, UserNotifyMessageModule.HANDLE_NEW_ARRIVE)
        await this.subscribeCompanyNotifyMessage(companyId, true, CompanyNotifyMessageModule.HANDLE_MESSAGE)
      } catch (e: any) {
        console.log(e.message)
      }
    }
  }

  static {
    this.mqtt = new BasicMqtt()
    const _this = this
    setInterval(function () {
      _this.setOptions(_this.options).then()
    }, 5000)
  }

  static register<T> (topic: string, qos: 0 | 1 | 2, handler: MqttMsgHandler<T>, context?:any): Promise<boolean> {
    if (context) {
      handler.contextThis = new WeakRef<any>(context)
    }
    return this.mqtt.register(topic, qos, handler)
  }

  static unregister<T> (topic: string, handler: MqttMsgHandler<T>): Promise<boolean> {
    return this.mqtt.unregister(topic, handler)
  }

  static send (topic: string, mess: string, opts: IClientPublishOptions): Promise<number> {
    return this.mqtt.send(topic, mess, opts)
  }

  // 监听任务聊天消息
  static async subscribeAssignmentsMessage (assignmentsId: string, open: boolean, handler: AssignmentsMessageHandler, context?:any) {
    const topic = `assignments/${assignmentsId}/msg`
    if (open) {
      return await this.register(topic, 2, handler, context)
    } else {
      return await this.unregister(topic, handler)
    }
  }

  // 监听时候有新消息
  static async subscribeWaylineJobTaskCreate (jobId: string, open: boolean, handler: WaylineJobTaskCreateHandler, context?:any) {
    const topic = `job/task/${jobId}/create`
    if (open) {
      return await this.register(topic, 1, handler, context)
    } else {
      return await this.unregister(topic, handler)
    }
  }

  // 监听计划工作执行后计划的数据变化
  static async subscribeWaylineJobTaskExecute (jobId: String, open: boolean, handler: WaylineJobTaskExecuteHandler, context?:any) {
    const topic = `job/${jobId}/execute`
    if (open) {
      return await this.register(topic, 1, handler, context)
    } else {
      return await this.unregister(topic, handler)
    }
  }

  // 任务开始结束通知
  static async subscribeCompanyNotifyMessage (companyId: string, open: boolean, handler: CompanyNotifyMessageHandler, context?:any) {
    const topic = `company/notify/${companyId}/message`
    if (open) {
      return await this.register(topic, 1, handler, context)
    } else {
      return await this.unregister(topic, handler)
    }
  }

  // 监听推送消息
  static async subscribeUserNotifyMessage (userId: string, open: boolean, handler: UserNotifyMessageHandler, context?:any) {
    const topic = `user/notify/${userId}/message`
    if (open) {
      return await this.register(topic, 1, handler, context)
    } else {
      return await this.unregister(topic, handler)
    }
  }

  // 监听时候有新消息
  static async subscribeNewMessageArrive (userId: string, open: boolean, handler: NewMessageArriveHandler, context?:any) {
    const topic = `user/notify/${userId}/newMessageArrived`
    if (open) {
      return await this.register(topic, 1, handler, context)
    } else {
      return await this.unregister(topic, handler)
    }
  }

  static subscribeUserInAssignmentsSave = new Map<string, { interval: number, fun: MqttMsgHandler<UserShowWrapperVo[]> }>()

  static async subscribeUserInAssignments (assignmentsId: string, userId: string, assignmentsToken:string, callback?: (msg: UserShowWrapperVo[]) => void, context?:any) {
    const topic = `assignments/${assignmentsId}/enter`
    const key = assignmentsId + userId
    const _this = this
    const save = _this.subscribeUserInAssignmentsSave.get(key)
    save?.interval && clearInterval(save.interval)
    if (callback) {
      const callbackSave = {
        // TODO这里可以优化
        interval: setInterval(function () {
          _this.send(`web/${assignmentsId}/enter`, JSON.stringify({ assignmentsId, userId, assignmentsToken }), {})
        }, 5000),
        fun: function (msg: UserShowWrapperVo[]) {
          callback.apply(context || this, [msg])
        }
      }
      _this.subscribeUserInAssignmentsSave.set(key, callbackSave)
      const fun = _this.subscribeUserInAssignmentsSave.get(key)!.fun
      await _this.register(topic, 0, fun)
    } else {
      if (save?.fun) {
        await _this.unregister(topic, save.fun)
        _this.subscribeUserInAssignmentsSave.delete(key)
      }
    }
  }
}
