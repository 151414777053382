import {JOB_TYPE} from "@/common/enum/JLINK_ENUMS";

export default class WaylineJobTypeMaker {
  isNormalType (job?: WaylineJobVo |JOB_TYPE) {
    if (typeof job === 'object') {
      return job?.jobType === 0
    } else {
      return job === 0
    }
  }

  isEmergencyType (job?: WaylineJobVo | JOB_TYPE) {
    if (typeof job === 'object') {
      return job?.jobType === 1
    } else {
      return job === 1
    }
  }

  isTakeOffType (job?: WaylineJobVo | JOB_TYPE) {
    if (typeof job === 'object') {
      return job?.jobType === 2
    } else {
      return job === 2
    }
  }
}
